:root {
  --color-gold: rgb(228, 198, 0);
  --color-red: rgb(255, 103, 69);
  --color-green: rgb(105, 209, 68);
  --color-wikiwhite: rgb(248, 249, 250);
  --color-lightgrey: rgb(230, 235, 235);
  --color-grey: rgb(190, 191, 190);
  --color-darkgrey: rgb(90, 90, 90);

  --width-left: 320px;
  --width-side: 500px;
}

html, body {
  height: unset !important;
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,body::-webkit-scrollbar {
  display: none;
}

/* * {
  outline: 1px solid #f00 !important;
} */

.application {
  
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;
  height: 100vh;
}

.side {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  height: 100vh;
  flex-shrink: 1;
}

.side-content {
  display: flex;
  width: var(--width-left);
  flex-direction: column;
  align-items: flex-end;
  padding: 36px 36px 36px 0px;
  border-right: 1px solid var(--color-grey);
  border-color: var(--color-grey);
}

.nice-container {
  font-size: 14px;
  text-align: left;
  border: 1px solid;
  border-color: var(--color-grey);
  border-radius: 4px;
  padding: 10px 12px 6px 18px;
  margin-top: 9px;
  transition: .5s height, .5s padding, .5s margin, .5s opacity;
}
.nice-container:before {
  content: attr(label);
  position: relative;
  display: table;
  top: -19px;
  left: -15px;
  margin: 0 -12px -16px 0;
  background-color: var(--color-wikiwhite);
  color: var(--color-grey);
  padding: 0 4px;
}
/* .nice-container:empty { */
.nice-container:not(:has(> * > :not(.exit))) {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -2; /* compensates for border for 0 height */
  opacity: 0;
  pointer-events: none;
}

.vertical-group-enter {
  opacity: 0;
  margin: 0;
}
.vertical-group-enter-active {
  opacity: 1;
  margin: 6px 0;
  transition: opacity .5s, margin .5s;
}
.vertical-group-enter-done {
  opacity: 1;
  margin: 6px 0;
}
.vertical-group-exit {
  opacity: 1;
  margin: 6px 0;
}
.vertical-group {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.vertical-group-exit-active {
  opacity: 0;
  margin: 0;
  transition: opacity .5s, margin .5s;
}
.vertical-group-exit-done {
  opacity: 0;
  margin: 0
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  opacity: 0;
  width: 0;
  overflow: hidden;
}
.settings.enter {
  opacity: 0;
  width: 0;
}
.settings.enter-active {
  opacity: 1;
  width: 490px;
  transition: width .5s, opacity .5s;
}
.settings.enter-done {
  opacity: 1;
  width: 490px;
}
.settings.exit {
  opacity: 1;
  width: 490px;
}
.settings.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
}
.settings.exit-done {
  opacity: 0;
  width: 0;
  visibility: hidden;
}

.settings-align {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.settings-counter-align {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.settings-content {
  display: flex;
  width: calc(490px - 32px);
  gap: 8px;
}
.settings-content > * {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.settings-content.no-edit input {
  cursor: not-allowed;
}

button.start-run {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  border-radius: 16px;
  width: unset;
  padding: 0 16px 0 3px;
  border-color: var(--color-green);
  transition: color .5s, border-color .5s;
}
button.start-run:disabled {
  border-color: var(--color-grey);
}

button.start-run > .mini-icon {
  filter: saturate(100%);
  transition: filter .5s;
}
button.start-run:disabled > .mini-icon {
  filter: saturate(1%);
}


/* .wlogo {
  width: 232px;
  position: sticky;
  background-color: transparent;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.wlogo-enter {
  width: 0;
  opacity: 0;
}
.wlogo-enter-active {
  width: 232px;
  opacity: 1;
  transition: width .5s, opacity .5s;
}
.wlogo-enter-done {
  opacity: 1;
}
.wlogo-exit {
  width: 232px;
  opacity: 1;
}
.wlogo-exit-active {
  width: 0;
  opacity: 0;
  transition: width .5s, opacity .5s;
}
.wlogo-exit-done {
  width: 0;
  opacity: 0;
}

.wlogo-img {
  width: 128px;
  height: 128px;  
  margin: 18px;
  filter: saturate(1%);
  animation: rotation 16s infinite linear;
  transition: filter .5s, transform .5s;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.wlogo-img.ready {
  filter: none;
  cursor: pointer;
} */

.wiki-run {
  /* display: flex;
  flex-direction: column; */
  opacity: 0;
  width: 0;
  overflow-x: visible;
}
.wiki-run.enter {
  opacity: 0;
  width: 0;
}
.wiki-run.enter-active {
  opacity: 1;
  width: 60vw;
  transition: width .5s, opacity .5s;
}
.wiki-run.enter-done {
  opacity: 1;
  width: 60vw;
}
.wiki-run.exit {
  opacity: 1;
  width: 60vw;
  height: 100vh;
}
.wiki-run.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
}
.wiki-run.exit-done {
  width: 0;
  opacity: 0;
  height: 100vh;
}

/* .wiki-run.exit > .wiki-run-content,
.wiki-run.exit-done > .wiki-run-content {
  overflow-y: hidden;
} */

.wiki-run.exit > .wiki,
.wiki-run.exit-done > .wiki {
  overflow-y: hidden;
  height: 100vh;
}

.wiki-run-align {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.wiki-run.exit > .wiki-run-align {
  align-items: start;
}

.wiki {
  overflow-x: visible;
  width: 60vw;
  margin-left: 32px;
}

.wiki.loading {
  opacity: .2;
}

/* @media only screen and (max-width: 1200px) { .content  { width: 600px }}
@media only screen and (max-width: 1000px) { .content  { width: 500px }}
@media only screen and (max-width: 800px)  { .content  { width: 400px }} */

.input-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-end;
  overflow: hidden;
  height: 32px;
}

.input-line label {
  margin-right: 1em;
}

.input-line-enter {
  opacity: 0;
  height: 0;
}
.input-line-enter-active {
  opacity: 1;
  height: 32px;
  transition: height .5s, opacity .5s;
}
.input-line-exit {
  opacity: 1;
  height: 32px;
}
.input-line-exit-active {
  opacity: 0;
  height: 0;
  transition: height .5s, opacity .5s;
}
.input-line-exit-done {
  opacity: 0;
  height: 0;
}

.input-line input[type=text], button {
  height: 20px;
  width: 140px;
  padding: 0;
  margin-bottom: 11px;
  border: 0;
  background-color: transparent;
  text-align: center;
}

.input-line input[type=text] {  
  border-bottom: 1px solid lightslategray;
  caret-color: transparent;
}

.input-line input:focus {
  outline: none;
  animation: glow 0.6s infinite ease;
}

.input-line input::placeholder {
  transition: opacity .5s;
  opacity: 1;
}

.input-line input:focus::placeholder {
  opacity: 0.7;
}

button {
  border: 1px solid;
  border-color: var(--color-grey);
  color: var(--color-darkgrey);
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 0;
}
button:disabled {
  color: var(--color-grey)
}

.input-line.pageselect input {
  width: 200px;
}
button:not(:disabled):hover {
  background-color: var(--color-lightgrey)
}
button:not(:disabled):active {
  background-color: var(--color-grey)
}

/* wrapper class for in/out transitioning buttons */
.button {
  padding: 0;
  height: 0;
  margin: 0;
  border-width: 0;
  opacity: 0;
  overflow: hidden;
}
.button.enter {
  height: 0;
  margin: 0;
  border-width: 0;
  opacity: 0;
}
.button.enter-active {
  height: 20px;
  margin: 8px 0;
  opacity: 1;
  transition: height .5s, margin .5s, opacity .5s;
}
.button.enter-done {
  height: 20px;
  margin: 8px 0;
  opacity: 1;
}
.button.exit {
  height: 20px;
  margin: 8px 0;
  opacity: 1;
}
.button.exit-active {
  height: 0;
  margin: 0;
  opacity: 0;
  transition: height .5s, margin .5s, opacity .5s;
}
.button.exit-done {
  height: 0;
  margin: 0;
  border-width: 0;
  opacity: 0;
}

.setting-line {
  height: 18px;
  justify-content: space-between;
}
.setting-line.disabled label {
  color: var(--color-grey);
}

.party-members {
  margin: 0;
}
.party-members.enter {
  margin: 0;
}
.party-members.enter-active {
  margin: 8px 0 26px;
  transition: margin .5s;
}
.party-members.enter-done {
  margin: 8px 0 26px;
}
.party-members.exit {
  margin: 8px 0 26px;
}
.party-members.exit-active {
  margin: 0;
  transition: margin .5s;
}
.party-members.exit-done {
  margin: 0;
}

.party-members-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 4px;
  overflow: hidden;
  transition: .5s height;
}

.party-member {
  background-color: var(--color-grey);
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  height: 20px;
  padding: 0 10px;
  overflow: hidden;
}

.party-member.leader {
  background-color: var(--color-green);
  color:white;
  padding-left: 6px;
}

.party-member.enter {
  opacity: 0;
  height: 0;
}
.party-member.enter-active {
  opacity: 1;
  height: 20px;
  transition: height .5s, opacity .5s, background-color .5s;
}
.party-member.enter-done {
  opacity: 1;
  height: 20px;
}
.party-member-exit {
  opacity: 1;
  height: 20px;
}
.party-member.exit-active {
  opacity: 0;
  height: 0;
  background-color: var(--color-lightgrey);
  transition: height .5s, opacity .5s, background-color .5s;
}
.party-member.exit-done {
  opacity: 0;
  height: 0;
}

.autocomplete {
  position: absolute;
  transform: translate(-100%, 4px);
  width: 200px;
  max-height: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
  text-align: left;
  transition: height .5s;
  z-index: 1000;
}
.autocomplete-enter {
  max-height: 0px;
}
.autocomplete-enter-active {
  transition: max-height .5s;
  max-height: 300px;
}
.autocomplete-enter-done {
  max-height: 300px;
}
.autocomplete-exit {
  max-height: 300px;
}
.autocomplete-exit-active {
  transition: max-height .5s;
  max-height: 0;
}

.autocomplete-suggestion {
  white-space: nowrap;
}

.cross {
  width: 12px;
  margin-left: -14px;
  padding-right: 2px;
  height: 12px;
  opacity: .8;
  padding-bottom: 6px;
}

.opponents {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}

.opponents > .title {
  font-size: 14;
  font-weight: bold;
  line-height: 24px;
  padding: 4px 0;
  margin: 0;
  padding: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.opponents > .title.enter {
  height: 0;
  opacity: 0;
}
.opponents > .title.enter-active {
  height: 24px;
  opacity: 1;
  transition: height .5s, opacity .5s;
}
.opponents > .title.enter-done {
  height: 24px;
  opacity: 1;
}
.opponents > .title.exit {
  height: 24px;
  opacity: 1;
}
.opponents > .title.exit-active {
  height: 0;
  opacity: 0;
  transition: height .5s, opacity .5s;
}
.opponents > .title.exit-done {
  height: 0;
  opacity: 0;
}

.opponents-content {
  flex-direction: column;
  align-items: stretch;
}

.opponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 12px;
  border-left: 1px solid var(--color-grey);
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.side .opponent {
  padding: 0 12px 0 0;
  border-left: none;
  border-right: 1px solid var(--color-grey);
}

.opponent.enter {
  height: 0;
  opacity: 0;
}
.opponent.enter-active {
  height: 28px;
  opacity: 1;
  transition: height .5s, opacity .5s;
}
.opponent.enter-done {
  height: 28px;
  opacity: 1;
}
/* account for different height if path is shown */
.opponent.with-path.enter-active {
  height: 56px;
}
.opponent.with-path.enter-done {
  height: 56px;
}
.opponent.with-path.exit {
  height: 56px;
}
.opponent.exit {
  height: 28px;
  opacity: 1;
}
.opponent.exit-active {
  height: 0;
  opacity: 0;
  transition: height .5s, opacity .5s;
}
.opponent.exit-done {
  height: 0;
  opacity: 0;
}

.opponent-info {
  display: flex;
  flex-direction: row;
  align-items: end;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 12px;
}

.side .opponent-info {
  justify-content: end;
}

.opponent-status {
  width: 80px;
  border-radius: 9px;
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
  line-height: 16px;
  font-size: 14;
  overflow: hidden;
  transition: background-color 1s, border-color 1s;
  flex-shrink: 0;
  border-width: 1px;
  border-style: solid;
}
.opponent-status.running {
  background-color: var(--color-lightgrey);
  border-color: var(--color-grey);
}
.opponent-status.finished {
  background-color: var(--color-wikiwhite);
  border-color: var(--color-lightgrey);
}
.opponent-status.finished.first {
  background-color: var(--color-green);
  color: white;
}
.opponent-status.dnf {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: white;
}

.opponent .page {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opponent-path-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 0;
}
.opponent-path-container > label {
  color: var(--color-darkgrey)
}
.side .opponent-path-container {
  justify-content: end;
}

.mini-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0 3px;
  margin-bottom: -2px;
  vertical-align: bottom;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359.9deg);
  }
}

@keyframes glow {
  0% {
    background-color: var(--color-wikiwhite)
  }
  50% {
    background-color: var(--color-lightgrey)
  }
}

.error-container {
  position: sticky;
  bottom: 16px;
  left: 16px;
  width: 0;
  height: 0;
  overflow: visible;
}

.error {
  bottom: 0px;
  position: absolute;
  background-color: rgb(255, 76, 76);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px;
  color: white;
  border-radius: 8px;
}

.error > img {
  width: 24px;
  height: 24px;
  padding: 8px;
}

.error > label {
  display: block;
  white-space: nowrap;
  padding: 8px;
}

.error-enter {
  transform: scale(1, 0);
}
.error-enter-active {
  transform: scale(1, 1);
  transition: transform .5s;
}
.error-enter-done {
  transform: scale(1, 1);
}
.error-exit {
  transform: scale(1, 1);
}
.error-exit-active {
  transform: scale(1, 0);
  transition: transform .5s;
}
.error-exit-done {
  transform: scale(1, 0);
}

.link-mouseover {
  position: absolute;
  z-index: 1000;
  background-color: rgb(198, 198, 198);
  color: rgb(26, 26, 26);
  padding: 6px 12px;
  transform: translate(-50%, calc(-100% - 16px));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: 360px;
  text-align: center;
  border-radius: 6px;
  font-size: 13;
}

.link-mouseover.target {
  background-color: var(--color-green);
  color: white;
}
.link-mouseover.illegal {
  background-color: var(--color-red);
  color: white;
}

.link-mouseover > .title {
  font-weight: bold;
  font-style: normal;
}

.link-mouseover > .redirect {
  z-index: 1001;
  color: rgb(95, 95, 95);
}

.link-mouseover > .illegal-note {
  font-weight: bold;
}

.link-mouseover > .description {
  z-index: 1001;
  font-style: italic; 
}

.link-mouseover > :last-child:after {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgb(198, 198, 198);
  z-index: 1000;
  content: '';
  left: 50%;
  transform: translate(-50%, 14px) rotate(45deg);
}
.link-mouseover.target > :last-child:after {
  background-color: var(--color-green);
}
.link-mouseover.illegal > :last-child:after {
  background-color: var(--color-red);
}

.path-container {
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  max-height: 24px;
  margin-top: 4px;
}

.path-container > .path-container-inner {
  border: 1px solid transparent;
  border-radius: 9px;
  padding: 2px;
}

.path-container:has(.path-page:not(:only-child)):hover,
.path-container:has(.path-page:not(:only-child)):focus {
  overflow: visible;
}
.path-container:has(.path-page:not(:only-child)):hover > .path-container-inner,
.path-container:has(.path-page:not(:only-child)):focus > .path-container-inner {
  border-color: var(--color-grey);
  background-color: var(--color-wikiwhite);
}

.path {
  font-size: 12;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.path-page {
  display: inline-block;
}

.path-arrow {
  color: rgb(138, 143, 143);
}

.path-pill {
  background-color: rgb(200, 210, 210);
  border-radius: 9px;
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  padding: 0 8px;
  margin: 2px;  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 168px;
}

.run-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.run-status.enter {
  opacity: 0;
  height: 0;
}
.run-status.enter-active {
  opacity: 1;
  height: 96px;
  transition: opacity .5s, height .5s;
}
.run-status.enter-done {
  opacity: 1;
  height: 96px;
}
.run-status.exit {
  opacity: 1;
  height: 96px;
}
.run-status.exit-active {
  opacity: 0;
  height: 0;
  transition: opacity .5s, height .5s;
}
.run-status.exit-done {
  opacity: 0;
  height: 0;
}

.run-status > .primary {
  font-size: 32;
}

.run-status > .secondary {
  font-size: 18;
}

.side .path {
  justify-content: flex-start;
}

.post-game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 0;
  height: 100vh;
  opacity: 0;
  overflow: hidden;
  gap: 12px;
}
.post-game.enter {
  width: 0;
  opacity: 0;
  overflow: hidden;
}
.post-game.enter-active {
  width: var(--width-side);
  opacity: 1;
  transition: width .5s, opacity .5s;
}
.post-game.enter-done {
  width: var(--width-side);
  opacity: 1;
}
.post-game.exit {
  width: var(--width-side);
  opacity: 1;
}
.post-game.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
  overflow: visible;
}
.post-game.exit-done {
  opacity: 0;
  width: 0;
  visibility: hidden;
}

.post-game-align {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.post-game.exit > .post-game-align {
  align-items: start;
}

.post-game-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 32px;
  width: calc(var(--width-side) - 32px);
}

.abandon {
  margin-top: 12px;
}

/* Clear references and anything after */

/* h2:has(#References) {
  display: none;
}
h2:has(#References) ~ * {
  display: none;
} */

/* Clear anything after 'see also' segment*/

/* h2:has(#See_also) ~ h2 ~ * {
  display: none;
} */

.wiki *:has( + h2 + * + .reflist ) ~ * {
  display: none!important;
}

.wiki .metadata, .portalbox, .sistersitebox, .reference, .reflist, #Further_reading, #External_links, #Literary_work {
  display: none!important;
}

.wiki #coordinates {
  display: none!important;
}

.wiki.no-see-also h2:has(#See_also) {
  display: none!important;
}
.wiki.no-see-also h2:has(#See_also) + ul {
  display: none!important;
}
.wiki.no-see-also h2:has(#See_also) + :not(h2) + ul {
  display: none!important;
}
.wiki.no-see-also h2:has(#See_also) + :not(h2) + :not(h2) + ul {
  display: none!important;
}

/* .navbox {
  display: none!important;
} */
.no-navboxes .navbox {
  display: none!important;
}

.reflist ~ * {
  display: none;
}

.wiki:not(.no-navboxes) .navbox:not(.authority-control) {
  display: block!important;
}

.wiki.no-infoboxes .infobox {
  display: none!important;
}

.wiki.no-thumbs .thumb {
  display: none!important;
}

sup:has(a[title="Wikipedia:Citation needed"]) {
  display: none!important;
}

.bruh, .bruh * {
  cursor:url("data:image/svg+xml;utf8,<svg overflow='visible' xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewport='0 0 100 100' style='fill:black;font-size:18px;'> <text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle'>🤡</text> <animateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 0 -3' to='360 0 -3' dur='.6s' repeatCount='indefinite' /> </svg>") 0 0, auto; /*!emojicursor.app*/
}
