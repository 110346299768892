/*
https://en.wikipedia.org/w/load.php?lang=en&modules=site.styles|ext.cite.styles|ext.tmh.player.styles|mediawiki.ui.button|skins.vector.styles|skins.vector.user.styles|skins.vector.icons|mediawiki.ui.icon&only=styles&skin=vector-2022&debug=true
*/

/*
MediaWiki:Common.css
*/
/*
 * This is the CSS common to all desktop skins on en.Wikipedia.
 * Styling inside .mw-parser-output should generally use TemplateStyles.
 */
/* Reset italic styling set by user agent */
cite,
dfn {
	font-style: inherit;
}

/* Straight quote marks for <q> */
q {
	quotes: '"' '"' "'" "'";
}

/* Avoid collision of blockquote with floating elements by swapping margin and padding */
blockquote {
	overflow: hidden;
	margin: 1em 0;
	padding: 0 40px;
}

/* Consistent size for <small>, <sub> and <sup> */
small {
	font-size: 85%;
}

.mw-body-content sub,
.mw-body-content sup {
	font-size: 80%;
}

/* Same spacing for indented and unindented paragraphs on talk pages */
.ns-talk .mw-body-content dd {
	margin-top: 0.4em;
	margin-bottom: 0.4em;
}

/* Reduce page jumps by hiding collapsed/dismissed content */
.client-js .mw-special-Watchlist #watchlist-message,
.client-js .collapsible:not( .mw-made-collapsible).collapsed > tbody > tr:not(:first-child),

/* Avoid FOUC/reflows on collapsed elements. */
/* This copies MediaWiki's solution for T42812 to apply to innercollapse/outercollapse (T325115). */
/* TODO: Use :is() selector at some reasonable future when support is good for Most Clients */
/* Reference: https://gerrit.wikimedia.org/g/mediawiki/core/+/ecda06cb2aef55b77c4b4d7ecda492d634419ead/resources/src/jquery/jquery.makeCollapsible.styles.less#75 */
.client-js .outercollapse .innercollapse.mw-collapsible:not( .mw-made-collapsible ) > p,
.client-js .outercollapse .innercollapse.mw-collapsible:not( .mw-made-collapsible ) > table,
.client-js .outercollapse .innercollapse.mw-collapsible:not( .mw-made-collapsible ) > thead + tbody,
.client-js .outercollapse .innercollapse.mw-collapsible:not( .mw-made-collapsible ) tr:not( :first-child ),
.client-js .outercollapse .innercollapse.mw-collapsible:not( .mw-made-collapsible ) .mw-collapsible-content,

/* Hide charinsert base for those not using the gadget */
#editpage-specialchars {
	display: none;
}

/* Make the list of references smaller
 * Keep in sync with Template:Refbegin/styles.css
 * And Template:Reflist/styles.css
 */
ol.references {
	font-size: 90%;
	margin-bottom: 0.5em;
}

/* Cite customizations for Parsoid
 * Once everything uses the one true parser these are just customizations
 */
span[ rel='mw:referencedBy' ] > a::before {
	content: counter( mw-ref-linkback, lower-alpha );
	font-size: 80%;
	font-weight: bold;
	font-style: italic;
}

a[ rel="mw:referencedBy" ]::before {
	font-weight: bold;
	content: "^";
}

span[ rel="mw:referencedBy" ]::before {
	content: "^ ";
}

.mw-ref > a[data-mw-group=lower-alpha]::after {
	content: '[' counter( mw-Ref, lower-alpha ) ']';
}

.mw-ref > a[data-mw-group=upper-alpha]::after {
	content: '[' counter( mw-Ref, upper-alpha ) ']';
}

.mw-ref > a[data-mw-group=decimal]::after {
	content: '[' counter( mw-Ref, decimal ) ']';
}

.mw-ref > a[data-mw-group=lower-roman]::after {
	content: '[' counter( mw-Ref, lower-roman ) ']';
}

.mw-ref > a[data-mw-group=upper-roman]::after {
	content: '[' counter( mw-Ref, upper-roman ) ']';
}

.mw-ref > a[data-mw-group=lower-greek]::after {
	content: '[' counter( mw-Ref, lower-greek ) ']';
}

/* Styling for jQuery makeCollapsible, matching that of collapseButton */
.mw-parser-output .mw-collapsible-toggle:not(.mw-ui-button) {
	font-weight: normal;
	padding-right: 0.2em;
	padding-left: 0.2em;
}

.mw-collapsible-leftside-toggle .mw-collapsible-toggle {
	/* @noflip */
	float: left;
}

/* Lists in wikitable data cells are always left-aligned */
.wikitable td ul,
.wikitable td ol,
.wikitable td dl {
	/* @noflip */
	text-align: left;
}

/* Change the external link icon to a PDF icon for all PDF files */
.mw-parser-output a[href$=".pdf"].external,
.mw-parser-output a[href*=".pdf?"].external,
.mw-parser-output a[href*=".pdf#"].external,
.mw-parser-output a[href$=".PDF"].external,
.mw-parser-output a[href*=".PDF?"].external,
.mw-parser-output a[href*=".PDF#"].external {
	background: url(//upload.wikimedia.org/wikipedia/commons/4/4d/Icon_pdf_file.png) no-repeat right;
	/* @noflip */
	padding: 8px 18px 8px 0;
}

/* System messages styled similarly to fmbox */
div.mw-warning-with-logexcerpt,
div.mw-lag-warn-high,
div.mw-cascadeprotectedwarning,
div#mw-protect-cascadeon,
div.titleblacklist-warning {
	clear: both;
	margin: 0.2em 0;
	border: 1px solid #bb7070;
	background-color: #ffdbdb;
	padding: 0.25em 0.9em;
	box-sizing: border-box;
}

/* default colors for partial block message */
.mw-contributions-blocked-notice-partial .mw-warning-with-logexcerpt {
	border-color: #fc3;
	background-color: #fef6e7;
}

/* Increase the height of the image upload box */
#wpUploadDescription {
	height: 13em;
}

/* Minimum thumb width */
figure[typeof~='mw:File/Thumb'],
figure[typeof~='mw:File/Frame'],
.thumbinner {
	min-width: 100px;
}

/* Prevent floating boxes from overlapping any category listings,
   file histories, edit previews, and edit [Show changes] views. */
#mw-subcategories,
#mw-pages,
#mw-category-media,
#filehistory,
#wikiPreview,
#wikiDiff {
	clear: both;
}

/* Styling for tags in changes pages */
.mw-tag-markers {
	font-style: italic;
	font-size: 90%;
}

/* Hide stuff meant for accounts with special permissions. Made visible again in
   [[MediaWiki:Group-checkuser.css]], [[MediaWiki:Group-sysop.css]], [[MediaWiki:Group-abusefilter.css]],
   [[MediaWiki:Group-abusefilter-helper.css]], [[MediaWiki:Group-patroller.css]],
   [[MediaWiki:Group-templateeditor.css]], [[MediaWiki:Group-extendedmover.css]],
   [[MediaWiki:Group-extendedconfirmed.css]], and [[Mediawiki:Group-autoconfirmed.css]]. */
.checkuser-show,
.sysop-show,
.abusefilter-show,
.abusefilter-helper-show,
.patroller-show,
.templateeditor-show,
.extendedmover-show,
.extendedconfirmed-show,
.autoconfirmed-show,
.user-show {
	display: none;
}

/* Hide the redlink generated by {{Editnotice}},
   this overrides the ".sysop-show { display: none; }" above that applies
   to the same link as well. See [[phab:T45013]]

   Hide the images in editnotices to keep them readable in VE view.
   Long term, editnotices should become a core feature so that they can be designed responsive. */
.ve-ui-mwNoticesPopupTool-item .editnotice-redlink,
.ve-ui-mwNoticesPopupTool-item .mbox-image,
.ve-ui-mwNoticesPopupTool-item .mbox-imageright {
	display: none !important;
}

/* Remove bullets when there are multiple edit page warnings */
ul.permissions-errors {
	margin: 0;
}

ul.permissions-errors > li {
	list-style: none;
}

/* larger inline math */
span.mwe-math-mathml-inline {
	font-size: 118%;
}

/* Make <math display="block"> be left aligned with one space indent for 
 * compatibility with style conventions
 */
.mwe-math-fallback-image-display,
.mwe-math-mathml-display {
	margin-left: 1.6em !important;
	margin-top: 0.6em;
	margin-bottom: 0.6em;
}

.mwe-math-mathml-display math {
	display: inline;
}

@media screen {
	/* Gallery styles background changes are restricted to screen view.
	   In printing we should avoid applying backgrounds. */
	/* The backgrounds for galleries. */
	#content .gallerybox div.thumb {
		/* Light gray padding */
		background-color: #f8f9fa;
	}
	/* Put a chequered background behind images, only visible if they have transparency.
	   '.filehistory a img' and '#file img:hover' are handled by MediaWiki core (as of 1.19) */
	.gallerybox .thumb img {
		background: #fff url(//upload.wikimedia.org/wikipedia/commons/5/5d/Checker-16x16.png) repeat;
	}
	/* But not on articles, user pages, or portals. */
	.ns-0 .gallerybox .thumb img,
	.ns-2 .gallerybox .thumb img,
	.ns-100 .gallerybox .thumb img {
		background-image: none;
	}

	/* Display "From Wikipedia, the free encyclopedia" in skins that support it,
	   do not apply to print mode */
	#siteSub {
		display: block;
	}
}

/* Hide FlaggedRevs notice UI when there are no pending changes */
.flaggedrevs_draft_synced,
.flaggedrevs_stable_synced,
/* "Temporary" to remove links in sidebar T255381 */
#t-upload,
/* Hide broken download box on Special:Book pending T285400 */
.mw-special-Book #coll-downloadbox {
	display: none;
}

/*
 * BELOW HERE THERE BE SOONTOBE TEMPLATESTYLES THINGS;
 * SEE [[MediaWiki talk:Common.css/to do]]
 */

/* Infobox template style */
.infobox {
	border: 1px solid #a2a9b1;
	border-spacing: 3px;
	background-color: #f8f9fa;
	color: black;
	/* @noflip */
	margin: 0.5em 0 0.5em 1em;
	padding: 0.2em;
	/* @noflip */
	float: right;
	/* @noflip */
	clear: right;
	font-size: 88%;
	line-height: 1.5em;
	width: 22em;
}

.infobox-header,
.infobox-label,
.infobox-above,
.infobox-full-data,
.infobox-data,
.infobox-below,
.infobox-subheader,
.infobox-image,
.infobox-navbar,
/* Remove element selector when every .infobox thing is using the standard module/templates  */
.infobox th,
.infobox td {
	vertical-align: top;
}

.infobox-label,
.infobox-data,
/* Remove element selector when every .infobox thing is using the standard module/templates  */
.infobox th,
.infobox td {
	/* @noflip */
	text-align: left;
}

/* Remove .infobox when element selectors above are removed */
.infobox .infobox-above,
.infobox .infobox-title,
/* Remove element selector when every .infobox thing is using the standard module/templates  */
.infobox caption {
	font-size: 125%;
	font-weight: bold;
	text-align: center;
}

.infobox-title,
/* Remove element selector when every .infobox thing is using the standard module/templates  */
.infobox caption {
	padding: 0.2em;
}

/* Remove .infobox when element selectors above are removed */
.infobox .infobox-header,
.infobox .infobox-subheader,
.infobox .infobox-image,
.infobox .infobox-full-data,
.infobox .infobox-below {
	text-align: center;
}

/* Remove .infobox when element selectors above are removed */
.infobox .infobox-navbar {
	/* @noflip */
	text-align: right;
}

/* Normal font styling for wikitable row headers with scope="row" tag */
.wikitable.plainrowheaders th[scope=row],
.wikitable.plainrowheaders th[scope=rowgroup] {
	font-weight: normal;
	/* @noflip */
	text-align: left;
}

/* Remove underlines from certain links */
.nounderlines a,
.IPA a:link,
.IPA a:visited {
	text-decoration: none !important;
}

/* Prevent line breaks in silly places where desired (nowrap)
   and links when we don't want them to (nowraplinks a) */
.nowrap,
.nowraplinks a {
	white-space: nowrap;
}

/* But allow wrapping where desired: */
.wrap,
.wraplinks a {
	white-space: normal;
}

/* texhtml class for inline math (based on generic times-serif class) */
span.texhtml {
	font-family: "Nimbus Roman No9 L", "Times New Roman", Times, serif;
	font-size: 118%;
	line-height: 1;
	white-space: nowrap;
	/* Force tabular and lining display for texhtml */
	-webkit-font-feature-settings: "lnum", "tnum", "kern" 0;
	font-feature-settings: "lnum", "tnum", "kern" 0;
	font-variant-numeric: lining-nums tabular-nums;
	font-kerning: none;
}

span.texhtml span.texhtml {
	font-size: 100%;
}

@media screen {
	.nochecker .gallerybox .thumb img {
		background-image: none;
	}
}

/* Put anything you mean to be a sitewide addition above the TemplateStyles
 * comment above.
 *//*
MediaWiki:Vector-2022.css
*/
/**
 * Note: [[MediaWiki:Common.css]] [[MediaWiki:Vector.css]] is loaded in addition to these styles
 * This stylesheet is reserved only for styles that override rules in [[MediaWiki:Vector.css]].
 * Please use [[Extension:TemplateStyles]] where possible.
 */
/* [[phab:T281974]] */
.skin-vector-2022 #coordinates {
	top: 2em;
}

/* [[phab:T327334]] */
@media (max-width: 719px) {
	.skin-vector-2022 a.external {
	   word-break: break-word;
	}
}/*
MediaWiki:Vector.css
*/
/* Don't display some stuff on the main page */
.page-Main_Page #deleteconfirm,
.page-Main_Page #t-cite,
.page-Main_Page #footer-info-lastmod,
.action-view.page-Main_Page #siteSub,
.action-view.page-Main_Page #contentSub,
.action-view.page-Main_Page #contentSub2 {
	display: none !important;
}

/* Position coordinates */
#coordinates {
	position: absolute;
	top: 0;
	right: 0;
	float: right;
	margin: 0;
	padding: 0;
	line-height: 1.5em;
	text-align: right;
	text-indent: 0;
	font-size: 85%;
	text-transform: none;
	white-space: nowrap;
}
/* correct position for VE */
.ve-ce-surface-enabled #coordinates {
	margin-right: 2em;
	margin-top: -1em;
}
.mw-indicator #coordinates {
	position: absolute;
	top: 3.5em;
	right: 0;
	line-height: 1.6;
	text-align: right;
	font-size: 92%;
	white-space: nowrap;
}

/* FR topicon position */
body.skin-vector-legacy div.flaggedrevs_short {
	position: absolute;
	top: -3em;
	right: 100px;
	z-index: 1;
}

/* Make "From Wikipedia, the free encyclopedia" a bit smaller */
body.skin-vector-legacy #siteSub {
	font-size: 92%;
}


/* Move page status indicators down slightly */
body.skin-vector-legacy .mw-indicators {
	padding-top: 0.4em;
}

/* Override [[phab:T265947]] */
.mw-body-content blockquote {
	border-left: none;
}@media print {
	/*
	MediaWiki:Print.css
	*/
	/* Do not print:
	   1: When in mainspace: Article message boxes,
	      navboxes, sister project boxes, disambig links,
	      and items marked as metadata.
	   2: section edit links.
	   3: navbar links.
	   4: Show/hide toggles for collapsible items.
	*/
	.ns--1 .ambox,
	.ns-0 .ambox,
	.ns--1 .navbox,
	.ns-0 .navbox,
	/* high specificity because Minerva */
	.mediawiki.ns--1 .mw-parser-output .sidebar,
	.mediawiki.ns-0 .mw-parser-output .sidebar,
	.ns--1 .sisterproject,
	.ns-0 .sisterproject,
	.ns--1 .hatnote,
	.ns-0 .hatnote,
	.ns--1 .metadata,
	.ns-0 .metadata,
	.sistersitebox,
	.editlink,
	.navbar,
	span.mw-collapsible-toggle,
	th .sortkey,
	td .sortkey,
	#mw-revision-nav,
	/* Add formatting to make sure that "external references" from templates
	   like [[Template:Ref]] do not get URL expansion, not even when printed.
	   The anchor itself has class "external autonumber" and the url expansion
	   is inserted when printing (see the common printing style sheet at
	   http://en.wikipedia.orghttps://en.wikipedia.org/w/skins/common/commonPrint.css) using the
	   ":after" pseudo-element of CSS.
	*/
	.nourlexpansion a.external.text:after,
	.nourlexpansion a.external.autonumber:after {
		display: none !important;
	}
	
	/* Uncollapse collapsible things */
	.mw-parser-output .mw-collapsed .mw-collapsible-content {
		display: block !important;
	}
	
	table.collapsible tr,
	.mw-parser-output table.mw-collapsed > * > tr {
		display: table-row !important;
	}
	
	.mw-parser-output ol.mw-collapsed > li,
	.mw-parser-output ul.mw-collapsed > li {
		display: list-item !important;
	}
	
	/* On websites with siteSub visible, the margin on the firstHeading is not needed. */
	#firstHeading {
		margin: 0;
	}
	
	/* We don't want very long URLs (that are added to the content in print) to widen the canvas */
	#content a.external.text:after,
	#content a.external.autonumber:after {
		word-wrap: break-word;
	}
	
	/*
	- Basic infobox styling
	- Remove background colors, they are hard to print
	*/
	.infobox {
		border: solid 1px #aaa;
		background-color: #fff;
		border-spacing: 0;
		border-collapse: collapse;
		width: 180pt !important; /*T174957*/
	}
	
	.infobox > * > tr > td,
	.infobox > * > tr > th {
		padding: 2px 5px;
		border-bottom: 1px solid #EAECF0;		
	}
	
	/* Reduce noise for print medium - labels may be links */
	.infobox a,
	/* reset last border (set above) of infobox */
	.infobox > * > tr:last-child > th,
	.infobox > * > tr:last-child > td {
		border: 0;
	}
	
	/* References */
	.refbegin a,
	.references a,
	.reference a {
		color: black !important;
	}
	
	.reference a {
		border-bottom: 0;
	}
	
	ol.references,
	div.reflist,
	div.refbegin,
	cite * {
		/* Override any editor added inline styles that play with font-size */
		font-size: inherit !important;
	}
	
	.refbegin li,
	.references li {
		color: #666;
		line-height: 14pt;
	}
	
	.printfooter {
		clear: both;
	}}
.mw-cite-backlink,
.cite-accessibility-label {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mw-references-columns {
	-webkit-column-width: 30em;
	-moz-column-width: 30em;
	column-width: 30em;
	margin-top: 0.3em;
}

/* Lines first column up nicely. */
.mw-parser-output .mw-references-columns .references {
	margin-top: 0;
}

/* Avoid elements from breaking between columns */
.mw-references-columns li {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid-column;
}

ol.references {
	/**
	 * Resetting *any* counter on an <ol> element messes up the built-in numbering in Firefox.
	 * Explicitly resetting the built-in "list-item" counter fixes this.
	 *
	 * We have 2 counters for Parsoid HTML for different functionality.
	 * Make sure both are reset!
	 **/
	counter-reset: mw-ref-extends-parent mw-references list-item;
}

ol.references > li {
	/**
	 * We have 2 counters for Parsoid HTML for different functionality.
	 * Make sure both are incremented!
	 **/
	counter-increment: mw-ref-extends-parent mw-references;
	counter-reset: mw-ref-extends-child;
}

ol.references .mw-extended-references {
	list-style: none;
	margin-left: 2em;
}

.mw-extended-references > li:before {
	counter-increment: mw-ref-extends-child;
	content: counter( mw-ref-extends-parent, decimal ) '.' counter( mw-ref-extends-child, decimal ) '. ';
}

sup.reference {
	/* Isolation to fix references in case of RTL words at the end of a reference */
	unicode-bidi: -moz-isolate;
	unicode-bidi: -webkit-isolate;
	unicode-bidi: isolate;

	/* Don't allow a reference that includes a group name
	to break in the end of the line */
	white-space: nowrap;

	/* Do not inherit bold or italic */
	font-weight: normal;
	font-style: normal;
}

/* Highlight clicked reference in blue to help navigation */
ol.references li:target,
sup.reference:target {
	background-color: #eaf3ff;
}

/* Make cite errors "strong" */
.mw-ext-cite-error {
	font-weight: bold;
	/* For the case that the error is embedded in an element with a different direction */
	unicode-bidi: embed;
}

.mw-ext-cite-error code {
	/* As code is inheriting `#f8f9fa` as background color from `code`,
	   we need to increase contrast, by setting it to `transparent`. See T247903. */
	background-color: transparent;
	color: inherit;
	/* Reduce inherited `padding` to make code fit in better in Resources list. */
	padding: 1px 2px;
}

/* @noflip */
.mw-cite-dir-ltr .reference-text {
	direction: ltr;
	unicode-bidi: embed;
}

/* @noflip */
.mw-cite-dir-rtl .reference-text {
	direction: rtl;
	unicode-bidi: embed;
}@media print {
	.mw-cite-backlink {
		display: none;
	}}
/**
 * Styles-only module, added to all pages which include videojs mode
 * Both click to load and inline page modes.
 * This CSS should work both with and without JS and determines sizing etc
 */
/* Shrink videos to fit rather than overflowing containers */
video {
  height: auto;
  max-width: 100%;
}
/* Force a fixed size for audio to reduce FOUC */
audio,
.mw-tmh-player.audio {
  height: 30px;
  --inline-controls-bar-height: 30px;
  width: 300px;
}
video.thumbimage,
audio.thumbimage,
.thumb .mw-tmh-player.audio {
  border: 0;
  margin: 1px auto;
}
/* Galleryboxes are usually smaller than 220px, but can be different sizes. (T258622) */
.gallerybox .mw-tmh-player.audio {
  width: 90%;
}
/* Added by the ext.tmh.player after transforming the player */
.mw-tmh-player {
  display: inline-block;
  position: relative;
  /* fix height of inline-block T259549 */
  line-height: 0;
}
.mw-tmh-player .mw-tmh-play {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.thumb audio,
.thumb video,
.thumb .mw-tmh-player {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* Labels on top of the placeholder */
.mw-tmh-label {
  display: inline-block;
  line-height: 1;
  position: absolute;
  /* @noflip */
  right: 1em;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 0.3em;
  padding: 0.5em;
  cursor: pointer;
}
.mw-tmh-player.video .mw-tmh-label.mw-tmh-duration {
  bottom: 1em;
}
.mw-tmh-player.video .mw-tmh-label.mw-tmh-cc {
  font-size: 110%;
  top: 1em;
}
.mw-tmh-player.audio .mw-tmh-label {
  font-size: 80%;
  top: 0.35em;
}
.mw-tmh-player.audio .mw-tmh-label.mw-tmh-cc {
  /* @noflip */
  right: 5em;
}
/* Play button to load and play the video and audio file in a media dialog */
.mw-tmh-player.video .mw-tmh-play .mw-tmh-play-icon {
  background: rgba(0, 0, 0, 0.8) url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3Eplay%3C/title%3E %3Cpath fill=%22%23fff%22 d=%22M4.55 19A1 1 0 0 1 3 18.13V1.87A1 1 0 0 1 4.55 1l12.2 8.13a1 1 0 0 1 0 1.7z%22/%3E %3C/svg%3E") center no-repeat;
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 120ms ease-out, background-color 120ms ease-out;
}
.mw-tmh-player.audio .mw-tmh-play .mw-tmh-play-icon {
  background: rgba(0, 0, 0, 0.5) url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3Eplay%3C/title%3E %3Cpath fill=%22%23fff%22 d=%22M4.55 19A1 1 0 0 1 3 18.13V1.87A1 1 0 0 1 4.55 1l12.2 8.13a1 1 0 0 1 0 1.7z%22/%3E %3C/svg%3E") no-repeat 10px;
  transition: background-color 120ms ease-out;
  display: block;
  height: 100%;
  width: 100%;
}
.mw-tmh-player.video .mw-tmh-play:hover .mw-tmh-play-icon,
.mw-tmh-player.video .mw-tmh-play:focus .mw-tmh-play-icon,
.mw-tmh-player.video .mw-tmh-play:active .mw-tmh-play-icon {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translate(-50%, -50%) scale(1.1);
}
.mw-tmh-player.audio .mw-tmh-play:hover .mw-tmh-play-icon,
.mw-tmh-player.audio .mw-tmh-play:focus .mw-tmh-play-icon,
.mw-tmh-player.audio .mw-tmh-play:active .mw-tmh-play-icon {
  background-color: rgba(0, 0, 0, 0.8);
}
@supports ( aspect-ratio: 1 / 1 ) {
  .mw-tmh-player.video > .mw-tmh-play .mw-tmh-play-icon {
    width: auto;
    height: min( 50px, 70% );
    aspect-ratio: 1;
  }
}
/* All interstitial and progress loading is for the
   load phase between click and opening of the MediaDialog */
.mw-tmh-player-interstitial {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.mw-tmh-player-progress {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  overflow: hidden;
}
.mw-tmh-player-progress-bar {
  height: 1em;
  transition: width 100ms;
  animation: mw-tmh-player-progress-bar-slide 2s infinite linear;
  width: 40%;
  transform: translate(-25%);
  background-color: #36c;
}
@keyframes mw-tmh-player-progress-bar-slide {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(350%);
  }
}
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-enable selector-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */
/**
 * ###IMPORTANT:##
 * If editing this file, please also edit the respective file in the MinervaNeue skin.
 * ####
 *
 * This file is a LESS @import match for "mediawiki.skin.variables.less"
 * when Vector 2022 is the active skin.
 * Please note, that legacy Vector is handled by
 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
 *
 * Please also note, that both current Wikimedia Design Style Guide based skins,
 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
 * repositories.
 */
/**
 * Deprecated variables
 */
/* stylelint-disable selector-class-pattern */
.mw-ui-button {
  background-color: #f8f9fa;
  color: #202122;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  cursor: pointer;
  vertical-align: middle;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.28571429em;
  text-align: center;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-appearance: none;
}
.mw-ui-button:not( .mw-ui-icon-element ) {
  min-height: 32px;
  min-width: 4em;
  max-width: 28.75em;
  padding: 5px 12px;
}
.mw-ui-button:not( :disabled ) {
  transition-property: background-color, color, border-color, box-shadow;
  transition-duration: 100ms;
}
.mw-ui-button:not( :disabled ):visited {
  color: #202122;
}
.mw-ui-button:not( :disabled ):hover {
  background-color: #ffffff;
  color: #404244;
  border-color: #a2a9b1;
}
.mw-ui-button:not( :disabled ):focus {
  color: #202122;
  border-color: #3366cc;
  box-shadow: inset 0 0 0 1px #3366cc, inset 0 0 0 2px #ffffff;
  outline-width: 0;
}
.mw-ui-button:not( :disabled ):focus::-moz-focus-inner {
  border-color: transparent;
  padding: 0;
}
.mw-ui-button:not( :disabled ):active,
.mw-ui-button:not( :disabled ).is-on {
  background-color: #c8ccd1;
  color: #000000;
  border-color: #72777d;
  box-shadow: none;
}
.mw-ui-button:disabled {
  background-color: #c8ccd1;
  color: #ffffff;
  border-color: #c8ccd1;
  cursor: default;
}
.mw-ui-button.mw-ui-icon-element:not( .mw-ui-icon-with-label-desktop ) {
  color: transparent !important;
}
.mw-ui-button.mw-ui-icon-element:not( .mw-ui-icon-with-label-desktop ) span {
  display: block;
  position: absolute !important;
  /* stylelint-disable-line declaration-no-important */
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
}
@media all and (max-width: 1000px) {
  .mw-ui-button.mw-ui-icon-element.mw-ui-icon-with-label-desktop {
    color: transparent !important;
  }
  .mw-ui-button.mw-ui-icon-element span {
    display: block;
    position: absolute !important;
    /* stylelint-disable-line declaration-no-important */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
}
.mw-ui-button.mw-ui-quiet,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive {
  background-color: transparent;
  color: #202122;
  border-color: transparent;
  font-weight: bold;
}
.mw-ui-button.mw-ui-quiet:not( .mw-ui-icon-element ),
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:not( .mw-ui-icon-element ),
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:not( .mw-ui-icon-element ) {
  min-height: 32px;
}
input[type='checkbox']:hover + .mw-ui-button.mw-ui-quiet,
input[type='checkbox']:hover + .mw-ui-button.mw-ui-quiet.mw-ui-progressive,
input[type='checkbox']:hover + .mw-ui-button.mw-ui-quiet.mw-ui-destructive,
.mw-ui-button.mw-ui-quiet:hover,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:hover,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:hover {
  background-color: rgba(0, 24, 73, 0.02745098);
  color: #202122;
  border-color: transparent;
}
input[type='checkbox']:focus + .mw-ui-button.mw-ui-quiet,
input[type='checkbox']:focus + .mw-ui-button.mw-ui-quiet.mw-ui-progressive,
input[type='checkbox']:focus + .mw-ui-button.mw-ui-quiet.mw-ui-destructive,
.mw-ui-button.mw-ui-quiet:focus,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:focus,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:focus {
  color: #202122;
  border-color: #3366cc;
  box-shadow: inset 0 0 0 1px #3366cc, inset 0 0 0 2px #ffffff;
}
input[type='checkbox']:active + .mw-ui-button.mw-ui-quiet,
input[type='checkbox']:active + .mw-ui-button.mw-ui-quiet.mw-ui-progressive,
input[type='checkbox']:active + .mw-ui-button.mw-ui-quiet.mw-ui-destructive,
.mw-ui-button.mw-ui-quiet:active,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:active,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:active {
  background-color: rgba(0, 36, 73, 0.08235294);
  color: #000000;
  border-color: #72777d;
  box-shadow: none;
}
.mw-ui-button.mw-ui-quiet:disabled,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled,
.mw-ui-button.mw-ui-quiet:disabled:hover,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled:hover,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled:hover,
.mw-ui-button.mw-ui-quiet:disabled:active,
.mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled:active,
.mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled:active {
  background-color: transparent;
  color: #72777d;
  border-color: transparent;
}
.mw-ui-button.mw-ui-progressive:not( :disabled ) {
  background-color: #3366cc;
  color: #fff;
  border-color: #3366cc;
}
.mw-ui-button.mw-ui-progressive:not( :disabled ):hover {
  background-color: #447ff5;
  border-color: #447ff5;
}
.mw-ui-button.mw-ui-progressive:not( :disabled ):focus {
  box-shadow: inset 0 0 0 1px #3366cc, inset 0 0 0 2px #ffffff;
}
.mw-ui-button.mw-ui-progressive:not( :disabled ):active,
.mw-ui-button.mw-ui-progressive:not( :disabled ).is-on {
  background-color: #2a4b8d;
  border-color: #2a4b8d;
  box-shadow: none;
}
.mw-ui-button.mw-ui-progressive:disabled {
  background-color: #c8ccd1;
  color: #fff;
  border-color: #c8ccd1;
}
.mw-ui-button.mw-ui-progressive.mw-ui-quiet {
  color: #3366cc;
  background-color: transparent;
  border-color: transparent;
}
input[type='checkbox']:hover + .mw-ui-button.mw-ui-progressive.mw-ui-quiet,
.mw-ui-button.mw-ui-progressive.mw-ui-quiet:hover {
  background-color: rgba(52, 123, 255, 0.2);
  border-color: transparent;
  color: #447ff5;
}
input[type='checkbox']:focus + .mw-ui-button.mw-ui-progressive.mw-ui-quiet,
.mw-ui-button.mw-ui-progressive.mw-ui-quiet:focus {
  color: #3366cc;
  border-color: #3366cc;
}
input[type='checkbox']:active + .mw-ui-button.mw-ui-progressive.mw-ui-quiet,
.mw-ui-button.mw-ui-progressive.mw-ui-quiet:active {
  color: #ffffff;
  background-color: #2a4b8d;
  border-color: #2a4b8d;
}
.mw-ui-button.mw-ui-destructive:not( :disabled ) {
  background-color: #dd3333;
  color: #fff;
  border-color: #dd3333;
}
.mw-ui-button.mw-ui-destructive:not( :disabled ):hover {
  background-color: #ff4242;
  border-color: #ff4242;
}
.mw-ui-button.mw-ui-destructive:not( :disabled ):focus {
  box-shadow: inset 0 0 0 1px #dd3333, inset 0 0 0 2px #ffffff;
}
.mw-ui-button.mw-ui-destructive:not( :disabled ):active,
.mw-ui-button.mw-ui-destructive:not( :disabled ).is-on {
  background-color: #b32424;
  border-color: #b32424;
  box-shadow: none;
}
.mw-ui-button.mw-ui-destructive:disabled {
  background-color: #c8ccd1;
  color: #fff;
  border-color: #c8ccd1;
}
.mw-ui-button.mw-ui-destructive.mw-ui-quiet {
  color: #dd3333;
  background-color: transparent;
  border-color: transparent;
}
input[type='checkbox']:hover + .mw-ui-button.mw-ui-destructive.mw-ui-quiet,
.mw-ui-button.mw-ui-destructive.mw-ui-quiet:hover {
  background-color: rgba(209, 29, 19, 0.2);
  border-color: transparent;
  color: #ff4242;
}
input[type='checkbox']:focus + .mw-ui-button.mw-ui-destructive.mw-ui-quiet,
.mw-ui-button.mw-ui-destructive.mw-ui-quiet:focus {
  color: #dd3333;
  border-color: #dd3333;
}
input[type='checkbox']:active + .mw-ui-button.mw-ui-destructive.mw-ui-quiet,
.mw-ui-button.mw-ui-destructive.mw-ui-quiet:active {
  color: #ffffff;
  background-color: #b32424;
  border-color: #b32424;
}
.mw-ui-button.mw-ui-big {
  font-size: 1.3em;
}
.mw-ui-button.mw-ui-block {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
a.mw-ui-button {
  text-decoration: none;
}
a.mw-ui-button:hover,
a.mw-ui-button:focus {
  text-decoration: none;
}
.mw-ui-button-group > * {
  min-width: 48px;
  border-radius: 0;
  float: left;
}
.mw-ui-button-group > *:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.mw-ui-button-group > *:not( :first-child ) {
  border-left: 0;
}
.mw-ui-button-group > *:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.mw-ui-button-group .is-on .button {
  cursor: default;
}
/**
 * Respect users who prefer reduced motion.
 * Inspiration taken from sanitize.css
 * https://github.com/csstools/sanitize.css/blob/v13.0.0/reduce-motion.css,
 * itself based on collection at
 * https://github.com/jensimmons/cssremedy/issues/11
 *
 * Note, we also don't set `background-attachment: initial !important;` as
 * setting a background property changes the default rendering of unstyled
 * elements like `button`s and we don't feature `fixed` anywhere in deployed
 * skins or extensions.
 */
/* stylelint-disable declaration-no-important, time-min-milliseconds, selector-pseudo-element-colon-notation */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0ms !important;
  }
}

/**
 * MediaWiki style sheet for addressing (normalizing) browser bugs and inconsistencies.
 *
 * Meant for normalizing elements and attribute selectors used by MediaWiki.
 * General element styles and element-bound classes belong to 'elements.css'.
 * If adding/changing rules, please consult https://github.com/necolas/normalize.css v7.0.0
 * which this file is heavily inspired from, additionally orients on our
 * Basic (Grade C) supported browsers.
 * See https://www.mediawiki.org/wiki/Compatibility#Browser_support_matrix
 */
body {
  margin: 0;
}
main {
  display: block;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
@supports ( text-decoration: underline dotted ) {
  abbr[title] {
    border-bottom: 0;
    text-decoration: underline dotted;
  }
}
pre,
code,
tt,
kbd,
samp {
  font-family: monospace, monospace;
}
sub,
sup {
  line-height: 1;
}
img {
  border: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
legend {
  color: inherit;
  padding: 0;
}

/**
 * These styles are common to both print and screen media.
 * Use the -print or without postfix files to apply styles more selectively.
 */
/* stylelint-disable selector-class-pattern */
/* @noflip */
div.tright,
div.floatright,
table.floatright {
  clear: right;
  float: right;
}
/* @noflip */
div.tleft,
div.floatleft,
table.floatleft {
  float: left;
  clear: left;
}
/* @noflip */
div.floatright,
table.floatright {
  margin: 0 0 0.5em 0.5em;
}
/* @noflip */
div.floatleft,
table.floatleft {
  margin: 0 0.5em 0.5em 0;
}
div.thumb {
  width: auto;
  background-color: transparent;
  margin-bottom: 0.5em;
}
/* @noflip */
div.tleft {
  margin: 0.5em 1.4em 1.3em 0;
}
/* @noflip */
div.tright {
  margin: 0.5em 0 1.3em 1.4em;
}
.thumbcaption {
  text-align: left;
  line-height: 1.4em;
  padding: 3px;
}
div.thumbinner {
  padding: 3px;
  text-align: center;
  /* new block formatting context,
	 * to clear background from floating content */
  overflow: hidden;
}

.mw-message-box {
  color: #000;
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 1px solid;
  padding: 12px 24px;
  word-wrap: break-word;
  /* Standard property is `overflow-wrap` */
  overflow-wrap: break-word;
  overflow: hidden;
  background-color: #eaecf0;
  border-color: #a2a9b1;
  /* Ensure box inner spacing is not all over the place no matter what element is only child. */
}
.mw-message-box > :only-child {
  margin: 0;
}
.mw-message-box h2 {
  color: inherit;
  display: block;
  border: 0;
  font-size: 1em;
  font-weight: bold;
}
.mw-message-box .mw-logevent-loglines li {
  font-size: 90%;
}
.mw-message-box-error {
  background-color: #fee7e6;
  border-color: #d33;
}
.mw-message-box-warning {
  background-color: #fef6e7;
  border-color: #fc3;
}
.mw-message-box-success {
  background-color: #d5fdf4;
  border-color: #14866d;
}

/**
 * Common styles and components shared by both,
 * Vector legacy and Vector modern.
 */
/**
 * ###IMPORTANT:##
 * If editing this file, please also edit the respective file in the MinervaNeue skin.
 * ####
 *
 * This file is a LESS @import match for "mediawiki.skin.variables.less"
 * when Vector 2022 is the active skin.
 * Please note, that legacy Vector is handled by
 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
 *
 * Please also note, that both current Wikimedia Design Style Guide based skins,
 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
 * repositories.
 */
/**
 * Deprecated variables
 */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-enable selector-no-vendor-prefix */
@media screen {
  /*
 * Normalize styles across rendering engines.
 * Ideally, these rules should live in core and be shared across skins.
 *
 */
  html,
  body {
    height: 100%;
  }
  :focus {
    outline-color: #3366cc;
  }
  /*
 * Vector's common typography rules,
 * including whitespace with `margin` & `padding` and list bullets
 * as part of typographic styles. Iconography wouldn't belong here.
 *
 */
  html {
    font-size: 100%;
  }
  html,
  body {
    font-family: sans-serif;
  }
  ul {
    list-style-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
  }
  pre,
  .mw-code {
    line-height: 1.3;
  }
  .mw-jump-link:not( :focus ) {
    display: block;
    position: absolute !important;
    /* stylelint-disable-line declaration-no-important */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  /* Use unscoped selector to allow edit sections outside of .mw-body-content and .vector-body (T160269) */
  .mw-editsection,
  .mw-editsection-like {
    font-family: sans-serif;
  }
  /* Main content area, including siteNotice, indicators, categories, firstHeading and `.vector-body`. */
  .mw-body h1,
  .mw-body-content h1,
  .mw-body-content h2 {
    margin-bottom: 0.25em;
    padding: 0;
    font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
    line-height: 1.3;
    /* Burmese (Myanmar) language headlines would be cropped with set `line-height` */
    /* See T193270 */
  }
  .mw-body h1:lang( ja ),
  .mw-body-content h1:lang( ja ),
  .mw-body-content h2:lang( ja ),
  .mw-body h1:lang( he ),
  .mw-body-content h1:lang( he ),
  .mw-body-content h2:lang( he ),
  .mw-body h1:lang( ko ),
  .mw-body-content h1:lang( ko ),
  .mw-body-content h2:lang( ko ) {
    /* See T65827 */
    font-family: sans-serif;
  }
  .mw-body h1:lang( ckb ),
  .mw-body-content h1:lang( ckb ),
  .mw-body-content h2:lang( ckb ) {
    /* See T306317 */
    font-family: 'Scheherazade', 'Linux Libertine', 'Georgia', 'Times', serif;
  }
  .mw-body h1:lang( my ),
  .mw-body-content h1:lang( my ),
  .mw-body-content h2:lang( my ) {
    line-height: normal;
  }
  .mw-body h1,
  .mw-body-content h1 {
    font-size: 1.8em;
  }
  .vector-body {
    font-size: 0.875em;
    font-size: calc(1em * 0.875);
    line-height: 1.6;
  }
  .vector-body #siteSub {
    font-size: 12.8px;
  }
  .vector-body h1 {
    margin-top: 1em;
  }
  .vector-body h2 {
    margin-top: 1em;
    font-size: 1.5em;
  }
  .vector-body h3,
  .vector-body h4,
  .vector-body h5,
  .vector-body h6 {
    margin-top: 0.3em;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.6;
  }
  .vector-body h3 {
    font-size: 1.2em;
  }
  .vector-body h3,
  .vector-body h4 {
    font-weight: bold;
  }
  .vector-body h4,
  .vector-body h5,
  .vector-body h6 {
    font-size: 100%;
  }
  .vector-body .toc h2 {
    font-family: sans-serif;
    font-size: 100%;
  }
  .vector-body p {
    margin: 0.5em 0;
  }
  .vector-body blockquote {
    border-left: 4px solid #eaecf0;
    padding: 8px 32px;
  }
  .vector-body blockquote > :first-child {
    margin-top: 0;
  }
  .vector-body blockquote > :last-child {
    margin-bottom: 0;
  }
  .mw-parser-output a.external {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/link-external-small-ltr-progressive.svg?2cd31);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 0.857em;
    padding-right: 1em;
  }
  .mw-indicators {
    font-size: 0.875em;
    line-height: 1.6;
    position: relative;
    float: right;
  }
  .mw-indicator {
    display: inline-block;
  }
  /** element that wraps a notice that comes from wiki page */
  #localNotice {
    margin-bottom: 0.9em;
  }
  /* Site Notice (includes notices from CentralNotice extension) */
  #siteNotice {
    font-size: 0.8em;
    margin: 24px 0;
    position: relative;
    text-align: center;
  }
  /**
 * Common styles responsible for hiding/showing the Vector dropdown.
 */
  /**
 * Vector Dropdown. Contains the dropdown label, checkbox, and
 * dropdown content.
 */
  .vector-menu-dropdown {
    position: relative;
  }
  .vector-menu-dropdown > .vector-menu-content {
    position: absolute;
    top: 100%;
    left: -1px;
    opacity: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    margin: 0;
    padding: 0;
    z-index: 3;
  }
  .vector-menu-dropdown > .vector-menu-content-list {
    list-style: none;
  }
  .vector-menu-dropdown .mw-list-item {
    padding: 0;
    margin: 0;
  }
  .vector-menu-dropdown .mw-list-item a {
    cursor: pointer;
  }
  .vector-menu-dropdown .selected a,
  .vector-menu-dropdown .selected a:visited {
    color: #202122;
    text-decoration: none;
  }
  /**
 * Invisible checkbox covering the dropdown menu handle.
 */
  .vector-menu-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
  }
  .vector-menu-checkbox:checked ~ .vector-menu-content {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  :not( :checked ) > .vector-menu-checkbox {
    display: block;
  }
  .vector-menu-checkbox:focus + .vector-menu-heading {
    outline: dotted 1px;
    outline: auto -webkit-focus-ring-color;
  }
  .vector-search-box-inner {
    position: relative;
    height: 100%;
  }
  .vector-search-box-input {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000000;
    width: 100%;
    height: 2.15384615em;
    box-sizing: border-box;
    border: 1px solid #a2a9b1;
    border-radius: 2px;
    padding: 5px 2.15384615em 5px 0.4em;
    box-shadow: inset 0 0 0 1px transparent;
    font-family: inherit;
    font-size: 0.8125em;
    direction: ltr;
    transition-property: border-color, box-shadow;
    transition-duration: 250ms;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  .vector-search-box-inner:hover .vector-search-box-input {
    border-color: #72777d;
  }
  .vector-search-box-input:focus,
  .vector-search-box-inner:hover .vector-search-box-input:focus {
    outline: 0;
    border-color: #3366cc;
    box-shadow: inset 0 0 0 1px #3366cc;
  }
  .vector-search-box-input::-webkit-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .vector-search-box-input:-ms-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .vector-search-box-input::-moz-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .vector-search-box-input::placeholder {
    color: #72777d;
    opacity: 1;
  }
  .vector-search-box-input::-webkit-search-decoration,
  .vector-search-box-input::-webkit-search-cancel-button,
  .vector-search-box-input::-webkit-search-results-button,
  .vector-search-box-input::-webkit-search-results-decoration {
    display: none;
  }
  .searchButton {
    background-color: transparent;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    min-width: 28px;
    width: 2.15384615em;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.8125em;
    /* Opera 12 on RTL flips the text in a funny way without this. */
    /* @noflip */
    direction: ltr;
    /* Hide button text and replace it with the image. */
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }
  .searchButton[name='go'] {
    background: no-repeat center / 1.23076923em url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/search.svg?ac00d);
    opacity: 0.67;
  }
  .search-toggle {
    display: block;
    float: right;
  }
  .vector-search-box-collapses > div {
    display: none;
  }
  /* Logo */
  #p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
  }
  #p-logo a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 10em;
    height: 160px;
    text-decoration: none;
  }
  /* Footer */
  .mw-footer {
    direction: ltr;
  }
  .mw-footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .mw-footer li {
    color: #202122;
    margin: 0;
    padding: 0.5em 0;
    font-size: 0.75em;
  }
  #footer-icons {
    float: right;
  }
  #footer-icons li {
    float: left;
    margin-left: 0.5em;
    line-height: 2;
    text-align: right;
  }
  #footer-info li {
    line-height: 1.4;
  }
  #footer-places li {
    float: left;
    margin-right: 1em;
    line-height: 2;
  }
}
@media screen and (min-width: 1000px) {
  .search-toggle {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .vector-search-box-collapses > div {
    display: block;
  }
}
@media print {
  /* These styles retain the existing typography in skin.less
In future (when deploying these styles) we may want to refactor skins.vector.styles
to apply certain styles in print as well as screen mode. */
  .toc,
  body {
    padding: 10px;
    font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
  }
  .printfooter,
  .mw-footer,
  .thumb,
  figure,
  table,
  ol,
  dl,
  ul,
  h3,
  h4,
  h5,
  h6 {
    font-family: sans-serif;
  }
  img {
    font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
  }
  .mw-body a:not( .image ) {
    border-bottom: 1px solid #aaa;
  }
  .firstHeading {
    font-size: 25pt;
    line-height: 28pt;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }
  .firstHeading,
  h2 {
    overflow: hidden;
    border-bottom: 2px solid #000000;
  }
  h3,
  h4,
  h5,
  h6 {
    margin: 30px 0 0;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    position: relative;
  }
  h2 {
    font-size: 18pt;
    line-height: 24pt;
    margin-bottom: 0.25em;
  }
  h3 {
    font-size: 14pt;
    line-height: 20pt;
  }
  h4,
  h5,
  h6 {
    font-size: 12pt;
    line-height: 16pt;
  }
  p {
    font-size: 12pt;
    line-height: 16pt;
    margin-top: 5px;
    text-align: justify;
  }
  p:before {
    content: '';
    display: block;
    width: 120pt;
    overflow: hidden;
  }
  blockquote {
    border-left: 2px solid #000000;
    padding-left: 20px;
  }
  ol,
  ul {
    margin: 10px 0 0 1.6em;
    padding: 0;
  }
  ol li,
  ul li {
    padding: 2px 0;
    font-size: 12pt;
  }
  table ol li,
  table ul li {
    font-size: inherit;
  }
  .toc {
    page-break-before: avoid;
    page-break-after: avoid;
    background: none;
    border: 0;
    display: table;
  }
  .toc a {
    border: 0;
    font-weight: normal;
  }
  .toc > ul > li {
    margin-bottom: 4px;
    font-weight: bold;
  }
  .toc ul {
    margin: 0;
    list-style: none;
  }
  .toc ul ul {
    padding-left: 30px;
  }
  .toc li.toclevel-1 > a {
    font-size: 12pt;
    font-weight: bold;
  }
  #mw-navigation,
  .noprint,
  .mw-jump-link,
  .mw-portlet-lang,
  .toc .tocnumber,
  .mw-checkbox-hack-checkbox,
  .mw-checkbox-hack-button {
    display: none;
  }
  .printfooter {
    margin-top: 10px;
    border-top: 3px solid #000000;
    padding-top: 10px;
    font-size: 10pt;
    clear: both;
  }
  .mw-footer {
    margin-top: 12px;
    border-top: 1px solid #eeeeee;
    padding-top: 5px;
  }
  #footer-info {
    margin: 0;
    padding: 0;
  }
  #footer-info li {
    color: #999;
    list-style: none;
    display: block;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  #footer-info li a {
    color: #999 !important;
    /* stylelint-disable-line declaration-no-important */
  }
  #footer-info-lastmod {
    color: #000000;
    font-size: 12pt;
    font-weight: bold;
  }
}

/**
 * Vector modern stylesheets
 * See '../common/common.less' for common screen and print Vector stylesheets.
 */
/**
 * ###IMPORTANT:##
 * If editing this file, please also edit the respective file in the MinervaNeue skin.
 * ####
 *
 * This file is a LESS @import match for "mediawiki.skin.variables.less"
 * when Vector 2022 is the active skin.
 * Please note, that legacy Vector is handled by
 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
 *
 * Please also note, that both current Wikimedia Design Style Guide based skins,
 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
 * repositories.
 */
/**
 * Deprecated variables
 */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-enable selector-no-vendor-prefix */
@media screen {
  /**
 * Vector modern layout styles for screen
 *
 * Layout rules divide the page into sections and how VectorComponents should be arranged in the skin.
 * The rules here should only define the layout, not color or typography.
 */
  body {
    background-color: #f8f9fa;
    color: #202122;
    overflow-y: scroll;
  }
  .mw-body,
  .parsoid-body {
    direction: ltr;
  }
  .mw-body {
    padding: 0.5em 0 1.5em;
  }
  .mw-body .firstHeading {
    word-wrap: break-word;
    margin-bottom: 0;
  }
  .mw-header {
    position: relative;
    z-index: 4;
  }
  .mw-body-content {
    margin-top: 16px;
  }
  /* Main column */
  .mw-body,
  #mw-data-after-content,
  .mw-footer {
    margin-left: 0;
  }
  /* Content */
  .mw-indicators {
    z-index: 1;
  }
  .vector-page-titlebar:after,
  .vector-body-before-content:after {
    clear: both;
    content: '';
    display: block;
  }
  .mw-body .mw-portlet-lang {
    float: right;
  }
  .vector-body {
    position: relative;
    z-index: 0;
  }
  #siteSub {
    display: none;
    margin-top: 8px;
  }
  #contentSub:not( :empty ),
  #contentSub2 {
    font-size: 84%;
    line-height: 1.2em;
    color: #54595d;
    width: auto;
    margin: 8px 0 0;
  }
  .parsoid-body {
    padding: 0.5em 0 1.5em;
  }
  .mw-page-container {
    position: relative;
    z-index: 0;
    max-width: 99.75em;
    min-width: 31.25em;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0.05px 1.5em;
    background-color: #ffffff;
    box-sizing: border-box;
  }
  .vector-feature-limited-width-disabled .mw-page-container {
    max-width: none;
  }
  .skin--responsive .mw-page-container {
    min-width: auto;
  }
  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc:after,
  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc,
  .vector-feature-page-tools-disabled .vector-main-menu {
    width: 220px;
  }
  /* Use of minmax is important to restrict the maximum grid column width more information: T314756 */
  .vector-feature-page-tools-disabled .mw-content-container,
  .vector-feature-page-tools-disabled .mw-table-of-contents-container {
    max-width: 60em;
  }
  .vector-feature-limited-width-disabled .mw-content-container,
  .vector-feature-limited-width-disabled .mw-table-of-contents-container,
  .vector-feature-limited-width-content-disabled .mw-content-container,
  .vector-feature-limited-width-content-disabled .mw-table-of-contents-container {
    /* stylelint-disable-next-line declaration-no-important */
    max-width: none !important;
    /* stylelint-disable-next-line declaration-no-important */
    width: 100% !important;
  }
  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not( :checked ) ~ .vector-sidebar-container-no-toc ~ .mw-content-container,
  .vector-feature-page-tools-disabled .vector-toc-unpinned #mw-sidebar-checkbox:not( :checked ) ~ .mw-content-container,
  .vector-feature-page-tools-disabled.vector-toc-unpinned #mw-sidebar-checkbox:not( :checked ) ~ .mw-content-container {
    grid-column: mainMenu / pageContent;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-sidebar-container-no-toc ~ .mw-content-container,
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-toc-unpinned .mw-content-container,
  .vector-feature-page-tools-enabled.vector-toc-unpinned.vector-feature-main-menu-pinned-disabled .mw-content-container {
    grid-column: mainMenu / pageContent;
    margin-left: auto;
    margin-right: auto;
  }
  /**
 * Loading indicator for search widget
 *
 * By adding a class on the parent search form
 * <div id="simpleSearch" class="search-form__loader"></div>
 * A pseudo element is added via ':after' that mimics the appearance
 * of the search suggestion and contains the text
 * "Loading search suggestions" (message key: vector-search-loader).
 *
 * After appearing for more than a second, a progress-bar animation appears
 * above the loading indicator.
 *
 **/
  .search-form__loader:after {
    content: attr(data-loading-msg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 100%;
    width: 100%;
    height: calc( 40px + 2*8px + 1px );
    box-sizing: border-box;
    border: 1px solid #a2a9b1;
    border-top-width: 0;
    border-radius: 0 0 2px 2px;
    box-shadow: inset 0 0 0 1px transparent;
    padding-left: 36px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
    background: /*image*/ linear-gradient(90deg, #3366cc 0%, #3366cc 100%) /* position / size*/ -10% 0 / 0 2px /* repeat */ no-repeat, /*second bg, just color*/ #ffffff;
    animation: /* name */ search-loader-progress-bar /* duration */ 1200ms /* timing function */ linear /* delay */ 500ms /* iteration count */ infinite /* fill direction */ alternate;
  }
  .vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .search-form__loader:after {
    margin-left: 24px;
    width: calc( 100% - 24px );
  }
  @keyframes search-loader-progress-bar {
    0% {
      background-size: 0 2px;
      background-position: -10% 0;
    }
    30% {
      background-size: 30% 2px;
      background-position: -10% 0;
    }
    70% {
      background-size: 30% 2px;
      background-position: 110% 0;
    }
    100% {
      background-size: 0 2px;
      background-position: 110% 0;
    }
  }
  /**
 * Minimal styling for initial no-JS server-rendered
 * search form, which gets replaced by Codex on focus.
 * Most values are hard-coded since they aim to
 * mimic Codex-specific variables and disable the
 * ResourceLoader LESS transformation of `calc`.
 */
  .vector-search-box {
    font-size: 0.875em;
    font-size: calc(1em * 0.875);
    flex-grow: 1;
  }
  .vector-search-box > div {
    max-width: 35.71428571em;
  }
  .vector-search-box-vue .vector-search-box-input,
  .vector-search-box-vue .searchButton {
    font-size: inherit;
  }
  .vector-search-box-vue .vector-search-box-input {
    height: auto;
    min-height: 32px;
    line-height: 1.4285714;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .vector-search-box-vue .searchButton {
    background-size: 20px auto;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-search-input__end-button {
    opacity: 0;
    transition: opacity 250ms;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-search-input__end-button:focus {
    opacity: 1;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-text-input__input:not( :hover ):not( :focus ) {
    border-right-color: transparent;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-search-input__end-button,
  .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-search-input__end-button {
    opacity: 1;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-text-input,
  .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-text-input {
    z-index: 1;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-text-input__input,
  .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-text-input__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-menu-item {
    margin-bottom: 0;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-menu-item a {
    text-decoration: none;
  }
  .client-js .vector-search-box-vue .vector-typeahead-search.vector-search-box-disable-transitions .cdx-text-input__input:enabled,
  .client-js .vector-search-box-vue .vector-typeahead-search.vector-search-box-disable-transitions .cdx-text-input__start-icon {
    transition: none;
  }
  .client-js .vector-search-box-vue .vector-search-box-input {
    padding-left: 36px;
    padding-right: 8px;
  }
  .client-js .vector-search-box-vue .searchButton {
    pointer-events: none;
    right: auto;
    top: 0;
    bottom: 0;
    left: 1px;
    width: 36px;
    opacity: 0.51;
    background-size: 1.42857143em auto;
    background-position-x: 8px;
  }
  .client-js .vector-search-box-vue .vector-search-box-input:focus ~ .searchButton {
    opacity: 0.87;
  }
  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail > div {
    max-width: 37.42857143em;
  }
  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .searchButton {
    left: 25px;
  }
  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .vector-search-box-input {
    margin-left: 24px;
    width: calc( 100% - 24px );
  }
  /**
 * Less mixins shared between both legacy and Vector 2022.
 */
  .mixin-vector-legacy-menu-heading-arrow {
    content: '';
    background: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f) 100% 50% no-repeat;
    width: 1.23076923em;
    height: 1.23076923em;
  }
  .vector-page-titlebar .mw-portlet-lang {
    margin-top: 2px;
    box-sizing: border-box;
    height: 2em;
    flex-shrink: 0;
  }
  .vector-page-titlebar .mw-portlet-lang.mw-ui-icon-flush-right {
    margin-right: -12px;
  }
  .client-nojs .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-0 {
    display: none;
  }
  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading {
    user-select: none;
    opacity: 1;
  }
  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading .vector-menu-heading-label {
    font-size: 0.875em;
  }
  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading.mw-ui-progressive.mw-ui-quiet .mw-ui-icon:before {
    opacity: 1;
  }
  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading.mw-ui-progressive.mw-ui-quiet:after {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down-progressive.svg?f0b59);
    opacity: 1;
  }
  .vector-page-titlebar .mw-portlet-lang input:active + .vector-menu-heading.mw-ui-progressive.mw-ui-quiet .mw-ui-icon {
    filter: brightness(0) invert(1);
  }
  .vector-page-titlebar .mw-portlet-lang input:active + .vector-menu-heading.mw-ui-progressive.mw-ui-quiet:after {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down-invert.svg?e24ea);
  }
  .vector-page-titlebar .mw-portlet-lang > .vector-menu-content {
    top: auto;
    left: -1px;
    right: -1px;
    box-sizing: border-box;
    max-height: 65vh;
    overflow: auto;
    display: none;
  }
  .vector-page-titlebar .mw-portlet-lang > .vector-menu-content li a {
    font-size: inherit;
  }
  .vector-page-titlebar .mw-portlet-lang .vector-menu-checkbox:checked ~ .vector-menu-content {
    display: block;
  }
  .vector-page-titlebar .mw-portlet-lang .after-portlet {
    margin-top: 10px;
  }
  .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-empty + .vector-menu-content {
    left: auto;
    right: 0;
    min-width: 300px;
  }
  .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-empty + .vector-menu-content .mw-portlet-empty-language-selector-body {
    padding: 20px;
    border-bottom: solid 1px #c8ccd1;
    color: #54595d;
  }
  #p-lang-btn.mw-portlet-empty {
    display: none;
  }
  .mw-interlanguage-selector {
    display: flex;
  }
  .mw-interlanguage-selector:after {
    content: '';
    background: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f) 100% 50% no-repeat;
    width: 1.23076923em;
    height: 1.23076923em;
    margin-left: -1px;
  }
  .vector-dropdown > .vector-menu-heading.mw-portlet-lang-heading-0 {
    display: none;
  }
  .client-js .action-view .vector-dropdown > .vector-menu-heading.mw-portlet-lang-heading-0 {
    display: flex;
  }
  /**
 * Container that holds both the horizontal menu and dropdown menus.
 */
  .vector-user-links {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    flex-shrink: 1;
    font-size: 0.875em;
  }
  .vector-user-links .mw-ui-button {
    display: flex;
    align-items: center;
  }
  .vector-user-links .mw-list-item {
    margin: 0;
  }
  /**
 * Both logged-in and logged-out dropdown menus.
 */
  .vector-user-menu .vector-menu-content {
    left: auto;
    right: 0;
  }
  .vector-feature-page-tools-disabled .vector-user-menu .vector-menu-content {
    min-width: 200px;
  }
  .vector-user-menu-logout a,
  .vector-user-menu-create-account a,
  .vector-user-menu-login a,
  .mw-portlet-personal .mw-list-item a {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    color: #3366cc;
  }
  .vector-user-menu-logout a:visited,
  .vector-user-menu-create-account a:visited,
  .vector-user-menu-login a:visited,
  .mw-portlet-personal .mw-list-item a:visited {
    color: #3366cc;
  }
  .vector-user-menu-logout a.selected a,
  .vector-user-menu-create-account a.selected a,
  .vector-user-menu-login a.selected a,
  .mw-portlet-personal .mw-list-item a.selected a,
  .vector-user-menu-logout a.selected a:visited,
  .vector-user-menu-create-account a.selected a:visited,
  .vector-user-menu-login a.selected a:visited,
  .mw-portlet-personal .mw-list-item a.selected a:visited {
    color: #202122;
  }
  .vector-feature-page-tools-disabled .vector-user-menu-logout a,
  .vector-feature-page-tools-disabled .vector-user-menu-create-account a,
  .vector-feature-page-tools-disabled .vector-user-menu-login a,
  .vector-feature-page-tools-disabled .mw-portlet-personal .mw-list-item a {
    min-height: 2rem;
    padding: 0 12px;
  }
  /**
 * Horizontal links menu - logged in and logged out.
 */
  .vector-user-links .mw-portlet-vector-user-menu-overflow .vector-menu-content-list {
    display: flex;
    align-items: center;
  }
  .vector-user-links .mw-portlet-vector-user-menu-overflow a:not( .mw-ui-button ):not( .mw-echo-notifications-badge  ) {
    margin: 0 8px;
  }
  /**
 * Logged-in dropdown menu
 */
  .vector-user-menu-logged-in .vector-menu-heading {
    width: auto;
  }
  .vector-user-menu-logged-in .vector-menu-heading:before {
    width: auto;
  }
  /**
 * Dropdown menu items- Special treatment for special links.
 */
  .vector-user-menu-anon-editor {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    color: #54595d;
  }
  .vector-user-menu-anon-editor:visited {
    color: #3366cc;
  }
  .vector-user-menu-anon-editor.selected a,
  .vector-user-menu-anon-editor.selected a:visited {
    color: #202122;
  }
  .vector-feature-page-tools-disabled .vector-user-menu-anon-editor {
    min-height: 2rem;
    padding: 0 12px;
  }
  .vector-feature-page-tools-enabled .vector-user-menu-anon-editor p {
    margin: 0;
  }
  .vector-user-menu-anon-editor a:before {
    content: '(';
  }
  .vector-user-menu-anon-editor a:after {
    content: ')';
  }
  .vector-user-menu-login {
    border-bottom: 1px solid #eaecf0;
  }
  .vector-user-menu-logout {
    border-top: 1px solid #eaecf0;
  }
  #pt-userpage-2 {
    max-width: 11.07142857em;
  }
  #pt-userpage-2 a {
    color: #3366cc;
  }
  #pt-userpage-2 span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mw-header {
    min-height: 3.125em;
    padding: 8px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .mw-header .vector-header-start,
  .mw-header .vector-header-end {
    display: flex;
    align-items: center;
  }
  .mw-header .vector-header-end {
    flex-grow: 1;
  }
  .mw-footer {
    border-top: 1px solid #a2a9b1;
    padding: 0.75em 0;
  }
  .mw-footer-container {
    padding-top: 50px;
    padding-bottom: 82px;
  }
  .vector-menu ul {
    list-style: none;
    margin: 0;
  }
  /**
 * Targets all dropdown labels.
 * - language variants, Actions menus
 * - more menu, user menu
 * - ULS button in sticky header
 */
  .vector-dropdown > .vector-menu-heading {
    display: flex;
  }
  .vector-dropdown > .vector-menu-heading:after {
    content: '';
    background: url(https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f) 100% 50% no-repeat;
    width: 1.23076923em;
    height: 1.23076923em;
    margin-left: -1px;
  }
  /**
 * Dropdown container
 */
  .vector-dropdown > .vector-menu-content {
    background-color: #ffffff;
    border: 1px solid #a2a9b1;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    transition-property: opacity;
    transition-duration: 100ms;
  }
  .vector-feature-page-tools-disabled .vector-dropdown > .vector-menu-content {
    min-width: 100%;
  }
  .vector-feature-page-tools-enabled .vector-dropdown > .vector-menu-content {
    width: max-content;
    max-width: 200px;
  }
  /**
 * Dropdown menu items.
 */
  .vector-dropdown .mw-list-item a {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    color: #3366cc;
  }
  .vector-dropdown .mw-list-item a:visited {
    color: #3366cc;
  }
  .vector-dropdown .mw-list-item a.selected a,
  .vector-dropdown .mw-list-item a.selected a:visited {
    color: #202122;
  }
  .vector-feature-page-tools-disabled .vector-dropdown .mw-list-item a {
    min-height: 2rem;
    padding: 0 12px;
  }
  /**
 * Styling for namespace tabs (page, discussion) and views (read, edit, view history, watch and other actions)
 */
  /* Tab list items */
  .vector-menu-tabs {
    float: left;
    /* focus and hover have outlines. Text underline interferes with bottom border */
  }
  .vector-menu-tabs .mw-list-item .mw-ui-icon {
    margin: 8px 0 0 0;
  }
  .vector-menu-tabs .mw-list-item a {
    color: #3366cc;
  }
  .vector-menu-tabs .mw-list-item.vector-tab-noicon a:focus,
  .vector-menu-tabs .mw-list-item.vector-tab-noicon a:hover {
    text-decoration: none;
    border-bottom: 1px solid;
  }
  .vector-menu-tabs .mw-list-item.new a,
  .vector-menu-tabs .mw-list-item.new a:visited {
    color: #dd3333;
  }
  .vector-menu-tabs .mw-list-item.selected a,
  .vector-menu-tabs .mw-list-item.selected a:visited {
    color: #202122;
    border-bottom: 1px solid;
  }
  /**
 * Tab list item appearance. Applies to both <li>'s inside .vector-menu-tabs
 * and dropdown menus inside the article toolbar
 */
  .vector-menu-tabs .mw-list-item.vector-tab-noicon,
  .vector-page-toolbar-container .vector-menu-dropdown {
    margin: 0 8px;
  }
  .vector-menu-tabs .mw-list-item,
  .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    white-space: nowrap;
  }
  .vector-menu-tabs .mw-list-item,
  .vector-page-toolbar-container .vector-menu-dropdown {
    float: left;
    margin-bottom: 0;
    /* overrides default `li` styling */
  }
  .vector-menu-tabs .mw-list-item > a,
  .vector-page-toolbar-container .vector-menu-dropdown > a,
  .vector-menu-tabs .mw-list-item > .vector-menu-heading,
  .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    max-height: 3.15384615em;
    box-sizing: border-box;
    font-weight: normal;
  }
  .vector-menu-tabs .mw-list-item > .vector-menu-heading,
  .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    font-size: inherit;
  }
  .vector-menu-tabs .mw-list-item.vector-tab-noicon > a,
  .vector-page-toolbar-container .vector-menu-dropdown.vector-tab-noicon > a,
  .vector-menu-tabs .mw-list-item > .vector-menu-heading,
  .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    padding: 18px 0 7px 0;
    margin-bottom: -1px;
  }
  .mw-checkbox-hack-checkbox {
    display: none;
  }
  .mw-checkbox-hack-button {
    display: inline-block;
    cursor: pointer;
  }
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-enabled .vector-header-start .vector-main-menu-landmark,
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-main-menu-container .vector-main-menu-landmark {
    display: none;
  }
  .vector-main-menu {
    box-sizing: border-box;
  }
  .vector-main-menu #p-navigation .vector-menu-heading {
    display: none;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a,
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a:visited,
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content:visited {
    color: #3366cc;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a.selected a,
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content.selected a,
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a.selected a:visited,
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content.selected a:visited {
    color: #202122;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a {
    font-weight: bold;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    padding-top: 0;
  }
  .vector-feature-page-tools-enabled .vector-main-menu .vector-language-sidebar-alert {
    padding: 8px;
    margin: 0;
  }
  .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu {
    margin-left: -14px;
    margin-top: 11px;
    padding: 6px 14px;
    background-color: #f8f9fa;
  }
  .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu .vector-main-menu-action-opt-out a,
  .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    padding-left: 0;
    padding-right: 0;
  }
  #vector-main-menu-unpinned-container .vector-main-menu .vector-language-sidebar-alert {
    margin-bottom: 6px;
  }
  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not( :checked ) ~ .vector-main-menu-container .vector-main-menu {
    display: none;
  }
  .vector-feature-page-tools-disabled .vector-main-menu {
    padding: 12px 19px 12px 9px;
    background-color: #f8f9fa;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-heading {
    margin-bottom: 0.75em;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-content > * {
    font-size: 0.75em;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-content > a {
    font-weight: bold;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-language-sidebar-alert {
    padding: 0.75em;
  }
  #mw-sidebar-button:before {
    /* @embed */
  }
  .vector-feature-page-tools-disabled #mw-sidebar-button:before {
    background-image: url(https://en.wikipedia.org/w/skins/Vector/resources/skins.vector.styles/images/chevronHorizontal-ltr.svg?c223c);
  }
  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not( :checked ) ~ .mw-header #mw-sidebar-button:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3E menu %3C/title%3E %3Cpath d=%22M1 3v2h18V3zm0 8h18V9H1zm0 6h18v-2H1z%22/%3E %3C/svg%3E");
  }
  #mw-sidebar-button:hover:before {
    opacity: 1;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-heading,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-heading {
    align-self: center;
    color: #54595d;
    font-weight: normal;
    cursor: default;
    border-color: #c8ccd1;
    border-width: 1px;
    font-size: 0.75em;
    margin: 0.5em 0 0 0.66666667em;
    padding: 0.25em 0;
    border-bottom-style: solid;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content {
    margin-left: 0.5em;
    padding-top: 0;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content ul,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content ul {
    padding-top: 0.3em;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li a,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li a {
    color: #3366cc;
  }
  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li a:visited,
  .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li a:visited {
    color: #795cb2;
  }
  .vector-page-titlebar {
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 1px #a2a9b1;
    align-items: center;
  }
  .vector-page-titlebar-blank {
    box-shadow: none;
  }
  .vector-page-titlebar .firstHeading {
    flex-grow: 1;
    border: 0;
  }
  .vector-page-titlebar .vector-page-titlebar-toc.ve-active {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }
  .vector-page-toolbar-container {
    display: flex;
    font-size: 0.8125em;
    box-shadow: 0 1px #c8ccd1;
    margin-bottom: 1px;
  }
  .vector-page-toolbar-container .mw-portlet-views {
    display: none;
  }
  #left-navigation {
    display: flex;
    margin-left: -8px;
    flex-grow: 1;
  }
  #right-navigation {
    display: flex;
    margin-right: -8px;
  }
  #right-navigation .vector-menu-content {
    left: auto;
    right: -1px;
  }
  .vector-feature-page-tools-pinned-enabled .vector-page-toolbar-container .vector-page-tools-landmark,
  .vector-feature-page-tools-pinned-disabled .vector-column-end .vector-page-tools-landmark {
    display: none;
  }
  #vector-page-tools-pinned-container .vector-page-tools {
    width: 140px;
    padding-left: 14px;
  }
  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-more-collapsible-item,
  .vector-page-tools .vector-more-collapsible-item {
    display: block;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-header {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    display: block;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-header:visited {
    color: #3366cc;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-header.selected a,
  .vector-feature-page-tools-enabled .vector-pinnable-header.selected a:visited {
    color: #202122;
  }
  .vector-pinnable-header[hidden] {
    display: none;
  }
  .vector-feature-page-tools-enabled .vector-pinned-container .vector-page-tools .vector-pinnable-header,
  .vector-feature-page-tools-enabled .vector-pinned-container .vector-toc .vector-pinnable-header {
    padding: 18px 0 7px 0;
    margin-bottom: -1px;
  }
  .vector-pinnable-header-label {
    display: inline-block;
    color: #54595d;
    font-size: 0.875rem;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .vector-pinnable-header-toggle-button {
    display: none;
    border: 0;
    padding: 0;
    background-color: transparent;
    color: #3366cc;
    cursor: pointer;
  }
  .vector-pinnable-header-toggle-button:hover {
    color: #447ff5;
  }
  .vector-pinnable-header-toggle-button:before {
    content: '[';
    color: #54595d;
  }
  .vector-pinnable-header-toggle-button:after {
    content: ']';
    color: #54595d;
  }
  .client-js .vector-pinnable-header-unpinned .vector-pinnable-header-pin-button,
  .client-js .vector-pinnable-header-pinned .vector-pinnable-header-unpin-button {
    display: inline-block;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element > *:not( :last-child ) {
    border-bottom: 1px solid #eaecf0;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    align-self: center;
    color: #54595d;
    font-weight: normal;
    cursor: default;
    border-color: #c8ccd1;
    border-width: 1px;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading:visited {
    color: #3366cc;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading.selected a,
  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading.selected a:visited {
    color: #202122;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a {
    display: flex;
    align-items: center;
    padding: 6px 14px;
    font-size: 0.875rem;
    color: #3366cc;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a:visited {
    color: #3366cc;
  }
  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a.selected a,
  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a.selected a:visited {
    color: #202122;
  }
  .vector-unpinned-container .vector-pinnable-element {
    padding: 4px 0;
  }
  .vector-pinned-container .vector-pinnable-element .vector-pinnable-header,
  .vector-pinned-container .vector-pinnable-element .vector-menu-heading,
  .vector-pinned-container .vector-pinnable-element .mw-list-item a {
    padding-left: 0;
    padding-right: 0;
  }
  /**
 * At lower resolutions, we want to hide the pinned containers since these
 * elements collapse (become unpinned) at this resolution via PinnableElement.js.
 * Although this is handled in JS, this rule prevents the pinned menu from
 * appearing on pageload, at low resolutions, before the JS kicks in.
 */
  .vector-sticky-header {
    width: 100%;
    max-width: 99.75em;
    height: 3.125rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
    transition: transform 250ms linear;
    align-items: center;
    margin: 0 auto;
    background: #ffffff;
    background-color: #fffffff7;
    border-bottom: 1px solid #eaecf0;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px 2.75em;
    display: none;
    transform: translateY(-100%);
    opacity: 0;
  }
  .vector-feature-limited-width-disabled .vector-sticky-header {
    max-width: none;
  }
  .vector-sticky-header-start,
  .vector-sticky-header-end,
  .vector-sticky-header-icons,
  .vector-sticky-header-context-bar {
    display: flex;
    align-items: center;
  }
  .vector-sticky-header-start {
    flex-grow: 1;
    min-width: 0;
  }
  .vector-sticky-header-context-bar-primary,
  .vector-sticky-header-end {
    white-space: nowrap;
  }
  .vector-sticky-header-context-bar {
    border-left: 1px solid #c8c8c8;
    padding-left: 30px;
    min-width: 0;
    margin-left: 30px;
  }
  .vector-sticky-header-context-bar-primary {
    overflow: hidden;
    font-family: 'Linux Libertine', 'Georgia', 'Times', serif;
    font-size: 1.5em;
    text-overflow: ellipsis;
  }
  .vector-sticky-header-context-bar-primary wbr {
    display: none;
  }
  .vector-sticky-header-icons {
    font-size: 0.875em;
    column-gap: 8px;
  }
  .vector-sticky-header .vector-search-box {
    display: none;
  }
  .vector-sticky-header.vector-header-search-toggled .vector-sticky-header-search-toggle,
  .vector-sticky-header.vector-header-search-toggled .vector-sticky-header-context-bar {
    display: none;
  }
  .vector-sticky-header.vector-header-search-toggled .vector-search-box {
    display: block;
    margin-left: 4px;
  }
  .vector-sticky-header.vector-header-search-toggled .vector-search-box-show-thumbnail {
    margin-left: -9px;
  }
  .vector-sticky-header.vector-header-search-toggled .vector-search-box-show-thumbnail .cdx-text-input__start-icon {
    color: #202122;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* Watch/Unwatch Icon Styling */
  /* Only use icon if the menu item is not collapsed into the "More" dropdown
 * (in which case it is inside `.vector-menu-dropdown` instead of `.vector-menu-tabs`). */
  .mw-watchlink a:before {
    transition: transform 500ms;
  }
  .mw-watchlink .loading:before {
    /* Suppress the hilarious rotating focus outline on Firefox */
    outline: 0;
    cursor: default;
    pointer-events: none;
    transform-origin: 50% 50%;
  }
  .mw-ui-icon-wikimedia-unStar:before,
  .mw-ui-icon-wikimedia-star.loading:before {
    transform: rotate(72deg);
  }
  .vector-toc {
    max-height: 75vh;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #ffffff;
  }
  .vector-feature-page-tools-disabled .vector-toc {
    padding: 20px 12px 20px 27px;
  }
  .vector-feature-page-tools-disabled .vector-toc .vector-toc-pinnable-header {
    padding: 0 0 12px 0;
    border: 0;
    line-height: initial;
  }
  .vector-toc .vector-pinnable-header-label {
    overflow: unset;
  }
  .vector-toc .vector-toc-numb {
    display: none;
  }
  .vector-toc .vector-toc-toggle {
    display: none;
    position: absolute;
    top: 1px;
    left: calc( -1 * 1.834em - 1px );
    width: 1.834em;
    height: 1.834em;
    font-size: 0.75em;
    transition: 100ms;
    color: transparent;
    cursor: pointer;
  }
  .vector-feature-page-tools-enabled .vector-toc .vector-toc-toggle {
    margin-top: 2px;
  }
  .vector-toc .vector-toc-link {
    word-break: break-word;
    color: #3366cc;
    display: block;
  }
  .vector-toc .vector-toc-list-item-active > .vector-toc-link,
  .vector-toc .vector-toc-level-1-active:not( .vector-toc-list-item-expanded ) > .vector-toc-link,
  .vector-toc .vector-toc-list-item-active.vector-toc-level-1-active > .vector-toc-link {
    color: #202122;
    font-weight: bold;
  }
  .vector-toc .vector-toc-list-item-active > .vector-toc-link .vector-toc-text,
  .vector-toc .vector-toc-level-1-active:not( .vector-toc-list-item-expanded ) > .vector-toc-link .vector-toc-text,
  .vector-toc .vector-toc-list-item-active.vector-toc-level-1-active > .vector-toc-link .vector-toc-text {
    width: calc( 100% + 14px );
  }
  .vector-toc .vector-toc-level-1-active:not( .vector-toc-list-item-active ) > .vector-toc-link {
    color: #202122;
  }
  .vector-feature-page-tools-disabled .vector-toc .vector-toc-text {
    padding: 4px 0;
  }
  .vector-feature-page-tools-enabled .vector-toc .vector-toc-text {
    padding: 6px 0;
  }
  .vector-toc .vector-toc-contents,
  .vector-toc .vector-toc-list {
    margin: 0;
    list-style: none;
  }
  .vector-feature-page-tools-disabled .vector-toc .vector-toc-contents,
  .vector-feature-page-tools-disabled .vector-toc .vector-toc-list {
    line-height: 18px;
  }
  .vector-toc .vector-toc-list-item {
    display: block;
    position: relative;
    list-style-type: none;
    padding-left: 8px;
  }
  .vector-feature-page-tools-enabled .vector-toc .vector-toc-list-item {
    margin: 0;
  }
  .vector-toc .vector-toc-list-item.vector-toc-level-1 {
    padding-left: 0;
  }
  .vector-toc .vector-toc-list-item a {
    font-size: 0.875em;
  }
  .client-js .vector-toc .vector-toc-level-1 .vector-toc-list-item {
    display: none;
  }
  .client-js .vector-toc .vector-toc-level-1.vector-toc-list-item-expanded .vector-toc-list-item {
    display: block;
  }
  .client-js .vector-toc .vector-toc-toggle {
    display: block;
  }
  .client-js .vector-toc .vector-toc-level-1.vector-toc-list-item-expanded .vector-toc-toggle {
    transform: rotate(0deg);
  }
  .client-js body.ltr .vector-toc .vector-toc-toggle {
    transform: rotate(-90deg);
  }
  .client-js body.rtl .vector-toc .vector-toc-toggle {
    transform: rotate(90deg);
  }
  .mw-table-of-contents-container {
    align-self: start;
    height: 100%;
  }
  .vector-feature-page-tools-enabled .vector-unpinned-container .vector-toc .vector-pinnable-header {
    padding-left: 36px;
  }
  .vector-feature-page-tools-enabled .vector-unpinned-container .vector-toc .vector-toc-contents {
    padding-right: 14px;
    padding-left: 36px;
  }
  #vector-toc-collapsed-button,
  .vector-sticky-header-toc,
  .vector-page-titlebar-toc {
    display: none;
    margin-right: 8px;
  }
  .vector-feature-page-tools-disabled .vector-unpinned-container .vector-toc {
    box-sizing: content-box;
  }
  .mw-ui-icon:before {
    opacity: 0.87;
  }
}
@media screen and (min-width: 1000px) {
  .mw-page-container {
    padding-left: 2.75em;
    padding-right: 2.75em;
  }
}
@media screen and (min-width: 1200px) {
  .mw-page-container {
    padding-left: 3.25em;
    padding-right: 3.25em;
  }
}
@media screen and (min-width: 1200px) {
  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc:after,
  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc,
  .vector-feature-page-tools-disabled .vector-main-menu {
    width: 244px;
  }
}
@media screen and (min-width: 1000px) {
  .mw-page-container-inner {
    display: grid;
    column-gap: 36px;
    grid-template: min-content min-content min-content 1fr min-content / 12.25em minmax(0, 1fr);
    grid-template-areas: 'header header' 'siteNotice siteNotice' 'mainMenu pageContent' 'toc pageContent' 'footer footer';
  }
  .vector-feature-page-tools-disabled .mw-page-container-inner {
    column-gap: 44px;
  }
  .vector-sitenotice-container {
    grid-area: siteNotice;
  }
  .mw-table-of-contents-container {
    grid-area: toc;
  }
  .mw-header {
    grid-area: header;
  }
  .vector-main-menu-container {
    grid-area: mainMenu;
  }
  .mw-content-container {
    grid-area: pageContent;
  }
  .mw-footer-container {
    grid-area: footer;
  }
}
@media screen and (min-width: 1200px) {
  .mw-page-container-inner {
    grid-template-columns: 15.5em minmax(0, 1fr);
  }
  .vector-feature-page-tools-enabled .mw-header {
    display: grid;
    column-gap: 36px;
    grid-template: auto / 15.5em minmax(0, 1fr);
    grid-template-areas: 'headerStart headerEnd';
  }
  .vector-feature-page-tools-enabled .mw-header .vector-header-start {
    grid-area: headerStart;
  }
  .vector-feature-page-tools-enabled .mw-header .vector-header-end {
    grid-area: headerEnd;
  }
}
@media screen and (min-width: 1000px) {
  .vector-feature-page-tools-enabled .mw-body {
    display: grid;
    grid-template: min-content min-content min-content 1fr / minmax(0, 60em) min-content;
    /**
		 * NOTE: T327715 - 'titlebar-cx' grid area is a temporary workaround to accommodate
		 * the Content Translation extension and should not be used beyond that.
		 * FIXME: This can be removed when T322740 is resolved.
		 */
    grid-template-areas: 'titlebar-cx .' 'titlebar .' 'toolbar columnEnd' 'content columnEnd';
  }
  .vector-feature-page-tools-pinned-enabled.vector-feature-page-tools-enabled .mw-body {
    column-gap: 36px;
  }
  .vector-feature-limited-width-disabled.vector-feature-page-tools-enabled .mw-body,
  .vector-feature-limited-width-content-disabled.vector-feature-page-tools-enabled .mw-body {
    grid-template-columns: minmax(0, 1fr) min-content;
  }
  .vector-feature-page-tools-enabled .mw-body .vector-page-titlebar {
    grid-area: titlebar;
  }
  .vector-feature-page-tools-enabled .mw-body .vector-page-toolbar {
    grid-area: toolbar;
  }
  .vector-feature-page-tools-enabled .mw-body #bodyContent {
    grid-area: content;
  }
  .vector-feature-page-tools-enabled .mw-body .vector-column-end {
    grid-area: columnEnd;
  }
}
@media screen and (min-width: 720px) {
  .vector-user-menu .user-links-collapsible-item {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .vector-user-links .mw-portlet-vector-user-menu-overflow {
    margin-right: 8px;
  }
  .vector-user-links .mw-portlet-vector-user-menu-overflow .vector-menu-content-list {
    column-gap: 8px;
  }
}
@media screen and (max-width: 719px) {
  .vector-user-links .mw-portlet-vector-user-menu-overflow .user-links-collapsible-item {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  #pt-userpage-2 {
    max-width: 14.28571429em;
  }
}
@media screen and (min-width: 1200px) {
  .vector-feature-page-tools-disabled .mw-header .vector-search-box:not( .vector-search-box-auto-expand-width ),
  .vector-feature-page-tools-disabled:not( .client-js ) .mw-header .vector-search-box,
  html:not( .client-js ) .vector-feature-page-tools-disabled .mw-header .vector-search-box {
    padding-left: 24px;
  }
  .client-js.vector-feature-page-tools-enabled .mw-header .vector-search-box.vector-search-box-auto-expand-width,
  .client-js .vector-feature-page-tools-enabled .mw-header .vector-search-box.vector-search-box-auto-expand-width {
    margin-left: -24px;
  }
}
@media screen and (min-width: 1000px) {
  .mw-header .vector-search-box {
    margin-right: 12px;
  }
}
@media screen and (max-width: 999px) {
  .mw-header {
    /**
	 	 * Toggles the visibility of the search box at lower resolutions.
		 */
  }
  .mw-header.vector-header-search-toggled .vector-header-start,
  .mw-header.vector-header-search-toggled .search-toggle {
    display: none;
  }
  .mw-header.vector-header-search-toggled .vector-search-box-collapses > div {
    display: block;
  }
  .mw-header.vector-header-search-toggled .vector-search-box {
    position: relative;
    margin-right: 12px;
  }
  .mw-header.vector-header-search-toggled .vector-search-box > div {
    max-width: none;
  }
  .vector-feature-page-tools-disabled .mw-header.vector-header-search-toggled .vector-search-box {
    margin-left: 8px;
  }
  .mw-header.vector-header-search-toggled .cdx-search-input__input-wrapper {
    position: static;
  }
  .mw-header.vector-header-search-toggled .cdx-typeahead-search--expanded .cdx-search-input__end-button {
    border-bottom-right-radius: 0;
  }
}
@media screen and (max-width: 999px) {
  .vector-feature-page-tools-disabled .vector-main-menu {
    width: 100%;
  }
}
@media screen and (min-width: 1000px) {
  .vector-feature-page-tools-disabled .vector-main-menu {
    margin-left: -28px;
  }
}
@media screen and (max-width: 999px) {
  .vector-page-titlebar .vector-page-titlebar-toc .vector-menu-heading {
    padding: 7px 12px;
  }
}
@media screen and (min-width: 720px) {
  .vector-page-toolbar-container .mw-portlet-views:not( .emptyPortlet ) {
    display: block;
  }
}
@media screen and (min-width: 720px) {
  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-more-collapsible-item,
  .vector-page-tools .vector-more-collapsible-item {
    display: none;
  }
}
@media screen and (max-width: 719px) {
  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-has-collapsible-items,
  .vector-page-tools .vector-has-collapsible-items {
    display: block;
  }
}
@media screen and (max-width: 999px) {
  .vector-pinned-container {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .vector-sticky-header {
    padding: 6px 3.25em;
  }
}
@media screen and (min-width: 1000px) {
  .client-js.vector-sticky-header-enabled {
    scroll-padding-top: 3.125rem;
  }
  .client-js.vector-sticky-header-enabled .vector-sticky-header {
    display: flex;
  }
  .client-js.vector-sticky-header-enabled .vector-sticky-header-visible .vector-sticky-header {
    opacity: 1;
    transform: translateY(0);
  }
  .client-js.vector-sticky-header-enabled .vector-toc-pinned #vector-toc-pinned-container,
  .client-js.vector-sticky-header-enabled .mw-sticky-header-element,
  .client-js.vector-sticky-header-enabled .charts-stickyhead th {
    /* stylelint-disable-next-line declaration-no-important */
    top: 3.125rem !important;
  }
}
@media screen and (min-width: 1000px) {
  .vector-toc-pinned #mw-panel-toc {
    contain: paint;
  }
  .vector-feature-page-tools-disabled .vector-toc-pinned #mw-panel-toc,
  .vector-feature-page-tools-disabled.vector-toc-pinned #mw-panel-toc {
    margin-left: -27px;
  }
  .vector-feature-page-tools-enabled .vector-toc-pinned #mw-panel-toc,
  .vector-feature-page-tools-enabled.vector-toc-pinned #mw-panel-toc {
    margin-left: -22px;
  }
  #vector-toc-pinned-container {
    position: sticky;
    top: 0;
  }
  .vector-toc-pinned #vector-toc-pinned-container {
    padding-top: 1.5em;
  }
  .vector-feature-page-tools-disabled .vector-toc-pinned #mw-sidebar-checkbox:not( :checked ) ~ .mw-table-of-contents-container #vector-toc-pinned-container,
  .vector-feature-page-tools-disabled.vector-toc-pinned #mw-sidebar-checkbox:not( :checked ) ~ .mw-table-of-contents-container #vector-toc-pinned-container {
    margin-top: 1.5em;
  }
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-toc-pinned #vector-toc-pinned-container,
  .vector-feature-page-tools-enabled.vector-toc-pinned.vector-feature-main-menu-pinned-disabled #vector-toc-pinned-container {
    margin-top: 1.34em;
  }
  .vector-feature-page-tools-enabled #vector-toc-pinned-container .vector-toc {
    padding-left: 22px;
    padding-right: 14px;
    padding-bottom: 20px;
  }
  .vector-toc-pinned #vector-toc-pinned-container .vector-toc:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
    background-repeat: no-repeat;
    background-position: -12px;
    pointer-events: none;
  }
}
@media screen and (max-width: 999px) {
  .client-js #vector-toc-pinned-container .vector-toc {
    display: none;
  }
  .client-js .vector-toc .vector-pinnable-header-toggle-button {
    display: none;
  }
  .client-js .vector-page-titlebar-toc {
    display: block;
  }
  .client-js .vector-page-titlebar-toc > .vector-menu-content {
    max-width: none;
  }
  .client-js .vector-page-titlebar-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min( 0.85 * 60em, 75vw );
  }
  .client-js .vector-below-page-title .vector-page-titlebar-toc {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background-color: #ffffff;
    z-index: 3;
  }
}
@media screen and (min-width: 1000px) {
  .client-js .vector-toc-unpinned:not( .vector-sticky-header-visible ) .vector-page-titlebar-toc {
    display: block;
  }
  .client-js .vector-toc-unpinned:not( .vector-sticky-header-visible ) .vector-page-titlebar-toc > .vector-menu-content {
    max-width: none;
  }
  .client-js .vector-toc-unpinned:not( .vector-sticky-header-visible ) .vector-page-titlebar-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min( 0.85 * 60em, 75vw );
  }
  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc {
    display: block;
  }
  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc > .vector-menu-content {
    max-width: none;
  }
  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min( 0.85 * 60em, 75vw );
  }
}
@media screen and (max-width: 999px) {
  .client-nojs #vector-toc-collapsed-button {
    display: block;
    padding: 7px 12px;
  }
  .client-nojs #vector-toc-collapsed-button:hover,
  .client-nojs #vector-toc-collapsed-button:active {
    background-color: #f8f9fa;
  }
  .client-nojs #vector-toc-pinned-container {
    position: relative;
    display: block;
  }
  .client-nojs #vector-toc-pinned-container > .vector-menu-content {
    max-width: none;
  }
  .client-nojs #vector-toc-pinned-container .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min( 0.85 * 60em, 75vw );
  }
  .client-nojs .vector-toc {
    display: none;
    position: absolute;
    top: 36px;
    left: -12px;
    margin-top: 0.5em;
    border: 1px solid #a2a9b1;
    z-index: 3;
  }
  .client-nojs .vector-feature-page-tools-enabled .vector-toc {
    padding-left: 14px;
  }
  .client-nojs #vector-toc-collapsed-checkbox:checked ~ .mw-table-of-contents-container .vector-toc {
    display: block;
  }
}
@media all {
  .mw-logo {
    display: flex;
    height: 100%;
    align-items: center;
    min-width: 13.875em;
  }
  .vector-feature-page-tools-disabled .mw-logo {
    margin-left: 24px;
  }
  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .mw-logo {
    margin-left: 20px;
  }
  .mw-logo-icon {
    float: left;
    margin-right: 10px;
    display: none;
    width: 3.125em;
    height: 3.125em;
  }
  .mw-logo-container {
    float: left;
    max-width: 120px;
  }
  .mw-logo-container img {
    width: 100%;
  }
  .mw-logo-wordmark {
    display: block;
    margin: 0 auto;
  }
  .mw-logo-tagline {
    display: block;
    margin: 5px auto 0;
  }
}
@media all and (min-width: 720px) {
  .mw-logo-icon {
    display: block;
  }
}
@media all and (min-width: 720px) {
  .mw-logo-container {
    max-width: none;
  }
  .mw-logo-container img {
    width: auto;
  }
}
@media print {
  /* stylelint-disable no-invalid-double-slash-comments */
  .vector-page-toolbar,
  #mw-sidebar-checkbox,
  .vector-header-start > *:not( .mw-logo ),
  .vector-header-end,
  #mw-panel-toc,
  #vector-sticky-header,
  #p-lang-btn,
  .vector-menu-checkbox,
  nav,
  #vector-page-titlebar-toc,
  #footer {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
.mw-table-of-contents-container {
  display: none;
}
@supports ( display: grid ) {
  .mw-table-of-contents-container {
    display: block;
  }
}@media screen {
	/**
	 * MediaWiki style sheet for styles relating to thumbnails.
	 * For legacy reasons this also contains the floatleft, tleft, tright and floatright classes.
	 *
	 * CSS that does not relate to styling thumbnails generated by the parser in a vanilla
	 * MediaWiki install do not belong here.
	 */
	/* stylelint-disable selector-class-pattern */
	/* Thumbnails */
	div.thumbinner {
	  border: 1px solid #c8ccd1;
	  background-color: #f8f9fa;
	  font-size: 94%;
	}
	.thumbimage {
	  background-color: #ffffff;
	  border: 1px solid #c8ccd1;
	}
	.thumbcaption {
	  /* Default styles when there's no .mw-content-ltr or .mw-content-rtl, overridden below */
	  border: 0;
	  font-size: 94%;
	}
	.thumbborder {
	  border: 1px solid #eaecf0;
	}
	.magnify {
	  /* Default styles when there's no .mw-content-ltr or .mw-content-rtl, overridden below */
	  float: right;
	  margin-left: 3px;
	}
	.magnify a {
	  display: block;
	  /* Hide the text… */
	  text-indent: 15px;
	  white-space: nowrap;
	  overflow: hidden;
	  /* …and replace it with the image */
	  width: 15px;
	  height: 11px;
	  /* Default styles when there's no .mw-content-ltr or .mw-content-rtl, overridden below */
	  background-image: url(https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
	  /* Don't annoy people who copy-paste everything too much */
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}
	/* Directionality-specific styles for thumbnails - their positioning depends on content language */
	/* @noflip */
	.mw-content-ltr .thumbcaption {
	  text-align: left;
	}
	/* @noflip */
	.mw-content-rtl .thumbcaption {
	  text-align: right;
	}
	/* @noflip */
	.mw-content-ltr .magnify {
	  margin-left: 3px;
	  margin-right: 0;
	  float: right;
	}
	/* @noflip */
	.mw-content-ltr .magnify a {
	  background-image: url(https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
	}
	/* @noflip */
	.mw-content-rtl .magnify {
	  margin-left: 0;
	  margin-right: 3px;
	  float: left;
	}
	/* @noflip */
	.mw-content-rtl .magnify a {
	  background-image: url(https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-rtl.svg?38fd5);
	}
	
	/**
	 * ###IMPORTANT:##
	 * If editing this file, please also edit the respective file in the MinervaNeue skin.
	 * ####
	 *
	 * This file is a LESS @import match for "mediawiki.skin.variables.less"
	 * when Vector 2022 is the active skin.
	 * Please note, that legacy Vector is handled by
	 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
	 *
	 * Please also note, that both current Wikimedia Design Style Guide based skins,
	 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
	 * repositories.
	 */
	/* stylelint-disable selector-class-pattern */
	a.new {
	  color: #dd3333;
	}
	/* self links */
	a.mw-selflink {
	  color: inherit;
	  font-weight: bold;
	  text-decoration: inherit;
	}
	a.mw-selflink:hover {
	  cursor: inherit;
	  text-decoration: inherit;
	}
	a.mw-selflink:active,
	a.mw-selflink:visited {
	  color: inherit;
	}
	a.new:visited {
	  color: #a55858;
	}
	/* Interwiki & External links */
	.mw-parser-output a.extiw,
	.mw-parser-output a.external {
	  color: #3366cc;
	}
	.mw-parser-output a.extiw:visited,
	.mw-parser-output a.external:visited {
	  color: #795cb2;
	}
	.mw-parser-output a.extiw:active,
	.mw-parser-output a.external:active {
	  color: #faa700;
	}
	/* Underline preference */
	.mw-underline-always a {
	  text-decoration: underline;
	}
	.mw-underline-never a {
	  text-decoration: none;
	}
	/* Plainlinks - this can be used to switch
	 * off special external link styling */
	.plainlinks a.external {
	  background: none !important;
	  /* stylelint-disable-line declaration-no-important */
	  padding: 0 !important;
	  /* stylelint-disable-line declaration-no-important */
	}
	
	/* stylelint-disable selector-class-pattern */
	/**
	 * NOTE: This feature is enabled for all skins. Please read the instructions below before adding anything
	 * new to this file.
	 *
	 * This feature provides various styles associated with the body content of an article.
	 * The article body is considered to be anything that can be generated by OutputPage::getHTML()
	 * that is (or could be considered) universal to all pages in the main namespace.
	 *
	 * It is expected that this HTML is wrapped by SkinTemplate::wrapHTML and that the wrapping
	 * element makes use of the `mw-body-content` class.
	 *
	 * All styles here should be be scoped to the `.mw-body-content` or one of its child class
	 * e.g. `mw-parser-output class` where more appropriate.
	 *
	 * Styles here should be limited to CSS classes generated by PHP code inside MediaWiki core.
	 * Classes added that require an on-wiki template in the Template space and cannot be reproduced
	 * with a vanilla MediaWiki install are not allowed here. Please use MediaWiki:Common.css and
	 * MediaWiki:<skin>.css for such styles.
	 */
	/* stylelint-disable selector-class-pattern */
	/* stylelint-disable selector-no-vendor-prefix */
	/* stylelint-enable selector-no-vendor-prefix */
	/**
	 * It's possible to add HTML elements inside wikitext, for example <div style="float:right;"></div>
	 * Editors have grown to expect that any floated elements added inside wikitext will be cleared
	 * automatically by the skin. This rule encapsulates that user expectation in a central place that
	 * applies to all skins.
	 * Note, this applies to mw-body-content not mw-parser-output as on pages such as the category page,
	 * the body of a page includes other sibling elements aside from the output of the parser.
	 */
	.mw-body-content:after {
	  clear: both;
	  content: '';
	  display: block;
	}
	.mw-body-content a.external.free {
	  word-wrap: break-word;
	}
	.mw-body-content .error {
	  font-size: larger;
	  color: #d33;
	}
	/* External URLs should always be treated as LTR (T6330) */
	/* @noflip */
	.rtl .mw-parser-output a.external.free,
	.rtl .mw-parser-output a.external.autonumber {
	  direction: ltr;
	  unicode-bidi: embed;
	}
	/* body */
	.mw-hide-empty-elt .mw-parser-output:not( .mw-show-empty-elt ) .mw-empty-elt {
	  display: none;
	}
	
	/**
	 * wikitable class for skinning normal tables.
	 * Keep in sync with content.tables-print.less.
	 */
	.wikitable {
	  background-color: #f8f9fa;
	  color: #202122;
	  margin: 1em 0;
	  border: 1px solid #a2a9b1;
	  border-collapse: collapse;
	}
	.wikitable > tr > th,
	.wikitable > tr > td,
	.wikitable > * > tr > th,
	.wikitable > * > tr > td {
	  border: 1px solid #a2a9b1;
	  padding: 0.2em 0.4em;
	}
	.wikitable > tr > th,
	.wikitable > * > tr > th {
	  background-color: #eaecf0;
	  text-align: center;
	}
	.wikitable > caption {
	  font-weight: bold;
	}
	
	/* stylelint-disable selector-class-pattern */
	#catlinks {
	  /**
		 * Overrides text justification (user preference)
		 * See T33990
		 */
	  text-align: left;
	}
	.catlinks {
	  border: 1px solid #a2a9b1;
	  background-color: #f8f9fa;
	  padding: 5px;
	  margin-top: 1em;
	  clear: both;
	}
	.catlinks ul {
	  display: inline;
	  margin: 0;
	  padding: 0;
	  list-style: none;
	}
	.catlinks li {
	  display: inline-block;
	  line-height: 1.25em;
	  border-left: 1px solid #a2a9b1;
	  margin: 0.125em 0;
	  padding: 0 0.5em;
	  /* (T7346) make category redirects italic */
	}
	.catlinks li:first-child {
	  padding-left: 0.25em;
	  border-left: 0;
	}
	.catlinks li a.mw-redirect {
	  font-style: italic;
	}
	/**
	 * Hidden categories
	 */
	.mw-hidden-cats-hidden,
	.catlinks-allhidden {
	  display: none;
	}
	
	/**
	 * MediaWiki style sheet for common core styles on interfaces
	 *
	 * Styles for the Monobook/Vector pattern of laying out common interfaces.
	 * These ids/classes are not built into the system,
	 * they are outputted by the actual MonoBook/Vector code by convention.
	 */
	/* stylelint-disable selector-class-pattern */
	/**
	 * Hide empty portlets. Controlled by mw.util.(show|hide)Portlet.
	 *
	 * Note: Historically this class was provided by the skins themselves but in
	 * I2ba68122fd82a254a5ad0e45157f095508f6fa39 was moved into core to formalize
	 * the behaviour of hidden portlets.
	 */
	.emptyPortlet {
	  display: none;
	}
	.printfooter,
	.client-nojs #t-print {
	  display: none;
	  /* T167956 */
	}
	
	.mw-editsection {
	  -moz-user-select: none;
	  -webkit-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}
	/* Display editsection links smaller and next to headings */
	.mw-editsection,
	.mw-editsection-like {
	  font-size: small;
	  font-weight: normal;
	  margin-left: 1em;
	  vertical-align: baseline;
	  /* Avoid affecting the height of the heading */
	  line-height: 0;
	}
	/* Correct directionality when page dir is different from site/user dir */
	.mw-content-ltr .mw-editsection,
	.mw-content-rtl .mw-content-ltr .mw-editsection,
	.mw-content-ltr .mw-editsection-like,
	.mw-content-rtl .mw-content-ltr .mw-editsection-like {
	  /* @noflip */
	  margin-left: 1em;
	  /* @noflip */
	  margin-right: 0;
	}
	.mw-content-rtl .mw-editsection,
	.mw-content-ltr .mw-content-rtl .mw-editsection,
	.mw-content-rtl .mw-editsection-like,
	.mw-content-ltr .mw-content-rtl .mw-editsection-like {
	  /* @noflip */
	  margin-right: 1em;
	  /* @noflip */
	  margin-left: 0;
	}
	
	/* stylelint-disable-next-line selector-class-pattern */
	.usermessage {
	  background-color: #fef6e7;
	  border: 1px solid #fc3;
	  color: #000;
	  font-weight: bold;
	  margin: 2em 0 1em;
	  padding: 0.5em 1em;
	  vertical-align: middle;
	}
	
	/**
	 * MediaWiki style sheet for general styles on basic content elements
	 * IMPORTANT: All rules in this file should only apply to __tag__ selectors (T255717).
	 * Styling of non-tag selectors is permitted for backwards compatibility, in only two cases:
	 * 1) There is an equivalent tag with the same name in HTML4 (e.g. `.small and <small>)
	 * 2) A class matches a tag name and is prefixed with `mw-` e.g. `code` and `.mw-code`
	 *
	 * Styles for basic elements: links, lists, etc...
	 *
	 * This style sheet is used by the Monobook and Vector skins.
	 */
	/**
	 * ###IMPORTANT:##
	 * If editing this file, please also edit the respective file in the MinervaNeue skin.
	 * ####
	 *
	 * This file is a LESS @import match for "mediawiki.skin.variables.less"
	 * when Vector 2022 is the active skin.
	 * Please note, that legacy Vector is handled by
	 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
	 *
	 * Please also note, that both current Wikimedia Design Style Guide based skins,
	 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
	 * repositories.
	 */
	/* stylelint-disable selector-class-pattern */
	/* Links */
	a {
	  text-decoration: none;
	  color: #3366cc;
	  background: none;
	}
	a:not( [ href ] ) {
	  cursor: pointer;
	  /* Always cursor:pointer even without href */
	}
	a:visited {
	  color: #795cb2;
	}
	a:active {
	  color: #faa700;
	}
	a:hover,
	a:focus {
	  text-decoration: underline;
	}
	a:lang( ar ),
	a:lang( kk-arab ),
	a:lang( mzn ),
	a:lang( ps ),
	a:lang( ur ) {
	  text-decoration: none;
	}
	/* Inline Elements */
	img {
	  border: 0;
	  /* FIXME: Remove after adding 'normalize.less' added to all 'elements.less' calling skins. */
	  vertical-align: middle;
	}
	hr {
	  height: 1px;
	  background-color: #a2a9b1;
	  border: 0;
	  margin: 0.2em 0;
	}
	/* Structural Elements */
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	  color: #000;
	  margin: 0;
	  padding-top: 0.5em;
	  padding-bottom: 0.17em;
	  overflow: hidden;
	}
	h1,
	h2 {
	  margin-bottom: 0.6em;
	  border-bottom: 1px solid #a2a9b1;
	}
	h3,
	h4,
	h5 {
	  margin-bottom: 0.3em;
	}
	h1 {
	  font-size: 188%;
	  font-weight: normal;
	}
	h2 {
	  font-size: 150%;
	  font-weight: normal;
	}
	h3 {
	  font-size: 128%;
	}
	h4 {
	  font-size: 116%;
	}
	h5 {
	  font-size: 108%;
	}
	h6 {
	  font-size: 100%;
	}
	p {
	  margin: 0.4em 0 0.5em 0;
	}
	p img {
	  margin: 0;
	}
	ul {
	  margin: 0.3em 0 0 1.6em;
	  padding: 0;
	}
	ol {
	  margin: 0.3em 0 0 3.2em;
	  padding: 0;
	  list-style-image: none;
	}
	li {
	  margin-bottom: 0.1em;
	}
	dt {
	  font-weight: bold;
	  margin-bottom: 0.1em;
	}
	dl {
	  margin-top: 0.2em;
	  margin-bottom: 0.5em;
	}
	dd {
	  margin-left: 1.6em;
	  margin-bottom: 0.1em;
	}
	/* FIXME: Duplicated ruleset if skins using ResourceLoaderSkinModule
	load the features `normalize` and `elements`. */
	pre,
	code,
	tt,
	kbd,
	samp,
	.mw-code {
	  /* Support: Blink, Gecko, Webkit; enable unified font sizes for monospace font. T176636 */
	  font-family: monospace, monospace;
	}
	pre,
	code,
	.mw-code {
	  background-color: #f8f9fa;
	  color: #000;
	  border: 1px solid #eaecf0;
	}
	code {
	  border-radius: 2px;
	  padding: 1px 4px;
	}
	pre,
	.mw-code {
	  padding: 1em;
	  /* Wrap lines in overflow. T2260, T103780 */
	  white-space: pre-wrap;
	  /* Create a new block formatting context */
	  overflow-x: hidden;
	  /* Break really long words when they overflow to the next line */
	  word-wrap: break-word;
	}
	/* Tables */
	table {
	  font-size: 100%;
	}
	/* Forms */
	fieldset {
	  border: 1px solid #2a4b8d;
	  margin: 1em 0 1em 0;
	  padding: 0 1em 1em;
	}
	legend {
	  padding: 0.5em;
	}
	form {
	  border: 0;
	  margin: 0;
	}
	textarea {
	  display: block;
	  box-sizing: border-box;
	  width: 100%;
	  /* Support: Firefox */
	  /* Border rule required to override system appearance on Linux. T13641. */
	  border: 1px solid #c8ccd1;
	  padding: 0.1em;
	}
	/* Emulate Center */
	.center {
	  width: 100%;
	  text-align: center;
	}
	*.center * {
	  margin-left: auto;
	  margin-right: auto;
	}
	/* Small for tables and similar */
	.small {
	  font-size: 94%;
	}
	table.small {
	  font-size: 100%;
	}
	
	/* Taken from https://www.w3.org/TR/predefined-counter-styles/#meetei-styles */
	@counter-style meetei {
		system: numeric;
		symbols: '\ABF0' '\ABF1' '\ABF2' '\ABF3' '\ABF4' '\ABF5' '\ABF6' '\ABF7' '\ABF8' '\ABF9';
		suffix: ') ';
	}
	
	/* Taken from https://www.w3.org/TR/predefined-counter-styles/#ol-chiki-styles */
	@counter-style santali {
		system: numeric;
		symbols: '\1C50' '\1C51' '\1C52' '\1C53' '\1C54' '\1C55' '\1C56' '\1C57' '\1C58' '\1C59';
	}
	
	/* Taken from https://www.w3.org/TR/predefined-counter-styles/#myanmar-styles */
	@counter-style myanmar_with_period {
		system: numeric;
		symbols: '\1040' '\1041' '\1042' '\1043' '\1044' '\1045' '\1046' '\1047' '\1048' '\1049';
		suffix: '\104B\0020';
	}
	
	/* Localised ordered list numbering for some languages */
	ol:lang( azb ) li,
	ol:lang( bcc ) li,
	ol:lang( bgn ) li,
	ol:lang( bqi ) li,
	ol:lang( fa ) li,
	ol:lang( glk ) li,
	ol:lang( kk-arab ) li,
	ol:lang( lrc ) li,
	ol:lang( luz ) li,
	ol:lang( mzn ) li {
	  list-style-type: persian;
	}
	ol:lang( ckb ) li,
	ol:lang( sdh ) li {
	  list-style-type: arabic-indic;
	}
	ol:lang( hi ) li,
	ol:lang( mai ) li,
	ol:lang( mr ) li,
	ol:lang( ne ) li {
	  list-style-type: devanagari;
	}
	ol:lang( as ) li,
	ol:lang( bn ) li {
	  list-style-type: bengali;
	}
	ol:lang( mni ) li {
	  list-style-type: meetei;
	}
	ol:lang( or ) li {
	  list-style-type: oriya;
	}
	ol:lang( sat ) li {
	  list-style-type: santali;
	}
	ol:lang( blk ) li,
	ol:lang( kjp ) li,
	ol:lang( ksw ) li,
	ol:lang( mnw ) li,
	ol:lang( my ) li,
	ol:lang( shn ) li {
	  list-style-type: myanmar_with_period;
	}
	
	/* Correct directionality when page dir is different from site/user dir */
	.mw-content-ltr ul,
	.mw-content-rtl .mw-content-ltr ul {
	  /* @noflip */
	  margin: 0.3em 0 0 1.6em;
	  padding: 0;
	}
	.mw-content-rtl ul,
	.mw-content-ltr .mw-content-rtl ul {
	  /* @noflip */
	  margin: 0.3em 1.6em 0 0;
	  padding: 0;
	}
	.mw-content-ltr ol,
	.mw-content-rtl .mw-content-ltr ol {
	  /* @noflip */
	  margin: 0.3em 0 0 3.2em;
	  padding: 0;
	}
	.mw-content-rtl ol,
	.mw-content-ltr .mw-content-rtl ol {
	  /* @noflip */
	  margin: 0.3em 3.2em 0 0;
	  padding: 0;
	}
	/* @noflip */
	.mw-content-ltr dd,
	.mw-content-rtl .mw-content-ltr dd {
	  margin-left: 1.6em;
	  margin-right: 0;
	}
	/* @noflip */
	.mw-content-rtl dd,
	.mw-content-ltr .mw-content-rtl dd {
	  margin-right: 1.6em;
	  margin-left: 0;
	}
	
	/* Language specific height correction for titles. Ref T31405 and T32809 */
	/* Languages like hi or ml require slightly more vertical space to show diacritics properly */
	h1:lang( anp ),
	h1:lang( as ),
	h1:lang( bh ),
	h1:lang( bho ),
	h1:lang( blk ),
	h1:lang( bn ),
	h1:lang( gu ),
	h1:lang( hi ),
	h1:lang( kjp ),
	h1:lang( kn ),
	h1:lang( ks ),
	h1:lang( ksw ),
	h1:lang( mag ),
	h1:lang( ml ),
	h1:lang( mr ),
	h1:lang( my ),
	h1:lang( mai ),
	h1:lang( mnw ),
	h1:lang( ne ),
	h1:lang( new ),
	h1:lang( or ),
	h1:lang( pa ),
	h1:lang( pi ),
	h1:lang( rki ),
	h1:lang( sa ),
	h1:lang( shn ),
	h1:lang( syl ),
	h1:lang( ta ),
	h1:lang( te ) {
	  line-height: 1.6em !important;
	  /* stylelint-disable-line declaration-no-important */
	}
	h2:lang( anp ),
	h2:lang( as ),
	h2:lang( bh ),
	h2:lang( bho ),
	h2:lang( blk ),
	h2:lang( bn ),
	h2:lang( gu ),
	h2:lang( hi ),
	h2:lang( kjp ),
	h2:lang( kn ),
	h2:lang( ks ),
	h2:lang( ksw ),
	h2:lang( mag ),
	h2:lang( ml ),
	h2:lang( mr ),
	h2:lang( my ),
	h2:lang( mai ),
	h2:lang( mnw ),
	h2:lang( ne ),
	h2:lang( new ),
	h2:lang( or ),
	h2:lang( pa ),
	h2:lang( pi ),
	h2:lang( rki ),
	h2:lang( sa ),
	h2:lang( shn ),
	h2:lang( syl ),
	h2:lang( ta ),
	h2:lang( te ),
	h3:lang( anp ),
	h3:lang( as ),
	h3:lang( bh ),
	h3:lang( bho ),
	h3:lang( blk ),
	h3:lang( bn ),
	h3:lang( gu ),
	h3:lang( hi ),
	h3:lang( kjp ),
	h3:lang( kn ),
	h3:lang( ks ),
	h3:lang( ksw ),
	h3:lang( mag ),
	h3:lang( ml ),
	h3:lang( mr ),
	h3:lang( my ),
	h3:lang( mai ),
	h3:lang( mnw ),
	h3:lang( ne ),
	h3:lang( new ),
	h3:lang( or ),
	h3:lang( pa ),
	h3:lang( pi ),
	h3:lang( rki ),
	h3:lang( sa ),
	h3:lang( shn ),
	h3:lang( syl ),
	h3:lang( ta ),
	h3:lang( te ),
	h4:lang( anp ),
	h4:lang( as ),
	h4:lang( bh ),
	h4:lang( bho ),
	h4:lang( blk ),
	h4:lang( bn ),
	h4:lang( gu ),
	h4:lang( hi ),
	h4:lang( kjp ),
	h4:lang( kn ),
	h4:lang( ks ),
	h4:lang( ksw ),
	h4:lang( mag ),
	h4:lang( ml ),
	h4:lang( mr ),
	h4:lang( my ),
	h4:lang( mai ),
	h4:lang( mnw ),
	h4:lang( ne ),
	h4:lang( new ),
	h4:lang( or ),
	h4:lang( pa ),
	h4:lang( pi ),
	h4:lang( rki ),
	h4:lang( sa ),
	h4:lang( shn ),
	h4:lang( syl ),
	h4:lang( ta ),
	h4:lang( te ),
	h5:lang( anp ),
	h5:lang( as ),
	h5:lang( bh ),
	h5:lang( bho ),
	h5:lang( blk ),
	h5:lang( bn ),
	h5:lang( gu ),
	h5:lang( hi ),
	h5:lang( kjp ),
	h5:lang( kn ),
	h5:lang( ks ),
	h5:lang( ksw ),
	h5:lang( mag ),
	h5:lang( ml ),
	h5:lang( mr ),
	h5:lang( my ),
	h5:lang( mai ),
	h5:lang( mnw ),
	h5:lang( ne ),
	h5:lang( new ),
	h5:lang( or ),
	h5:lang( pa ),
	h5:lang( pi ),
	h5:lang( rki ),
	h5:lang( sa ),
	h5:lang( shn ),
	h5:lang( syl ),
	h5:lang( ta ),
	h5:lang( te ),
	h6:lang( anp ),
	h6:lang( as ),
	h6:lang( bh ),
	h6:lang( bho ),
	h6:lang( blk ),
	h6:lang( bn ),
	h6:lang( gu ),
	h6:lang( hi ),
	h6:lang( kjp ),
	h6:lang( kn ),
	h6:lang( ks ),
	h6:lang( ksw ),
	h6:lang( mag ),
	h6:lang( ml ),
	h6:lang( mr ),
	h6:lang( my ),
	h6:lang( mai ),
	h6:lang( mnw ),
	h6:lang( ne ),
	h6:lang( new ),
	h6:lang( or ),
	h6:lang( pa ),
	h6:lang( pi ),
	h6:lang( rki ),
	h6:lang( sa ),
	h6:lang( shn ),
	h6:lang( syl ),
	h6:lang( ta ),
	h6:lang( te ) {
	  line-height: 1.4em;
	}
	h2:lang( th ) {
	  line-height: 1.6;
	}}@media print {
	/* stylelint-disable selector-class-pattern */
	/* @noflip */
	table.floatright,
	div.floatright {
	  border: 0;
	}
	/* @noflip */
	table.floatleft,
	div.floatleft {
	  border: 0;
	}
	div.thumb {
	  /* Pagination */
	  page-break-inside: avoid;
	}
	div.thumb a {
	  border-bottom: 0;
	}
	div.thumbinner {
	  border: 1px;
	  background-color: #ffffff;
	  font-size: 10pt;
	  color: #666666;
	  border-radius: 2px;
	  min-width: 100px;
	}
	.thumbborder {
	  border: 1pt solid #dddddd;
	}
	.magnify {
	  display: none;
	}
	
	/* stylelint-disable selector-class-pattern */
	.mw-parser-output a.external {
	  /* Expand URLs for printing */
	  /* Expand protocol-relative URLs for printing */
	}
	.mw-parser-output a.external.text:after,
	.mw-parser-output a.external.autonumber:after {
	  content: ' (' attr(href) ')';
	  word-break: break-all;
	  word-wrap: break-word;
	}
	.mw-parser-output a.external.text[href^='//']:after,
	.mw-parser-output a.external.autonumber[href^='//']:after {
	  content: ' (https:' attr(href) ')';
	}
	
	/* stylelint-disable selector-class-pattern */
	.wikitable {
	  background: #fff;
	  margin: 1em 0;
	  border: 1pt solid #aaa;
	  border-collapse: collapse;
	  font-size: 10pt;
	  /* Pagination */
	  page-break-inside: avoid;
	}
	.wikitable > caption {
	  padding: 5px;
	  font-size: 10pt;
	}
	.wikitable > tr > th,
	.wikitable > tr > td,
	.wikitable > * > tr > th,
	.wikitable > * > tr > td {
	  /* Important is required to override any inline styles provided by editors */
	  background: #fff !important;
	  /* stylelint-disable-line declaration-no-important */
	  /* We need to also set color in case editors applied a light text color */
	  color: #000 !important;
	  /* stylelint-disable-line declaration-no-important */
	  border: 1pt solid #aaa;
	  padding: 0.4em 0.6em;
	}
	.wikitable > tr > th,
	.wikitable > * > tr > th {
	  text-align: center;
	}
	table.listing,
	table.listing td {
	  border: 1pt solid #000;
	  border-collapse: collapse;
	}
	
	/* stylelint-disable selector-class-pattern */
	.catlinks ul {
	  display: inline;
	  padding: 0;
	  list-style: none;
	}
	.catlinks li {
	  display: inline-block;
	  line-height: 1.15;
	  margin: 0.1em 0;
	  border-left: 1pt solid #aaa;
	  padding: 0 0.4em;
	}
	.catlinks li:first-child {
	  border-left: 0;
	  padding-left: 0.2em;
	}
	.mw-hidden-catlinks,
	.catlinks {
	  display: none;
	}
	
	/* stylelint-disable selector-class-pattern */
	.mw-editsection,
	.mw-editsection-like,
	.mw-indicators,
	#siteNotice,
	.usermessage {
	  display: none;
	}
	.printfooter {
	  padding: 1em 0;
	}
	
	/* stylelint-disable selector-class-pattern */
	.center {
	  text-align: center;
	}
	/* Links */
	a {
	  background: none !important;
	  /* stylelint-disable-line declaration-no-important */
	  padding: 0 !important;
	  /* stylelint-disable-line declaration-no-important */
	}
	a,
	a.external,
	a.new,
	a.stub {
	  color: inherit !important;
	  /* stylelint-disable-line declaration-no-important */
	  text-decoration: inherit !important;
	  /* stylelint-disable-line declaration-no-important */
	}
	dt {
	  font-weight: bold;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	  font-weight: bold;
	  /* Pagination */
	  page-break-after: avoid;
	  page-break-before: avoid;
	}
	p {
	  margin: 1em 0;
	  line-height: 1.2;
	  /* Pagination */
	  orphans: 3;
	  widows: 3;
	}
	img,
	figure {
	  /* Pagination */
	  page-break-inside: avoid;
	}
	img {
	  border: 0;
	  vertical-align: middle;
	}
	pre,
	.mw-code {
	  background: #fff;
	  color: #000;
	  border: 1pt dashed #000;
	  padding: 1em;
	  font-size: 8pt;
	  white-space: pre-wrap;
	  /* Create a new block formatting context */
	  overflow-x: hidden;
	  /* Break really long words when they overflow to the next line */
	  word-wrap: break-word;
	}
	ul {
	  list-style-type: square;
	}}
.mw-ui-icon-wikimedia-labFlask:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-labFlask-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-language:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-language-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-sandbox:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-sandbox-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-listBullet:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-listBullet-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-ellipsis:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-ellipsis-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-heart:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-heart-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-logIn:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-logIn-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-logOut:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-logOut-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-search:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-search-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-settings:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-settings-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-menu:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-menu-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-imageGallery:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-imageGallery-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-unStar:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-unStar-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-expand:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-expand-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAdd:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAdd-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAnonymous:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAnonymous-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAvatar:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userAvatar-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&variant=progressive&format=rasterized&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&variant=progressive&format=original&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userContributions:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userContributions-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userGroup:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userGroup-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userTalk:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-userTalk-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-watchlist:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&format=original&lang=en&skin=vector-2022&version=ztntf);
}
.mw-ui-icon-wikimedia-watchlist-progressive:before {
	background-image: url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&variant=progressive&format=rasterized&lang=en&skin=vector-2022&version=ztntf);
	background-image: linear-gradient(transparent, transparent), url(https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf);
}
/**
* MediaWiki UI icons specification 2.0
*
* Applies the MinervaNeue icon styles, adding more mobile-friendly
* features like increased touch-area sizes and highlights.
*
* - mw-ui-icon = base class. 20px square with icon.
* - mw-ui-icon + mw-ui-icon-element = icon with padding & pseudo-states.
* - mw-ui-icon + mw-ui-icon-small = small icon with padding & pseudo-states.
* - mw-ui-icon + mw-ui-icon-flush-left = negative right-margin.
* - mw-ui-icon + mw-ui-icon-flush-right = negative left-margin.
* - mw-ui-icon + mw-ui-icon-before = *deprecated*, icon with text.
*   Labels should be placed in a separate element beside the icon.
*
*/
/**
 * ###IMPORTANT:##
 * If editing this file, please also edit the respective file in the MinervaNeue skin.
 * ####
 *
 * This file is a LESS @import match for "mediawiki.skin.variables.less"
 * when Vector 2022 is the active skin.
 * Please note, that legacy Vector is handled by
 * "mediwiki.less.legacy/mediawiki.skin.variables.less".
 *
 * Please also note, that both current Wikimedia Design Style Guide based skins,
 * Vector 2022 and Minerva Neue are using the same variable values respectively in their
 * repositories.
 */
/**
 * Deprecated variables
 */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-enable selector-no-vendor-prefix */
/**
* Mixin for a pseudo-element with a background image.
*/
/**
* A standalone 20px square with screen-reader text. Contains a :before element
* to house the icon as a background image. Total size is increased with padding via
* `box-sizing: content-box`. This separates control over size of icon vs the size of
* the touch-area.
*/
.mw-ui-icon {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  font-size: initial;
  position: relative;
  display: inline-block;
  box-sizing: content-box !important;
  width: 1.25em;
  height: 1.25em;
  min-width: 1.25em;
  min-height: 1.25em;
  flex-basis: 1.25em;
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -moz-appearance: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}
.mw-ui-icon:not( .mw-ui-button ) {
  border: 0;
}
.mw-ui-icon:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  min-width: 1.25em;
  min-height: 1.25em;
  background-repeat: no-repeat;
  background-size: 1.25em 1.25em;
  background-position: center;
}
.mw-ui-icon + span:not( :empty ) {
  margin-left: 6px;
}
/**
* When aligning a series of similar icons (e.g. list items)
* prefer using a `:first-child` or `:last-child` selector.
* The flush-left/right classes are appropriate when that's unavailable.
*/
.mw-ui-icon-flush-top {
  margin-top: -0.75em;
}
.mw-ui-icon-flush-left {
  margin-left: -0.75em;
}
@media all and (min-width: 1000px) {
  .mw-ui-icon-flush-left {
    margin-left: -0.375em;
  }
}
.mw-ui-icon-flush-right {
  margin-right: -0.75em;
}
@media all and (min-width: 1000px) {
  .mw-ui-icon-flush-right {
    margin-right: -0.375em;
  }
}
.mw-ui-icon-element {
  border-radius: 2px;
  padding: 0.75em;
  transition: background-color 100ms;
  color: transparent;
}
.mw-ui-icon-element.mw-ui-button {
  padding: 0.6875em;
}
.mw-ui-icon-element:focus,
.mw-ui-icon-element:active,
.mw-ui-icon-element:visited {
  color: transparent;
}
.mw-ui-icon-element:active {
  background-color: rgba(0, 24, 73, 0.02745098);
}
@media (min-width: 1000px) {
  .mw-ui-icon-element.mw-ui-button {
    padding: 0.3125em;
  }
}
@media (hover: hover) {
  .mw-ui-icon-element:not( .disabled ):hover {
    background-color: rgba(0, 24, 73, 0.02745098);
  }
}
.mw-ui-icon-small {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  flex-basis: 1em;
  line-height: 1em;
}
.mw-ui-icon-small:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  min-width: 1em;
  min-height: 1em;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  background-position: center;
}
.mw-ui-icon-small.mw-ui-icon-element {
  padding: 0.4375em;
}
.mw-ui-icon-small.mw-ui-icon-flush-left {
  margin-left: -0.4375em;
}
.mw-ui-icon-small.mw-ui-icon-flush-right {
  margin-right: -0.4375em;
}
.mw-ui-icon-small.mw-ui-icon-before:before {
  min-width: 1em;
  min-height: 1em;
  margin-right: 0.4375em;
}
.mw-ui-icon-before {
  width: auto;
  max-width: 100%;
}
.mw-ui-icon-before:before {
  display: inline-block;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  font-size: initial;
  width: auto;
  min-width: 1.25em;
  min-height: 1.25em;
  margin-right: 6px;
  vertical-align: middle;
}
.mw-ui-icon-before span {
  vertical-align: middle;
}
@media all and (min-width: 1000px) {
  .mw-ui-icon-with-label-desktop {
    color: #54595d !important;
    width: auto;
    line-height: inherit;
    flex-basis: auto;
    font-weight: 500 !important;
  }
  .mw-ui-icon-with-label-desktop:hover,
  .mw-ui-icon-with-label-desktop:focus,
  .mw-ui-icon-with-label-desktop:active,
  .mw-ui-icon-with-label-desktop:visited {
    color: #54595d;
    text-decoration: none;
  }
  .mw-ui-icon-with-label-desktop:before {
    width: auto;
    display: inline-block;
    margin-right: 6px;
    vertical-align: text-bottom;
  }
}
