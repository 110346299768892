:root {
  --color-gold: #e4c600;
  --color-red: #ff6745;
  --color-green: #69d144;
  --color-wikiwhite: #f8f9fa;
  --color-lightgrey: #e6ebeb;
  --color-grey: #bebfbe;
  --color-darkgrey: #5a5a5a;
  --width-left: 320px;
  --width-side: 500px;
}

html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
  height: unset !important;
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

.container {
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  display: flex;
  overflow-x: hidden;
}

.side {
  text-align: right;
  height: 100vh;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: center;
  display: flex;
  position: sticky;
  top: 0;
}

.side-content {
  width: var(--width-left);
  border-right: 1px solid var(--color-grey);
  border-color: var(--color-grey);
  flex-direction: column;
  align-items: flex-end;
  padding: 36px 36px 36px 0;
  display: flex;
}

.nice-container {
  text-align: left;
  border: 1px solid;
  border-color: var(--color-grey);
  border-radius: 4px;
  margin-top: 9px;
  padding: 10px 12px 6px 18px;
  font-size: 14px;
  transition: height .5s, padding .5s, margin .5s, opacity .5s;
}

.nice-container:before {
  content: attr(label);
  background-color: var(--color-wikiwhite);
  color: var(--color-grey);
  margin: 0 -12px -16px 0;
  padding: 0 4px;
  display: table;
  position: relative;
  top: -19px;
  left: -15px;
}

.nice-container:not(:has( > * > :not(.exit))) {
  opacity: 0;
  pointer-events: none;
  margin-top: -2px;
  padding-top: 0;
  padding-bottom: 0;
}

.vertical-group-enter {
  opacity: 0;
  margin: 0;
}

.vertical-group-enter-active {
  opacity: 1;
  margin: 6px 0;
  transition: opacity .5s, margin .5s;
}

.vertical-group-enter-done, .vertical-group-exit {
  opacity: 1;
  margin: 6px 0;
}

.vertical-group {
  flex-direction: column;
  align-items: end;
  display: flex;
}

.vertical-group-exit-active {
  opacity: 0;
  margin: 0;
  transition: opacity .5s, margin .5s;
}

.vertical-group-exit-done {
  opacity: 0;
  margin: 0;
}

.settings {
  height: 100vh;
  opacity: 0;
  width: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
}

.settings.enter {
  opacity: 0;
  width: 0;
}

.settings.enter-active {
  opacity: 1;
  width: 490px;
  transition: width .5s, opacity .5s;
}

.settings.enter-done, .settings.exit {
  opacity: 1;
  width: 490px;
}

.settings.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
}

.settings.exit-done {
  opacity: 0;
  width: 0;
  visibility: hidden;
}

.settings-align {
  flex-direction: column;
  align-items: end;
  margin-left: 32px;
  display: flex;
}

.settings-counter-align {
  flex-direction: column;
  align-items: start;
  display: flex;
}

.settings-content {
  width: 458px;
  gap: 8px;
  display: flex;
}

.settings-content > * {
  flex-direction: column;
  gap: 6px;
  display: flex;
}

.settings-content.no-edit input {
  cursor: not-allowed;
}

button.start-run {
  height: 32px;
  width: unset;
  border-color: var(--color-green);
  border-radius: 16px;
  align-items: center;
  gap: 6px;
  padding: 0 16px 0 3px;
  transition: color .5s, border-color .5s;
  display: flex;
}

button.start-run:disabled {
  border-color: var(--color-grey);
}

button.start-run > .mini-icon {
  filter: saturate();
  transition: filter .5s;
}

button.start-run:disabled > .mini-icon {
  filter: saturate(1%);
}

.wiki-run {
  opacity: 0;
  width: 0;
  overflow-x: visible;
}

.wiki-run.enter {
  opacity: 0;
  width: 0;
}

.wiki-run.enter-active {
  opacity: 1;
  width: 60vw;
  transition: width .5s, opacity .5s;
}

.wiki-run.enter-done {
  opacity: 1;
  width: 60vw;
}

.wiki-run.exit {
  opacity: 1;
  width: 60vw;
  height: 100vh;
}

.wiki-run.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
}

.wiki-run.exit-done {
  width: 0;
  opacity: 0;
  height: 100vh;
}

.wiki-run.exit > .wiki, .wiki-run.exit-done > .wiki {
  height: 100vh;
  overflow-y: hidden;
}

.wiki-run-align {
  flex-direction: column;
  align-items: start;
  margin-left: 32px;
  display: flex;
}

.wiki-run.exit > .wiki-run-align {
  align-items: start;
}

.wiki {
  width: 60vw;
  margin-left: 32px;
  overflow-x: visible;
}

.wiki.loading {
  opacity: .2;
}

.input-line {
  height: 32px;
  flex-direction: row;
  place-items: center flex-end;
  display: flex;
  overflow: hidden;
}

.input-line label {
  margin-right: 1em;
}

.input-line-enter {
  opacity: 0;
  height: 0;
}

.input-line-enter-active {
  opacity: 1;
  height: 32px;
  transition: height .5s, opacity .5s;
}

.input-line-exit {
  opacity: 1;
  height: 32px;
}

.input-line-exit-active {
  opacity: 0;
  height: 0;
  transition: height .5s, opacity .5s;
}

.input-line-exit-done {
  opacity: 0;
  height: 0;
}

.input-line input[type="text"], button {
  height: 20px;
  width: 140px;
  text-align: center;
  background-color: #0000;
  border: 0;
  margin-bottom: 11px;
  padding: 0;
}

.input-line input[type="text"] {
  caret-color: #0000;
  border-bottom: 1px solid #789;
}

.input-line input:focus {
  outline: none;
  animation: .6s infinite glow;
}

.input-line input::placeholder {
  opacity: 1;
  transition: opacity .5s;
}

.input-line input:focus::placeholder {
  opacity: .7;
}

button {
  border: 1px solid;
  border-color: var(--color-grey);
  color: var(--color-darkgrey);
  border-radius: 4px;
  padding: 2px 0;
  font-weight: 600;
}

button:disabled {
  color: var(--color-grey);
}

.input-line.pageselect input {
  width: 200px;
}

button:not(:disabled):hover {
  background-color: var(--color-lightgrey);
}

button:not(:disabled):active {
  background-color: var(--color-grey);
}

.button {
  height: 0;
  opacity: 0;
  border-width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.button.enter {
  height: 0;
  opacity: 0;
  border-width: 0;
  margin: 0;
}

.button.enter-active {
  height: 20px;
  opacity: 1;
  margin: 8px 0;
  transition: height .5s, margin .5s, opacity .5s;
}

.button.enter-done, .button.exit {
  height: 20px;
  opacity: 1;
  margin: 8px 0;
}

.button.exit-active {
  height: 0;
  opacity: 0;
  margin: 0;
  transition: height .5s, margin .5s, opacity .5s;
}

.button.exit-done {
  height: 0;
  opacity: 0;
  border-width: 0;
  margin: 0;
}

.setting-line {
  height: 18px;
  justify-content: space-between;
}

.setting-line.disabled label {
  color: var(--color-grey);
}

.party-members, .party-members.enter {
  margin: 0;
}

.party-members.enter-active {
  margin: 8px 0 26px;
  transition: margin .5s;
}

.party-members.enter-done, .party-members.exit {
  margin: 8px 0 26px;
}

.party-members.exit-active {
  margin: 0;
  transition: margin .5s;
}

.party-members.exit-done {
  margin: 0;
}

.party-members-content {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 4px;
  transition: height .5s;
  display: flex;
  overflow: hidden;
}

.party-member {
  background-color: var(--color-grey);
  vertical-align: middle;
  height: 20px;
  border-radius: 10px;
  padding: 0 10px;
  line-height: 20px;
  display: inline-block;
  overflow: hidden;
}

.party-member.leader {
  background-color: var(--color-green);
  color: #fff;
  padding-left: 6px;
}

.party-member.enter {
  opacity: 0;
  height: 0;
}

.party-member.enter-active {
  opacity: 1;
  height: 20px;
  transition: height .5s, opacity .5s, background-color .5s;
}

.party-member.enter-done, .party-member-exit {
  opacity: 1;
  height: 20px;
}

.party-member.exit-active {
  opacity: 0;
  height: 0;
  background-color: var(--color-lightgrey);
  transition: height .5s, opacity .5s, background-color .5s;
}

.party-member.exit-done {
  opacity: 0;
  height: 0;
}

.autocomplete {
  width: 200px;
  max-height: 0;
  text-align: left;
  z-index: 1000;
  background-color: #f0f0f0;
  flex-direction: column;
  align-items: stretch;
  transition: height .5s;
  display: flex;
  position: absolute;
  overflow: hidden;
  transform: translate(-100%, 4px);
}

.autocomplete-enter {
  max-height: 0;
}

.autocomplete-enter-active {
  max-height: 300px;
  transition: max-height .5s;
}

.autocomplete-enter-done, .autocomplete-exit {
  max-height: 300px;
}

.autocomplete-exit-active {
  max-height: 0;
  transition: max-height .5s;
}

.autocomplete-suggestion {
  white-space: nowrap;
}

.cross {
  width: 12px;
  height: 12px;
  opacity: .8;
  margin-left: -14px;
  padding-bottom: 6px;
  padding-right: 2px;
}

.opponents {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: sticky;
  top: 0;
  overflow: visible;
}

.opponents > .title {
  height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  overflow: hidden;
}

.opponents > .title.enter {
  height: 0;
  opacity: 0;
}

.opponents > .title.enter-active {
  height: 24px;
  opacity: 1;
  transition: height .5s, opacity .5s;
}

.opponents > .title.enter-done, .opponents > .title.exit {
  height: 24px;
  opacity: 1;
}

.opponents > .title.exit-active {
  height: 0;
  opacity: 0;
  transition: height .5s, opacity .5s;
}

.opponents > .title.exit-done {
  height: 0;
  opacity: 0;
}

.opponents-content {
  flex-direction: column;
  align-items: stretch;
}

.opponent {
  border-left: 1px solid var(--color-grey);
  height: 0;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 12px;
  display: flex;
  overflow: hidden;
}

.side .opponent {
  border-left: none;
  border-right: 1px solid var(--color-grey);
  padding: 0 12px 0 0;
}

.opponent.enter {
  height: 0;
  opacity: 0;
}

.opponent.enter-active {
  height: 28px;
  opacity: 1;
  transition: height .5s, opacity .5s;
}

.opponent.enter-done {
  height: 28px;
  opacity: 1;
}

.opponent.with-path.enter-active, .opponent.with-path.enter-done, .opponent.with-path.exit {
  height: 56px;
}

.opponent.exit {
  height: 28px;
  opacity: 1;
}

.opponent.exit-active {
  height: 0;
  opacity: 0;
  transition: height .5s, opacity .5s;
}

.opponent.exit-done {
  height: 0;
  opacity: 0;
}

.opponent-info {
  white-space: nowrap;
  flex-flow: row;
  align-items: end;
  gap: 12px;
  display: flex;
}

.side .opponent-info {
  justify-content: end;
}

.opponent-status {
  width: 80px;
  text-align: center;
  vertical-align: bottom;
  border-style: solid;
  border-width: 1px;
  border-radius: 9px;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  transition: background-color 1s, border-color 1s;
  display: inline-block;
  overflow: hidden;
}

.opponent-status.running {
  background-color: var(--color-lightgrey);
  border-color: var(--color-grey);
}

.opponent-status.finished {
  background-color: var(--color-wikiwhite);
  border-color: var(--color-lightgrey);
}

.opponent-status.finished.first {
  background-color: var(--color-green);
  color: #fff;
}

.opponent-status.dnf {
  background-color: var(--color-red);
  border-color: var(--color-red);
  color: #fff;
}

.opponent .page {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.opponent-path-container {
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 0;
  display: flex;
}

.opponent-path-container > label {
  color: var(--color-darkgrey);
}

.side .opponent-path-container {
  justify-content: end;
}

.mini-icon {
  width: 22px;
  height: 22px;
  vertical-align: bottom;
  margin-bottom: -2px;
  padding: 0 3px;
  display: inline-block;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359.9deg);
  }
}

@keyframes glow {
  0% {
    background-color: var(--color-wikiwhite);
  }

  50% {
    background-color: var(--color-lightgrey);
  }
}

.error-container {
  width: 0;
  height: 0;
  position: sticky;
  bottom: 16px;
  left: 16px;
  overflow: visible;
}

.error {
  color: #fff;
  background-color: #ff4c4c;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.error > img {
  width: 24px;
  height: 24px;
  padding: 8px;
}

.error > label {
  white-space: nowrap;
  padding: 8px;
  display: block;
}

.error-enter {
  transform: scale(1, 0);
}

.error-enter-active {
  transition: transform .5s;
  transform: scale(1);
}

.error-enter-done, .error-exit {
  transform: scale(1);
}

.error-exit-active {
  transition: transform .5s;
  transform: scale(1, 0);
}

.error-exit-done {
  transform: scale(1, 0);
}

.link-mouseover {
  z-index: 1000;
  color: #1a1a1a;
  max-width: 360px;
  text-align: center;
  background-color: #c6c6c6;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  font-size: 13px;
  display: flex;
  position: absolute;
  transform: translate(-50%, calc(-100% - 16px));
}

.link-mouseover.target {
  background-color: var(--color-green);
  color: #fff;
}

.link-mouseover.illegal {
  background-color: var(--color-red);
  color: #fff;
}

.link-mouseover > .title {
  font-style: normal;
  font-weight: bold;
}

.link-mouseover > .redirect {
  z-index: 1001;
  color: #5f5f5f;
}

.link-mouseover > .illegal-note {
  font-weight: bold;
}

.link-mouseover > .description {
  z-index: 1001;
  font-style: italic;
}

.link-mouseover > :last-child:after {
  width: 10px;
  height: 10px;
  z-index: 1000;
  content: "";
  background-color: #c6c6c6;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 14px)rotate(45deg);
}

.link-mouseover.target > :last-child:after {
  background-color: var(--color-green);
}

.link-mouseover.illegal > :last-child:after {
  background-color: var(--color-red);
}

.path-container {
  max-height: 24px;
  flex-direction: column-reverse;
  margin-top: 4px;
  display: flex;
  overflow: hidden;
}

.path-container > .path-container-inner {
  border: 1px solid #0000;
  border-radius: 9px;
  padding: 2px;
}

.path-container:has(.path-page:not(:only-child)):hover, .path-container:has(.path-page:not(:only-child)):focus {
  overflow: visible;
}

.path-container:has(.path-page:not(:only-child)):hover > .path-container-inner, .path-container:has(.path-page:not(:only-child)):focus > .path-container-inner {
  border-color: var(--color-grey);
  background-color: var(--color-wikiwhite);
}

.path {
  flex-flow: row-reverse wrap-reverse;
  justify-content: flex-end;
  font-size: 12px;
  display: flex;
}

.path-page {
  display: inline-block;
}

.path-arrow {
  color: #8a8f8f;
}

.path-pill {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 168px;
  background-color: #c8d2d2;
  border-radius: 9px;
  margin: 2px;
  padding: 0 8px;
  line-height: 18px;
  display: inline-block;
  overflow: hidden;
}

.run-status {
  opacity: 0;
  height: 0;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  display: flex;
  overflow: hidden;
}

.run-status.enter {
  opacity: 0;
  height: 0;
}

.run-status.enter-active {
  opacity: 1;
  height: 96px;
  transition: opacity .5s, height .5s;
}

.run-status.enter-done, .run-status.exit {
  opacity: 1;
  height: 96px;
}

.run-status.exit-active {
  opacity: 0;
  height: 0;
  transition: opacity .5s, height .5s;
}

.run-status.exit-done {
  opacity: 0;
  height: 0;
}

.run-status > .primary {
  font-size: 32px;
}

.run-status > .secondary {
  font-size: 18px;
}

.side .path {
  justify-content: flex-start;
}

.post-game {
  width: 0;
  height: 100vh;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  display: flex;
  overflow: hidden;
}

.post-game.enter {
  width: 0;
  opacity: 0;
  overflow: hidden;
}

.post-game.enter-active {
  width: var(--width-side);
  opacity: 1;
  transition: width .5s, opacity .5s;
}

.post-game.enter-done, .post-game.exit {
  width: var(--width-side);
  opacity: 1;
}

.post-game.exit-active {
  opacity: 0;
  width: 0;
  transition: width .5s, opacity .5s;
  overflow: visible;
}

.post-game.exit-done {
  opacity: 0;
  width: 0;
  visibility: hidden;
}

.post-game-align {
  flex-direction: column;
  align-items: end;
  display: flex;
}

.post-game.exit > .post-game-align {
  align-items: start;
}

.post-game-content {
  width: calc(var(--width-side)  - 32px);
  flex-direction: column;
  gap: 8px;
  margin-left: 32px;
  display: flex;
}

.abandon {
  margin-top: 12px;
}

.wiki :has( + h2 + * + .reflist) ~ *, .wiki .metadata, .portalbox, .sistersitebox, .reference, .reflist, #Further_reading, #External_links, #Literary_work, .wiki #coordinates {
  display: none !important;
}

.wiki.no-see-also h2:has(#See_also) {
  display: none !important;
}

.wiki.no-see-also h2:has(#See_also) + ul, .wiki.no-see-also h2:has(#See_also) + :not(h2) + ul, .wiki.no-see-also h2:has(#See_also) + :not(h2) + :not(h2) + ul, .no-navboxes .navbox {
  display: none !important;
}

.reflist ~ * {
  display: none;
}

.wiki:not(.no-navboxes) .navbox:not(.authority-control) {
  display: block !important;
}

.wiki.no-infoboxes .infobox, .wiki.no-thumbs .thumb {
  display: none !important;
}

sup:has(a[title="Wikipedia:Citation needed"]) {
  display: none !important;
}

.bruh, .bruh * {
  cursor: url("data:image/svg+xml;utf8,<svg overflow='visible' xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewport='0 0 100 100' style='fill:black;font-size:18px;'> <text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle'>🤡</text> <animateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 0 -3' to='360 0 -3' dur='.6s' repeatCount='indefinite' /> </svg>") 0 0, auto;
}

cite, dfn {
  font-style: inherit;
}

q {
  quotes: "\"" "\"" "'" "'";
}

blockquote {
  margin: 1em 0;
  padding: 0 40px;
  overflow: hidden;
}

small {
  font-size: 85%;
}

.mw-body-content sub, .mw-body-content sup {
  font-size: 80%;
}

.ns-talk .mw-body-content dd {
  margin-top: .4em;
  margin-bottom: .4em;
}

.client-js .mw-special-Watchlist #watchlist-message, .client-js .collapsible:not(.mw-made-collapsible).collapsed > tbody > tr:not(:first-child), .client-js .outercollapse .innercollapse.mw-collapsible:not(.mw-made-collapsible) > p, .client-js .outercollapse .innercollapse.mw-collapsible:not(.mw-made-collapsible) > table, .client-js .outercollapse .innercollapse.mw-collapsible:not(.mw-made-collapsible) > thead + tbody, .client-js .outercollapse .innercollapse.mw-collapsible:not(.mw-made-collapsible) tr:not(:first-child), .client-js .outercollapse .innercollapse.mw-collapsible:not(.mw-made-collapsible) .mw-collapsible-content, #editpage-specialchars {
  display: none;
}

ol.references {
  margin-bottom: .5em;
  font-size: 90%;
}

span[rel="mw:referencedBy"] > a:before {
  content: counter(mw-ref-linkback, lower-alpha);
  font-size: 80%;
  font-style: italic;
  font-weight: bold;
}

a[rel="mw:referencedBy"]:before {
  content: "^";
  font-weight: bold;
}

span[rel="mw:referencedBy"]:before {
  content: "^ ";
}

.mw-ref > a[data-mw-group="lower-alpha"]:after {
  content: "[" counter(mw-Ref, lower-alpha) "]";
}

.mw-ref > a[data-mw-group="upper-alpha"]:after {
  content: "[" counter(mw-Ref, upper-alpha) "]";
}

.mw-ref > a[data-mw-group="decimal"]:after {
  content: "[" counter(mw-Ref, decimal) "]";
}

.mw-ref > a[data-mw-group="lower-roman"]:after {
  content: "[" counter(mw-Ref, lower-roman) "]";
}

.mw-ref > a[data-mw-group="upper-roman"]:after {
  content: "[" counter(mw-Ref, upper-roman) "]";
}

.mw-ref > a[data-mw-group="lower-greek"]:after {
  content: "[" counter(mw-Ref, lower-greek) "]";
}

.mw-parser-output .mw-collapsible-toggle:not(.mw-ui-button) {
  padding-left: .2em;
  padding-right: .2em;
  font-weight: normal;
}

.mw-collapsible-leftside-toggle .mw-collapsible-toggle {
  float: left;
}

.wikitable td ul, .wikitable td ol, .wikitable td dl {
  text-align: left;
}

.mw-parser-output a[href$=".pdf"].external, .mw-parser-output a[href*=".pdf?"].external, .mw-parser-output a[href*=".pdf#"].external, .mw-parser-output a[href$=".PDF"].external, .mw-parser-output a[href*=".PDF?"].external, .mw-parser-output a[href*=".PDF#"].external {
  background: url("//upload.wikimedia.org/wikipedia/commons/4/4d/Icon_pdf_file.png") 100% no-repeat;
  padding: 8px 18px 8px 0;
}

div.mw-warning-with-logexcerpt, div.mw-lag-warn-high, div.mw-cascadeprotectedwarning, div#mw-protect-cascadeon, div.titleblacklist-warning {
  clear: both;
  box-sizing: border-box;
  background-color: #ffdbdb;
  border: 1px solid #bb7070;
  margin: .2em 0;
  padding: .25em .9em;
}

.mw-contributions-blocked-notice-partial .mw-warning-with-logexcerpt {
  background-color: #fef6e7;
  border-color: #fc3;
}

#wpUploadDescription {
  height: 13em;
}

figure[typeof~="mw:File/Thumb"], figure[typeof~="mw:File/Frame"], .thumbinner {
  min-width: 100px;
}

#mw-subcategories, #mw-pages, #mw-category-media, #filehistory, #wikiPreview, #wikiDiff {
  clear: both;
}

.mw-tag-markers {
  font-size: 90%;
  font-style: italic;
}

.checkuser-show, .sysop-show, .abusefilter-show, .abusefilter-helper-show, .patroller-show, .templateeditor-show, .extendedmover-show, .extendedconfirmed-show, .autoconfirmed-show, .user-show {
  display: none;
}

.ve-ui-mwNoticesPopupTool-item .editnotice-redlink, .ve-ui-mwNoticesPopupTool-item .mbox-image, .ve-ui-mwNoticesPopupTool-item .mbox-imageright {
  display: none !important;
}

ul.permissions-errors {
  margin: 0;
}

ul.permissions-errors > li {
  list-style: none;
}

span.mwe-math-mathml-inline {
  font-size: 118%;
}

.mwe-math-fallback-image-display, .mwe-math-mathml-display {
  margin-top: .6em;
  margin-bottom: .6em;
  margin-left: 1.6em !important;
}

.mwe-math-mathml-display math {
  display: inline;
}

@media screen {
  #content .gallerybox div.thumb {
    background-color: #f8f9fa;
  }

  .gallerybox .thumb img {
    background: #fff url("//upload.wikimedia.org/wikipedia/commons/5/5d/Checker-16x16.png");
  }

  .ns-0 .gallerybox .thumb img, .ns-2 .gallerybox .thumb img, .ns-100 .gallerybox .thumb img {
    background-image: none;
  }

  #siteSub {
    display: block;
  }
}

.flaggedrevs_draft_synced, .flaggedrevs_stable_synced, #t-upload, .mw-special-Book #coll-downloadbox {
  display: none;
}

.infobox {
  border-spacing: 3px;
  color: #000;
  float: right;
  clear: right;
  width: 22em;
  background-color: #f8f9fa;
  border: 1px solid #a2a9b1;
  margin: .5em 0 .5em 1em;
  padding: .2em;
  font-size: 88%;
  line-height: 1.5em;
}

.infobox-header, .infobox-label, .infobox-above, .infobox-full-data, .infobox-data, .infobox-below, .infobox-subheader, .infobox-image, .infobox-navbar, .infobox th, .infobox td {
  vertical-align: top;
}

.infobox-label, .infobox-data, .infobox th, .infobox td {
  text-align: left;
}

.infobox .infobox-above, .infobox .infobox-title, .infobox caption {
  text-align: center;
  font-size: 125%;
  font-weight: bold;
}

.infobox-title, .infobox caption {
  padding: .2em;
}

.infobox .infobox-header, .infobox .infobox-subheader, .infobox .infobox-image, .infobox .infobox-full-data, .infobox .infobox-below {
  text-align: center;
}

.infobox .infobox-navbar {
  text-align: right;
}

.wikitable.plainrowheaders th[scope="row"], .wikitable.plainrowheaders th[scope="rowgroup"] {
  text-align: left;
  font-weight: normal;
}

.nounderlines a, .IPA a:link, .IPA a:visited {
  text-decoration: none !important;
}

.nowrap, .nowraplinks a {
  white-space: nowrap;
}

.wrap, .wraplinks a {
  white-space: normal;
}

span.texhtml {
  white-space: nowrap;
  -webkit-font-feature-settings: "lnum", "tnum", "kern" 0;
  font-feature-settings: "lnum", "tnum", "kern" 0;
  font-variant-numeric: lining-nums tabular-nums;
  font-kerning: none;
  font-family: Nimbus Roman No9 L, Times New Roman, Times, serif;
  font-size: 118%;
  line-height: 1;
}

span.texhtml span.texhtml {
  font-size: 100%;
}

@media screen {
  .nochecker .gallerybox .thumb img {
    background-image: none;
  }
}

.skin-vector-2022 #coordinates {
  top: 2em;
}

@media (max-width: 719px) {
  .skin-vector-2022 a.external {
    word-break: break-word;
  }
}

.page-Main_Page #deleteconfirm, .page-Main_Page #t-cite, .page-Main_Page #footer-info-lastmod, .action-view.page-Main_Page #siteSub, .action-view.page-Main_Page #contentSub, .action-view.page-Main_Page #contentSub2 {
  display: none !important;
}

#coordinates {
  float: right;
  text-align: right;
  text-indent: 0;
  text-transform: none;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-size: 85%;
  line-height: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
}

.ve-ce-surface-enabled #coordinates {
  margin-top: -1em;
  margin-right: 2em;
}

.mw-indicator #coordinates {
  text-align: right;
  white-space: nowrap;
  font-size: 92%;
  line-height: 1.6;
  position: absolute;
  top: 3.5em;
  right: 0;
}

body.skin-vector-legacy div.flaggedrevs_short {
  z-index: 1;
  position: absolute;
  top: -3em;
  right: 100px;
}

body.skin-vector-legacy #siteSub {
  font-size: 92%;
}

body.skin-vector-legacy .mw-indicators {
  padding-top: .4em;
}

.mw-body-content blockquote {
  border-left: none;
}

@media print {
  .ns--1 .ambox, .ns-0 .ambox, .ns--1 .navbox, .ns-0 .navbox, .mediawiki.ns--1 .mw-parser-output .sidebar, .mediawiki.ns-0 .mw-parser-output .sidebar, .ns--1 .sisterproject, .ns-0 .sisterproject, .ns--1 .hatnote, .ns-0 .hatnote, .ns--1 .metadata, .ns-0 .metadata, .sistersitebox, .editlink, .navbar, span.mw-collapsible-toggle, th .sortkey, td .sortkey, #mw-revision-nav, .nourlexpansion a.external.text:after, .nourlexpansion a.external.autonumber:after {
    display: none !important;
  }

  .mw-parser-output .mw-collapsed .mw-collapsible-content {
    display: block !important;
  }

  table.collapsible tr, .mw-parser-output table.mw-collapsed > * > tr {
    display: table-row !important;
  }

  .mw-parser-output ol.mw-collapsed > li, .mw-parser-output ul.mw-collapsed > li {
    display: list-item !important;
  }

  #firstHeading {
    margin: 0;
  }

  #content a.external.text:after, #content a.external.autonumber:after {
    word-wrap: break-word;
  }

  .infobox {
    border-spacing: 0;
    border-collapse: collapse;
    background-color: #fff;
    border: 1px solid #aaa;
    width: 180pt !important;
  }

  .infobox > * > tr > td, .infobox > * > tr > th {
    border-bottom: 1px solid #eaecf0;
    padding: 2px 5px;
  }

  .infobox a, .infobox > * > tr:last-child > th, .infobox > * > tr:last-child > td {
    border: 0;
  }

  .refbegin a, .references a, .reference a {
    color: #000 !important;
  }

  .reference a {
    border-bottom: 0;
  }

  ol.references, div.reflist, div.refbegin, cite * {
    font-size: inherit !important;
  }

  .refbegin li, .references li {
    color: #666;
    line-height: 14pt;
  }

  .printfooter {
    clear: both;
  }
}

.mw-cite-backlink, .cite-accessibility-label {
  -webkit-user-select: none;
  user-select: none;
}

.mw-references-columns {
  -webkit-column-width: 30em;
  -moz-column-width: 30em;
  column-width: 30em;
  margin-top: .3em;
}

.mw-parser-output .mw-references-columns .references {
  margin-top: 0;
}

.mw-references-columns li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

ol.references {
  counter-reset: mw-ref-extends-parent mw-references list-item;
}

ol.references > li {
  counter-increment: mw-ref-extends-parent mw-references;
  counter-reset: mw-ref-extends-child;
}

ol.references .mw-extended-references {
  margin-left: 2em;
  list-style: none;
}

.mw-extended-references > li:before {
  counter-increment: mw-ref-extends-child;
  content: counter(mw-ref-extends-parent, decimal) "." counter(mw-ref-extends-child, decimal) ". ";
}

sup.reference {
  unicode-bidi: -moz-isolate;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
}

ol.references li:target, sup.reference:target {
  background-color: #eaf3ff;
}

.mw-ext-cite-error {
  unicode-bidi: embed;
  font-weight: bold;
}

.mw-ext-cite-error code {
  color: inherit;
  background-color: #0000;
  padding: 1px 2px;
}

.mw-cite-dir-ltr .reference-text {
  direction: ltr;
  unicode-bidi: embed;
}

.mw-cite-dir-rtl .reference-text {
  direction: rtl;
  unicode-bidi: embed;
}

@media print {
  .mw-cite-backlink {
    display: none;
  }
}

video {
  height: auto;
  max-width: 100%;
}

audio, .mw-tmh-player.audio {
  height: 30px;
  --inline-controls-bar-height: 30px;
  width: 300px;
}

video.thumbimage, audio.thumbimage, .thumb .mw-tmh-player.audio {
  border: 0;
  margin: 1px auto;
}

.gallerybox .mw-tmh-player.audio {
  width: 90%;
}

.mw-tmh-player {
  line-height: 0;
  display: inline-block;
  position: relative;
}

.mw-tmh-player .mw-tmh-play {
  display: block;
  position: absolute;
  inset: 0;
}

.thumb audio, .thumb video, .thumb .mw-tmh-player {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.mw-tmh-label {
  color: #fff;
  cursor: pointer;
  background-color: #000c;
  border-radius: .3em;
  padding: .5em;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  position: absolute;
  right: 1em;
}

.mw-tmh-player.video .mw-tmh-label.mw-tmh-duration {
  bottom: 1em;
}

.mw-tmh-player.video .mw-tmh-label.mw-tmh-cc {
  font-size: 110%;
  top: 1em;
}

.mw-tmh-player.audio .mw-tmh-label {
  font-size: 80%;
  top: .35em;
}

.mw-tmh-player.audio .mw-tmh-label.mw-tmh-cc {
  right: 5em;
}

.mw-tmh-player.video .mw-tmh-play .mw-tmh-play-icon {
  width: 50px;
  height: 50px;
  background: #000c url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3Eplay%3C/title%3E %3Cpath fill=%22%23fff%22 d=%22M4.55 19A1 1 0 0 1 3 18.13V1.87A1 1 0 0 1 4.55 1l12.2 8.13a1 1 0 0 1 0 1.7z%22/%3E %3C/svg%3E") center no-repeat;
  border-radius: 50%;
  margin: auto;
  transition: transform .12s ease-out, background-color .12s ease-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mw-tmh-player.audio .mw-tmh-play .mw-tmh-play-icon {
  height: 100%;
  width: 100%;
  background: #00000080 url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3Eplay%3C/title%3E %3Cpath fill=%22%23fff%22 d=%22M4.55 19A1 1 0 0 1 3 18.13V1.87A1 1 0 0 1 4.55 1l12.2 8.13a1 1 0 0 1 0 1.7z%22/%3E %3C/svg%3E") 10px no-repeat;
  transition: background-color .12s ease-out;
  display: block;
}

.mw-tmh-player.video .mw-tmh-play:hover .mw-tmh-play-icon, .mw-tmh-player.video .mw-tmh-play:focus .mw-tmh-play-icon, .mw-tmh-player.video .mw-tmh-play:active .mw-tmh-play-icon {
  background-color: #000c;
  transform: translate(-50%, -50%)scale(1.1);
}

.mw-tmh-player.audio .mw-tmh-play:hover .mw-tmh-play-icon, .mw-tmh-player.audio .mw-tmh-play:focus .mw-tmh-play-icon, .mw-tmh-player.audio .mw-tmh-play:active .mw-tmh-play-icon {
  background-color: #000c;
}

@supports (aspect-ratio: 1 / 1 ) {
  .mw-tmh-player.video > .mw-tmh-play .mw-tmh-play-icon {
    width: auto;
    height: min(50px, 70%);
    aspect-ratio: 1;
  }
}

.mw-tmh-player-interstitial {
  text-align: center;
  background-color: #ffffff80;
  position: fixed;
  inset: 0;
}

.mw-tmh-player-progress {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  overflow: hidden;
}

.mw-tmh-player-progress-bar {
  height: 1em;
  width: 40%;
  background-color: #36c;
  transition: width .1s;
  animation: 2s linear infinite mw-tmh-player-progress-bar-slide;
  transform: translate(-25%);
}

@keyframes mw-tmh-player-progress-bar-slide {
  from {
    transform: translate(-100%);
  }

  to {
    transform: translate(350%);
  }
}

.mw-ui-button {
  color: #202122;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  -webkit-appearance: none;
  background-color: #f8f9fa;
  border: 1px solid #a2a9b1;
  border-radius: 2px;
  margin: 0;
  font-family: inherit;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.28571em;
  display: inline-block;
}

.mw-ui-button:not(.mw-ui-icon-element) {
  min-height: 32px;
  min-width: 4em;
  max-width: 28.75em;
  padding: 5px 12px;
}

.mw-ui-button:not(:disabled) {
  transition-property: background-color, color, border-color, box-shadow;
  transition-duration: .1s;
}

.mw-ui-button:not(:disabled):visited {
  color: #202122;
}

.mw-ui-button:not(:disabled):hover {
  color: #404244;
  background-color: #fff;
  border-color: #a2a9b1;
}

.mw-ui-button:not(:disabled):focus {
  color: #202122;
  border-color: #36c;
  outline-width: 0;
  box-shadow: inset 0 0 0 1px #36c, inset 0 0 0 2px #fff;
}

.mw-ui-button:not(:disabled):focus::-moz-focus-inner {
  border-color: #0000;
  padding: 0;
}

.mw-ui-button:not(:disabled):active, .mw-ui-button:not(:disabled).is-on {
  color: #000;
  box-shadow: none;
  background-color: #c8ccd1;
  border-color: #72777d;
}

.mw-ui-button:disabled {
  color: #fff;
  cursor: default;
  background-color: #c8ccd1;
  border-color: #c8ccd1;
}

.mw-ui-button.mw-ui-icon-element:not(.mw-ui-icon-with-label-desktop) {
  color: #0000 !important;
}

.mw-ui-button.mw-ui-icon-element:not(.mw-ui-icon-with-label-desktop) span {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  display: block;
  overflow: hidden;
  position: absolute !important;
}

@media (max-width: 1000px) {
  .mw-ui-button.mw-ui-icon-element.mw-ui-icon-with-label-desktop {
    color: #0000 !important;
  }

  .mw-ui-button.mw-ui-icon-element span {
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    display: block;
    overflow: hidden;
    position: absolute !important;
  }
}

.mw-ui-button.mw-ui-quiet, .mw-ui-button.mw-ui-quiet.mw-ui-progressive, .mw-ui-button.mw-ui-quiet.mw-ui-destructive {
  color: #202122;
  background-color: #0000;
  border-color: #0000;
  font-weight: bold;
}

.mw-ui-button.mw-ui-quiet:not(.mw-ui-icon-element), .mw-ui-button.mw-ui-quiet.mw-ui-progressive:not(.mw-ui-icon-element), .mw-ui-button.mw-ui-quiet.mw-ui-destructive:not(.mw-ui-icon-element) {
  min-height: 32px;
}

input[type="checkbox"]:hover + .mw-ui-button.mw-ui-quiet, input[type="checkbox"]:hover + .mw-ui-button.mw-ui-quiet.mw-ui-progressive, input[type="checkbox"]:hover + .mw-ui-button.mw-ui-quiet.mw-ui-destructive, .mw-ui-button.mw-ui-quiet:hover, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:hover, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:hover {
  color: #202122;
  background-color: #00184907;
  border-color: #0000;
}

input[type="checkbox"]:focus + .mw-ui-button.mw-ui-quiet, input[type="checkbox"]:focus + .mw-ui-button.mw-ui-quiet.mw-ui-progressive, input[type="checkbox"]:focus + .mw-ui-button.mw-ui-quiet.mw-ui-destructive, .mw-ui-button.mw-ui-quiet:focus, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:focus, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:focus {
  color: #202122;
  border-color: #36c;
  box-shadow: inset 0 0 0 1px #36c, inset 0 0 0 2px #fff;
}

input[type="checkbox"]:active + .mw-ui-button.mw-ui-quiet, input[type="checkbox"]:active + .mw-ui-button.mw-ui-quiet.mw-ui-progressive, input[type="checkbox"]:active + .mw-ui-button.mw-ui-quiet.mw-ui-destructive, .mw-ui-button.mw-ui-quiet:active, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:active, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:active {
  color: #000;
  box-shadow: none;
  background-color: #00244915;
  border-color: #72777d;
}

.mw-ui-button.mw-ui-quiet:disabled, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled, .mw-ui-button.mw-ui-quiet:disabled:hover, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled:hover, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled:hover, .mw-ui-button.mw-ui-quiet:disabled:active, .mw-ui-button.mw-ui-quiet.mw-ui-progressive:disabled:active, .mw-ui-button.mw-ui-quiet.mw-ui-destructive:disabled:active {
  color: #72777d;
  background-color: #0000;
  border-color: #0000;
}

.mw-ui-button.mw-ui-progressive:not(:disabled) {
  color: #fff;
  background-color: #36c;
  border-color: #36c;
}

.mw-ui-button.mw-ui-progressive:not(:disabled):hover {
  background-color: #447ff5;
  border-color: #447ff5;
}

.mw-ui-button.mw-ui-progressive:not(:disabled):focus {
  box-shadow: inset 0 0 0 1px #36c, inset 0 0 0 2px #fff;
}

.mw-ui-button.mw-ui-progressive:not(:disabled):active, .mw-ui-button.mw-ui-progressive:not(:disabled).is-on {
  box-shadow: none;
  background-color: #2a4b8d;
  border-color: #2a4b8d;
}

.mw-ui-button.mw-ui-progressive:disabled {
  color: #fff;
  background-color: #c8ccd1;
  border-color: #c8ccd1;
}

.mw-ui-button.mw-ui-progressive.mw-ui-quiet {
  color: #36c;
  background-color: #0000;
  border-color: #0000;
}

input[type="checkbox"]:hover + .mw-ui-button.mw-ui-progressive.mw-ui-quiet, .mw-ui-button.mw-ui-progressive.mw-ui-quiet:hover {
  color: #447ff5;
  background-color: #347bff33;
  border-color: #0000;
}

input[type="checkbox"]:focus + .mw-ui-button.mw-ui-progressive.mw-ui-quiet, .mw-ui-button.mw-ui-progressive.mw-ui-quiet:focus {
  color: #36c;
  border-color: #36c;
}

input[type="checkbox"]:active + .mw-ui-button.mw-ui-progressive.mw-ui-quiet, .mw-ui-button.mw-ui-progressive.mw-ui-quiet:active {
  color: #fff;
  background-color: #2a4b8d;
  border-color: #2a4b8d;
}

.mw-ui-button.mw-ui-destructive:not(:disabled) {
  color: #fff;
  background-color: #d33;
  border-color: #d33;
}

.mw-ui-button.mw-ui-destructive:not(:disabled):hover {
  background-color: #ff4242;
  border-color: #ff4242;
}

.mw-ui-button.mw-ui-destructive:not(:disabled):focus {
  box-shadow: inset 0 0 0 1px #d33, inset 0 0 0 2px #fff;
}

.mw-ui-button.mw-ui-destructive:not(:disabled):active, .mw-ui-button.mw-ui-destructive:not(:disabled).is-on {
  box-shadow: none;
  background-color: #b32424;
  border-color: #b32424;
}

.mw-ui-button.mw-ui-destructive:disabled {
  color: #fff;
  background-color: #c8ccd1;
  border-color: #c8ccd1;
}

.mw-ui-button.mw-ui-destructive.mw-ui-quiet {
  color: #d33;
  background-color: #0000;
  border-color: #0000;
}

input[type="checkbox"]:hover + .mw-ui-button.mw-ui-destructive.mw-ui-quiet, .mw-ui-button.mw-ui-destructive.mw-ui-quiet:hover {
  color: #ff4242;
  background-color: #d11d1333;
  border-color: #0000;
}

input[type="checkbox"]:focus + .mw-ui-button.mw-ui-destructive.mw-ui-quiet, .mw-ui-button.mw-ui-destructive.mw-ui-quiet:focus {
  color: #d33;
  border-color: #d33;
}

input[type="checkbox"]:active + .mw-ui-button.mw-ui-destructive.mw-ui-quiet, .mw-ui-button.mw-ui-destructive.mw-ui-quiet:active {
  color: #fff;
  background-color: #b32424;
  border-color: #b32424;
}

.mw-ui-button.mw-ui-big {
  font-size: 1.3em;
}

.mw-ui-button.mw-ui-block {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

a.mw-ui-button, a.mw-ui-button:hover, a.mw-ui-button:focus {
  text-decoration: none;
}

.mw-ui-button-group > * {
  min-width: 48px;
  float: left;
  border-radius: 0;
}

.mw-ui-button-group > :first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.mw-ui-button-group > :not(:first-child) {
  border-left: 0;
}

.mw-ui-button-group > :last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.mw-ui-button-group .is-on .button {
  cursor: default;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: -.01ms !important;
  }
}

body {
  margin: 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

@supports ((-webkit-text-decoration: underline dotted ) or (text-decoration: underline dotted )) {
  abbr[title] {
    border-bottom: 0;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }
}

pre, code, tt, kbd, samp {
  font-family: monospace;
}

sub, sup {
  line-height: 1;
}

img {
  border: 0;
}

button, input, optgroup, select, textarea {
  margin: 0;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  color: inherit;
  padding: 0;
}

div.tright, div.floatright, table.floatright {
  clear: right;
  float: right;
}

div.tleft, div.floatleft, table.floatleft {
  float: left;
  clear: left;
}

div.floatright, table.floatright {
  margin: 0 0 .5em .5em;
}

div.floatleft, table.floatleft {
  margin: 0 .5em .5em 0;
}

div.thumb {
  width: auto;
  background-color: #0000;
  margin-bottom: .5em;
}

div.tleft {
  margin: .5em 1.4em 1.3em 0;
}

div.tright {
  margin: .5em 0 1.3em 1.4em;
}

.thumbcaption {
  text-align: left;
  padding: 3px;
  line-height: 1.4em;
}

div.thumbinner {
  text-align: center;
  padding: 3px;
  overflow: hidden;
}

.mw-message-box {
  color: #000;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: #eaecf0;
  border: 1px solid #a2a9b1;
  margin-bottom: 16px;
  padding: 12px 24px;
  overflow: hidden;
}

.mw-message-box > :only-child {
  margin: 0;
}

.mw-message-box h2 {
  color: inherit;
  border: 0;
  font-size: 1em;
  font-weight: bold;
  display: block;
}

.mw-message-box .mw-logevent-loglines li {
  font-size: 90%;
}

.mw-message-box-error {
  background-color: #fee7e6;
  border-color: #d33;
}

.mw-message-box-warning {
  background-color: #fef6e7;
  border-color: #fc3;
}

.mw-message-box-success {
  background-color: #d5fdf4;
  border-color: #14866d;
}

@media screen {
  html, body {
    height: 100%;
  }

  :focus {
    outline-color: #36c;
  }

  html {
    font-size: 100%;
  }

  html, body {
    font-family: sans-serif;
  }

  ul {
    list-style-image: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515");
  }

  pre, .mw-code {
    line-height: 1.3;
  }

  .mw-jump-link:not(:focus) {
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    display: block;
    overflow: hidden;
    position: absolute !important;
  }

  .mw-editsection, .mw-editsection-like {
    font-family: sans-serif;
  }

  .mw-body h1, .mw-body-content h1, .mw-body-content h2 {
    margin-bottom: .25em;
    padding: 0;
    font-family: Linux Libertine, Georgia, Times, serif;
    line-height: 1.3;
  }

  .mw-body h1:lang(ja), .mw-body-content h1:lang(ja), .mw-body-content h2:lang(ja), .mw-body h1:lang(he), .mw-body-content h1:lang(he), .mw-body-content h2:lang(he), .mw-body h1:lang(ko), .mw-body-content h1:lang(ko), .mw-body-content h2:lang(ko) {
    font-family: sans-serif;
  }

  .mw-body h1:lang(ckb), .mw-body-content h1:lang(ckb), .mw-body-content h2:lang(ckb) {
    font-family: Scheherazade, Linux Libertine, Georgia, Times, serif;
  }

  .mw-body h1:lang(my), .mw-body-content h1:lang(my), .mw-body-content h2:lang(my) {
    line-height: normal;
  }

  .mw-body h1, .mw-body-content h1 {
    font-size: 1.8em;
  }

  .vector-body {
    font-size: .875em;
    line-height: 1.6;
  }

  .vector-body #siteSub {
    font-size: 12.8px;
  }

  .vector-body h1 {
    margin-top: 1em;
  }

  .vector-body h2 {
    margin-top: 1em;
    font-size: 1.5em;
  }

  .vector-body h3, .vector-body h4, .vector-body h5, .vector-body h6 {
    margin-top: .3em;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.6;
  }

  .vector-body h3 {
    font-size: 1.2em;
  }

  .vector-body h3, .vector-body h4 {
    font-weight: bold;
  }

  .vector-body h4, .vector-body h5, .vector-body h6 {
    font-size: 100%;
  }

  .vector-body .toc h2 {
    font-family: sans-serif;
    font-size: 100%;
  }

  .vector-body p {
    margin: .5em 0;
  }

  .vector-body blockquote {
    border-left: 4px solid #eaecf0;
    padding: 8px 32px;
  }

  .vector-body blockquote > :first-child {
    margin-top: 0;
  }

  .vector-body blockquote > :last-child {
    margin-bottom: 0;
  }

  .mw-parser-output a.external {
    background-image: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/link-external-small-ltr-progressive.svg?2cd31");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: .857em;
    padding-right: 1em;
  }

  .mw-indicators {
    float: right;
    font-size: .875em;
    line-height: 1.6;
    position: relative;
  }

  .mw-indicator {
    display: inline-block;
  }

  #localNotice {
    margin-bottom: .9em;
  }

  #siteNotice {
    text-align: center;
    margin: 24px 0;
    font-size: .8em;
    position: relative;
  }

  .vector-menu-dropdown {
    position: relative;
  }

  .vector-menu-dropdown > .vector-menu-content {
    opacity: 0;
    height: 0;
    visibility: hidden;
    z-index: 3;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: -1px;
    overflow: hidden;
  }

  .vector-menu-dropdown > .vector-menu-content-list {
    list-style: none;
  }

  .vector-menu-dropdown .mw-list-item {
    margin: 0;
    padding: 0;
  }

  .vector-menu-dropdown .mw-list-item a {
    cursor: pointer;
  }

  .vector-menu-dropdown .selected a, .vector-menu-dropdown .selected a:visited {
    color: #202122;
    text-decoration: none;
  }

  .vector-menu-checkbox {
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .vector-menu-checkbox:checked ~ .vector-menu-content {
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  :not(:checked) > .vector-menu-checkbox {
    display: block;
  }

  .vector-menu-checkbox:focus + .vector-menu-heading {
    outline: 1px dotted;
    outline: auto -webkit-focus-ring-color;
  }

  .vector-search-box-inner {
    height: 100%;
    position: relative;
  }

  .vector-search-box-input {
    color: #000;
    width: 100%;
    height: 2.15385em;
    box-sizing: border-box;
    direction: ltr;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background-color: #ffffff80;
    border: 1px solid #a2a9b1;
    border-radius: 2px;
    padding: 5px 2.15385em 5px .4em;
    font-family: inherit;
    font-size: .8125em;
    transition-property: border-color, box-shadow;
    transition-duration: .25s;
    box-shadow: inset 0 0 0 1px #0000;
  }

  .vector-search-box-inner:hover .vector-search-box-input {
    border-color: #72777d;
  }

  .vector-search-box-input:focus, .vector-search-box-inner:hover .vector-search-box-input:focus {
    border-color: #36c;
    outline: 0;
    box-shadow: inset 0 0 0 1px #36c;
  }

  .vector-search-box-input::-webkit-input-placeholder {
    color: #72777d;
    opacity: 1;
  }

  .vector-search-box-input:-ms-input-placeholder {
    color: #72777d;
    opacity: 1;
  }

  .vector-search-box-input::placeholder {
    color: #72777d;
    opacity: 1;
  }

  .vector-search-box-input::-webkit-search-decoration, .vector-search-box-input::-webkit-search-cancel-button, .vector-search-box-input::-webkit-search-results-button, .vector-search-box-input::-webkit-search-results-decoration {
    display: none;
  }

  .searchButton {
    min-width: 28px;
    width: 2.15385em;
    cursor: pointer;
    direction: ltr;
    text-indent: -99999px;
    white-space: nowrap;
    z-index: 1;
    background-color: #0000;
    border: 0;
    padding: 0;
    font-size: .8125em;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    overflow: hidden;
  }

  .searchButton[name="go"] {
    opacity: .67;
    background: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/search.svg?ac00d") center / 1.23077em no-repeat;
  }

  .search-toggle {
    float: right;
    display: block;
  }

  .vector-search-box-collapses > div {
    display: none;
  }

  #p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
  }

  #p-logo a {
    width: 10em;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    text-decoration: none;
    display: block;
  }

  .mw-footer {
    direction: ltr;
  }

  .mw-footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mw-footer li {
    color: #202122;
    margin: 0;
    padding: .5em 0;
    font-size: .75em;
  }

  #footer-icons {
    float: right;
  }

  #footer-icons li {
    float: left;
    text-align: right;
    margin-left: .5em;
    line-height: 2;
  }

  #footer-info li {
    line-height: 1.4;
  }

  #footer-places li {
    float: left;
    margin-right: 1em;
    line-height: 2;
  }
}

@media screen and (min-width: 1000px) {
  .search-toggle {
    display: none;
  }

  .vector-search-box-collapses > div {
    display: block;
  }
}

@media print {
  .toc, body {
    padding: 10px;
    font-family: Linux Libertine, Georgia, Times, serif;
  }

  .printfooter, .mw-footer, .thumb, figure, table, ol, dl, ul, h3, h4, h5, h6 {
    font-family: sans-serif;
  }

  img {
    font-family: Linux Libertine, Georgia, Times, serif;
  }

  .mw-body a:not(.image) {
    border-bottom: 1px solid #aaa;
  }

  .firstHeading {
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-size: 25pt;
    line-height: 28pt;
  }

  .firstHeading, h2 {
    border-bottom: 2px solid #000;
    overflow: hidden;
  }

  h3, h4, h5, h6 {
    margin: 30px 0 0;
  }

  h2, h3, h4, h5, h6 {
    padding: 0;
    position: relative;
  }

  h2 {
    margin-bottom: .25em;
    font-size: 18pt;
    line-height: 24pt;
  }

  h3 {
    font-size: 14pt;
    line-height: 20pt;
  }

  h4, h5, h6 {
    font-size: 12pt;
    line-height: 16pt;
  }

  p {
    text-align: justify;
    margin-top: 5px;
    font-size: 12pt;
    line-height: 16pt;
  }

  p:before {
    content: "";
    width: 120pt;
    display: block;
    overflow: hidden;
  }

  blockquote {
    border-left: 2px solid #000;
    padding-left: 20px;
  }

  ol, ul {
    margin: 10px 0 0 1.6em;
    padding: 0;
  }

  ol li, ul li {
    padding: 2px 0;
    font-size: 12pt;
  }

  table ol li, table ul li {
    font-size: inherit;
  }

  .toc {
    page-break-before: avoid;
    page-break-after: avoid;
    background: none;
    border: 0;
    display: table;
  }

  .toc a {
    border: 0;
    font-weight: normal;
  }

  .toc > ul > li {
    margin-bottom: 4px;
    font-weight: bold;
  }

  .toc ul {
    margin: 0;
    list-style: none;
  }

  .toc ul ul {
    padding-left: 30px;
  }

  .toc li.toclevel-1 > a {
    font-size: 12pt;
    font-weight: bold;
  }

  #mw-navigation, .noprint, .mw-jump-link, .mw-portlet-lang, .toc .tocnumber, .mw-checkbox-hack-checkbox, .mw-checkbox-hack-button {
    display: none;
  }

  .printfooter {
    clear: both;
    border-top: 3px solid #000;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 10pt;
  }

  .mw-footer {
    border-top: 1px solid #eee;
    margin-top: 12px;
    padding-top: 5px;
  }

  #footer-info {
    margin: 0;
    padding: 0;
  }

  #footer-info li {
    color: #999;
    padding-bottom: 10px;
    font-size: 10pt;
    list-style: none;
    display: block;
  }

  #footer-info li a {
    color: #999 !important;
  }

  #footer-info-lastmod {
    color: #000;
    font-size: 12pt;
    font-weight: bold;
  }
}

@media screen {
  body {
    color: #202122;
    background-color: #f8f9fa;
    overflow-y: scroll;
  }

  .mw-body, .parsoid-body {
    direction: ltr;
  }

  .mw-body {
    padding: .5em 0 1.5em;
  }

  .mw-body .firstHeading {
    word-wrap: break-word;
    margin-bottom: 0;
  }

  .mw-header {
    z-index: 4;
    position: relative;
  }

  .mw-body-content {
    margin-top: 16px;
  }

  .mw-body, #mw-data-after-content, .mw-footer {
    margin-left: 0;
  }

  .mw-indicators {
    z-index: 1;
  }

  .vector-page-titlebar:after, .vector-body-before-content:after {
    clear: both;
    content: "";
    display: block;
  }

  .mw-body .mw-portlet-lang {
    float: right;
  }

  .vector-body {
    z-index: 0;
    position: relative;
  }

  #siteSub {
    margin-top: 8px;
    display: none;
  }

  #contentSub:not(:empty), #contentSub2 {
    color: #54595d;
    width: auto;
    margin: 8px 0 0;
    font-size: 84%;
    line-height: 1.2em;
  }

  .parsoid-body {
    padding: .5em 0 1.5em;
  }

  .mw-page-container {
    z-index: 0;
    max-width: 99.75em;
    min-width: 31.25em;
    min-height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    padding: .05px 1.5em;
    position: relative;
  }

  .vector-feature-limited-width-disabled .mw-page-container {
    max-width: none;
  }

  .skin--responsive .mw-page-container {
    min-width: auto;
  }

  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc:after, .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc, .vector-feature-page-tools-disabled .vector-main-menu {
    width: 220px;
  }

  .vector-feature-page-tools-disabled .mw-content-container, .vector-feature-page-tools-disabled .mw-table-of-contents-container {
    max-width: 60em;
  }

  .vector-feature-limited-width-disabled .mw-content-container, .vector-feature-limited-width-disabled .mw-table-of-contents-container, .vector-feature-limited-width-content-disabled .mw-content-container, .vector-feature-limited-width-content-disabled .mw-table-of-contents-container {
    max-width: none !important;
    width: 100% !important;
  }

  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not(:checked) ~ .vector-sidebar-container-no-toc ~ .mw-content-container, .vector-feature-page-tools-disabled .vector-toc-unpinned #mw-sidebar-checkbox:not(:checked) ~ .mw-content-container, .vector-feature-page-tools-disabled.vector-toc-unpinned #mw-sidebar-checkbox:not(:checked) ~ .mw-content-container {
    width: 100%;
    grid-column: mainMenu / pageContent;
    margin-left: auto;
    margin-right: auto;
  }

  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-sidebar-container-no-toc ~ .mw-content-container, .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-toc-unpinned .mw-content-container, .vector-feature-page-tools-enabled.vector-toc-unpinned.vector-feature-main-menu-pinned-disabled .mw-content-container {
    grid-column: mainMenu / pageContent;
    margin-left: auto;
    margin-right: auto;
  }

  .search-form__loader:after {
    content: attr(data-loading-msg);
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
    background: linear-gradient(90deg, #36c 0% 100%) -10% 0 / 0 2px no-repeat, #fff;
    border: 1px solid #a2a9b1;
    border-top-width: 0;
    border-radius: 0 0 2px 2px;
    flex-direction: column;
    justify-content: center;
    padding-left: 36px;
    animation: 1.2s linear .5s infinite alternate search-loader-progress-bar;
    display: flex;
    position: absolute;
    top: 100%;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px #0000;
  }

  .vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .search-form__loader:after {
    width: calc(100% - 24px);
    margin-left: 24px;
  }

  @keyframes search-loader-progress-bar {
    0% {
      background-position: -10% 0;
      background-size: 0 2px;
    }

    30% {
      background-position: -10% 0;
      background-size: 30% 2px;
    }

    70% {
      background-position: 110% 0;
      background-size: 30% 2px;
    }

    100% {
      background-position: 110% 0;
      background-size: 0 2px;
    }
  }

  .vector-search-box {
    flex-grow: 1;
    font-size: .875em;
  }

  .vector-search-box > div {
    max-width: 35.7143em;
  }

  .vector-search-box-vue .vector-search-box-input, .vector-search-box-vue .searchButton {
    font-size: inherit;
  }

  .vector-search-box-vue .vector-search-box-input {
    height: auto;
    min-height: 32px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 1.42857;
  }

  .vector-search-box-vue .searchButton {
    background-size: 20px;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-search-input__end-button {
    opacity: 0;
    transition: opacity .25s;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-search-input__end-button:focus {
    opacity: 1;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-text-input__input:not(:hover):not(:focus) {
    border-right-color: #0000;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-search-input__end-button, .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-search-input__end-button {
    opacity: 1;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-text-input, .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-text-input {
    z-index: 1;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search--active .cdx-text-input__input, .client-js .vector-search-box-vue .vector-typeahead-search:hover .cdx-text-input__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-menu-item {
    margin-bottom: 0;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search .cdx-menu-item a {
    text-decoration: none;
  }

  .client-js .vector-search-box-vue .vector-typeahead-search.vector-search-box-disable-transitions .cdx-text-input__input:enabled, .client-js .vector-search-box-vue .vector-typeahead-search.vector-search-box-disable-transitions .cdx-text-input__start-icon {
    transition: none;
  }

  .client-js .vector-search-box-vue .vector-search-box-input {
    padding-left: 36px;
    padding-right: 8px;
  }

  .client-js .vector-search-box-vue .searchButton {
    pointer-events: none;
    width: 36px;
    opacity: .51;
    background-position-x: 8px;
    background-size: 1.42857em;
    inset: 0 auto 0 1px;
  }

  .client-js .vector-search-box-vue .vector-search-box-input:focus ~ .searchButton {
    opacity: .87;
  }

  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail > div {
    max-width: 37.4286em;
  }

  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .searchButton {
    left: 25px;
  }

  .client-js .vector-search-box-vue.vector-search-box-show-thumbnail.vector-search-box-auto-expand-width .vector-search-box-input {
    width: calc(100% - 24px);
    margin-left: 24px;
  }

  .mixin-vector-legacy-menu-heading-arrow {
    content: "";
    width: 1.23077em;
    height: 1.23077em;
    background: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f") 100% no-repeat;
  }

  .vector-page-titlebar .mw-portlet-lang {
    box-sizing: border-box;
    height: 2em;
    flex-shrink: 0;
    margin-top: 2px;
  }

  .vector-page-titlebar .mw-portlet-lang.mw-ui-icon-flush-right {
    margin-right: -12px;
  }

  .client-nojs .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-0 {
    display: none;
  }

  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading {
    -webkit-user-select: none;
    user-select: none;
    opacity: 1;
  }

  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading .vector-menu-heading-label {
    font-size: .875em;
  }

  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading.mw-ui-progressive.mw-ui-quiet .mw-ui-icon:before {
    opacity: 1;
  }

  .vector-page-titlebar .mw-portlet-lang .vector-menu-heading.mw-ui-progressive.mw-ui-quiet:after {
    opacity: 1;
    background-image: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down-progressive.svg?f0b59");
  }

  .vector-page-titlebar .mw-portlet-lang input:active + .vector-menu-heading.mw-ui-progressive.mw-ui-quiet .mw-ui-icon {
    filter: brightness(0) invert();
  }

  .vector-page-titlebar .mw-portlet-lang input:active + .vector-menu-heading.mw-ui-progressive.mw-ui-quiet:after {
    background-image: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down-invert.svg?e24ea");
  }

  .vector-page-titlebar .mw-portlet-lang > .vector-menu-content {
    box-sizing: border-box;
    max-height: 65vh;
    display: none;
    top: auto;
    left: -1px;
    right: -1px;
    overflow: auto;
  }

  .vector-page-titlebar .mw-portlet-lang > .vector-menu-content li a {
    font-size: inherit;
  }

  .vector-page-titlebar .mw-portlet-lang .vector-menu-checkbox:checked ~ .vector-menu-content {
    display: block;
  }

  .vector-page-titlebar .mw-portlet-lang .after-portlet {
    margin-top: 10px;
  }

  .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-empty + .vector-menu-content {
    min-width: 300px;
    left: auto;
    right: 0;
  }

  .vector-page-titlebar .mw-portlet-lang .mw-portlet-lang-heading-empty + .vector-menu-content .mw-portlet-empty-language-selector-body {
    color: #54595d;
    border-bottom: 1px solid #c8ccd1;
    padding: 20px;
  }

  #p-lang-btn.mw-portlet-empty {
    display: none;
  }

  .mw-interlanguage-selector {
    display: flex;
  }

  .mw-interlanguage-selector:after {
    content: "";
    width: 1.23077em;
    height: 1.23077em;
    background: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f") 100% no-repeat;
    margin-left: -1px;
  }

  .vector-dropdown > .vector-menu-heading.mw-portlet-lang-heading-0 {
    display: none;
  }

  .client-js .action-view .vector-dropdown > .vector-menu-heading.mw-portlet-lang-heading-0 {
    display: flex;
  }

  .vector-user-links {
    flex-shrink: 1;
    justify-content: flex-end;
    align-items: center;
    font-size: .875em;
    display: flex;
    position: relative;
  }

  .vector-user-links .mw-ui-button {
    align-items: center;
    display: flex;
  }

  .vector-user-links .mw-list-item {
    margin: 0;
  }

  .vector-user-menu .vector-menu-content {
    left: auto;
    right: 0;
  }

  .vector-feature-page-tools-disabled .vector-user-menu .vector-menu-content {
    min-width: 200px;
  }

  .vector-user-menu-logout a, .vector-user-menu-create-account a, .vector-user-menu-login a, .mw-portlet-personal .mw-list-item a {
    color: #36c;
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: flex;
  }

  .vector-user-menu-logout a:visited, .vector-user-menu-create-account a:visited, .vector-user-menu-login a:visited, .mw-portlet-personal .mw-list-item a:visited {
    color: #36c;
  }

  .vector-user-menu-logout a.selected a, .vector-user-menu-create-account a.selected a, .vector-user-menu-login a.selected a, .mw-portlet-personal .mw-list-item a.selected a, .vector-user-menu-logout a.selected a:visited, .vector-user-menu-create-account a.selected a:visited, .vector-user-menu-login a.selected a:visited, .mw-portlet-personal .mw-list-item a.selected a:visited {
    color: #202122;
  }

  .vector-feature-page-tools-disabled .vector-user-menu-logout a, .vector-feature-page-tools-disabled .vector-user-menu-create-account a, .vector-feature-page-tools-disabled .vector-user-menu-login a, .vector-feature-page-tools-disabled .mw-portlet-personal .mw-list-item a {
    min-height: 2rem;
    padding: 0 12px;
  }

  .vector-user-links .mw-portlet-vector-user-menu-overflow .vector-menu-content-list {
    align-items: center;
    display: flex;
  }

  .vector-user-links .mw-portlet-vector-user-menu-overflow a:not(.mw-ui-button):not(.mw-echo-notifications-badge) {
    margin: 0 8px;
  }

  .vector-user-menu-logged-in .vector-menu-heading, .vector-user-menu-logged-in .vector-menu-heading:before {
    width: auto;
  }

  .vector-user-menu-anon-editor {
    color: #54595d;
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: flex;
  }

  .vector-user-menu-anon-editor:visited {
    color: #36c;
  }

  .vector-user-menu-anon-editor.selected a, .vector-user-menu-anon-editor.selected a:visited {
    color: #202122;
  }

  .vector-feature-page-tools-disabled .vector-user-menu-anon-editor {
    min-height: 2rem;
    padding: 0 12px;
  }

  .vector-feature-page-tools-enabled .vector-user-menu-anon-editor p {
    margin: 0;
  }

  .vector-user-menu-anon-editor a:before {
    content: "(";
  }

  .vector-user-menu-anon-editor a:after {
    content: ")";
  }

  .vector-user-menu-login {
    border-bottom: 1px solid #eaecf0;
  }

  .vector-user-menu-logout {
    border-top: 1px solid #eaecf0;
  }

  #pt-userpage-2 {
    max-width: 11.0714em;
  }

  #pt-userpage-2 a {
    color: #36c;
  }

  #pt-userpage-2 span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mw-header {
    min-height: 3.125em;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 0;
    display: flex;
  }

  .mw-header .vector-header-start, .mw-header .vector-header-end {
    align-items: center;
    display: flex;
  }

  .mw-header .vector-header-end {
    flex-grow: 1;
  }

  .mw-footer {
    border-top: 1px solid #a2a9b1;
    padding: .75em 0;
  }

  .mw-footer-container {
    padding-top: 50px;
    padding-bottom: 82px;
  }

  .vector-menu ul {
    margin: 0;
    list-style: none;
  }

  .vector-dropdown > .vector-menu-heading {
    display: flex;
  }

  .vector-dropdown > .vector-menu-heading:after {
    content: "";
    width: 1.23077em;
    height: 1.23077em;
    background: url("https://en.wikipedia.org/w/skins/Vector/resources/common/images/arrow-down.svg?9426f") 100% no-repeat;
    margin-left: -1px;
  }

  .vector-dropdown > .vector-menu-content {
    background-color: #fff;
    border: 1px solid #a2a9b1;
    transition-property: opacity;
    transition-duration: .1s;
    box-shadow: 0 1px 1px #0000001a;
  }

  .vector-feature-page-tools-disabled .vector-dropdown > .vector-menu-content {
    min-width: 100%;
  }

  .vector-feature-page-tools-enabled .vector-dropdown > .vector-menu-content {
    width: max-content;
    max-width: 200px;
  }

  .vector-dropdown .mw-list-item a {
    color: #36c;
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: flex;
  }

  .vector-dropdown .mw-list-item a:visited {
    color: #36c;
  }

  .vector-dropdown .mw-list-item a.selected a, .vector-dropdown .mw-list-item a.selected a:visited {
    color: #202122;
  }

  .vector-feature-page-tools-disabled .vector-dropdown .mw-list-item a {
    min-height: 2rem;
    padding: 0 12px;
  }

  .vector-menu-tabs {
    float: left;
  }

  .vector-menu-tabs .mw-list-item .mw-ui-icon {
    margin: 8px 0 0;
  }

  .vector-menu-tabs .mw-list-item a {
    color: #36c;
  }

  .vector-menu-tabs .mw-list-item.vector-tab-noicon a:focus, .vector-menu-tabs .mw-list-item.vector-tab-noicon a:hover {
    border-bottom: 1px solid;
    text-decoration: none;
  }

  .vector-menu-tabs .mw-list-item.new a, .vector-menu-tabs .mw-list-item.new a:visited {
    color: #d33;
  }

  .vector-menu-tabs .mw-list-item.selected a, .vector-menu-tabs .mw-list-item.selected a:visited {
    color: #202122;
    border-bottom: 1px solid;
  }

  .vector-menu-tabs .mw-list-item.vector-tab-noicon, .vector-page-toolbar-container .vector-menu-dropdown {
    margin: 0 8px;
  }

  .vector-menu-tabs .mw-list-item, .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    white-space: nowrap;
  }

  .vector-menu-tabs .mw-list-item, .vector-page-toolbar-container .vector-menu-dropdown {
    float: left;
    margin-bottom: 0;
  }

  .vector-menu-tabs .mw-list-item > a, .vector-page-toolbar-container .vector-menu-dropdown > a, .vector-menu-tabs .mw-list-item > .vector-menu-heading, .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    cursor: pointer;
    max-height: 3.15385em;
    box-sizing: border-box;
    font-weight: normal;
    display: inline-flex;
    position: relative;
  }

  .vector-menu-tabs .mw-list-item > .vector-menu-heading, .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    font-size: inherit;
  }

  .vector-menu-tabs .mw-list-item.vector-tab-noicon > a, .vector-page-toolbar-container .vector-menu-dropdown.vector-tab-noicon > a, .vector-menu-tabs .mw-list-item > .vector-menu-heading, .vector-page-toolbar-container .vector-menu-dropdown > .vector-menu-heading {
    margin-bottom: -1px;
    padding: 18px 0 7px;
  }

  .mw-checkbox-hack-checkbox {
    display: none;
  }

  .mw-checkbox-hack-button {
    cursor: pointer;
    display: inline-block;
  }

  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-enabled .vector-header-start .vector-main-menu-landmark, .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-main-menu-container .vector-main-menu-landmark {
    display: none;
  }

  .vector-main-menu {
    box-sizing: border-box;
  }

  .vector-main-menu #p-navigation .vector-menu-heading {
    display: none;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a, .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: flex;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a:visited, .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content:visited {
    color: #36c;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a.selected a, .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content.selected a, .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a.selected a:visited, .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content.selected a:visited {
    color: #202122;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-opt-out a {
    font-weight: bold;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    padding-top: 0;
  }

  .vector-feature-page-tools-enabled .vector-main-menu .vector-language-sidebar-alert {
    margin: 0;
    padding: 8px;
  }

  .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu {
    background-color: #f8f9fa;
    margin-top: 11px;
    margin-left: -14px;
    padding: 6px 14px;
  }

  .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu .vector-main-menu-action-opt-out a, .vector-feature-page-tools-enabled #vector-main-menu-pinned-container .vector-main-menu .vector-main-menu-action-lang-alert .vector-main-menu-action-content {
    padding-left: 0;
    padding-right: 0;
  }

  #vector-main-menu-unpinned-container .vector-main-menu .vector-language-sidebar-alert {
    margin-bottom: 6px;
  }

  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not(:checked) ~ .vector-main-menu-container .vector-main-menu {
    display: none;
  }

  .vector-feature-page-tools-disabled .vector-main-menu {
    background-color: #f8f9fa;
    padding: 12px 19px 12px 9px;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-heading {
    margin-bottom: .75em;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-content > * {
    font-size: .75em;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-main-menu-action-content > a {
    font-weight: bold;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-action .vector-main-menu-action-item .vector-language-sidebar-alert {
    padding: .75em;
  }

  .vector-feature-page-tools-disabled #mw-sidebar-button:before {
    background-image: url("https://en.wikipedia.org/w/skins/Vector/resources/skins.vector.styles/images/chevronHorizontal-ltr.svg?c223c");
  }

  .vector-feature-page-tools-disabled #mw-sidebar-checkbox:not(:checked) ~ .mw-header #mw-sidebar-button:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E %3Ctitle%3E menu %3C/title%3E %3Cpath d=%22M1 3v2h18V3zm0 8h18V9H1zm0 6h18v-2H1z%22/%3E %3C/svg%3E");
  }

  #mw-sidebar-button:hover:before {
    opacity: 1;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group, .vector-feature-page-tools-disabled .vector-main-menu-action-item {
    direction: ltr;
    margin: 0 .6em 0 .7em;
    padding: .25em 0;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-heading, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-heading {
    color: #54595d;
    cursor: default;
    border-width: 1px;
    border-color: #c8ccd1;
    border-bottom-style: solid;
    align-self: center;
    margin: .5em 0 0 .666667em;
    padding: .25em 0;
    font-size: .75em;
    font-weight: normal;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content {
    margin-left: .5em;
    padding-top: 0;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content ul, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content ul {
    padding-top: .3em;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li {
    word-wrap: break-word;
    margin: 0;
    padding: .25em 0;
    font-size: .75em;
    line-height: 1.125em;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li a, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li a {
    color: #36c;
  }

  .vector-feature-page-tools-disabled .vector-main-menu-group .vector-menu-content li a:visited, .vector-feature-page-tools-disabled .vector-main-menu-action-item .vector-menu-content li a:visited {
    color: #795cb2;
  }

  .vector-page-titlebar {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    box-shadow: 0 1px #a2a9b1;
  }

  .vector-page-titlebar-blank {
    box-shadow: none;
  }

  .vector-page-titlebar .firstHeading {
    border: 0;
    flex-grow: 1;
  }

  .vector-page-titlebar .vector-page-titlebar-toc.ve-active {
    display: none !important;
  }

  .vector-page-toolbar-container {
    margin-bottom: 1px;
    font-size: .8125em;
    display: flex;
    box-shadow: 0 1px #c8ccd1;
  }

  .vector-page-toolbar-container .mw-portlet-views {
    display: none;
  }

  #left-navigation {
    flex-grow: 1;
    margin-left: -8px;
    display: flex;
  }

  #right-navigation {
    margin-right: -8px;
    display: flex;
  }

  #right-navigation .vector-menu-content {
    left: auto;
    right: -1px;
  }

  .vector-feature-page-tools-pinned-enabled .vector-page-toolbar-container .vector-page-tools-landmark, .vector-feature-page-tools-pinned-disabled .vector-column-end .vector-page-tools-landmark {
    display: none;
  }

  #vector-page-tools-pinned-container .vector-page-tools {
    width: 140px;
    padding-left: 14px;
  }

  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-more-collapsible-item, .vector-page-tools .vector-more-collapsible-item {
    display: block;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-header {
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: block;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-header:visited {
    color: #36c;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-header.selected a, .vector-feature-page-tools-enabled .vector-pinnable-header.selected a:visited {
    color: #202122;
  }

  .vector-pinnable-header[hidden] {
    display: none;
  }

  .vector-feature-page-tools-enabled .vector-pinned-container .vector-page-tools .vector-pinnable-header, .vector-feature-page-tools-enabled .vector-pinned-container .vector-toc .vector-pinnable-header {
    margin-bottom: -1px;
    padding: 18px 0 7px;
  }

  .vector-pinnable-header-label {
    color: #54595d;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: .875rem;
    display: inline-block;
  }

  .vector-pinnable-header-toggle-button {
    color: #36c;
    cursor: pointer;
    background-color: #0000;
    border: 0;
    padding: 0;
    display: none;
  }

  .vector-pinnable-header-toggle-button:hover {
    color: #447ff5;
  }

  .vector-pinnable-header-toggle-button:before {
    content: "[";
    color: #54595d;
  }

  .vector-pinnable-header-toggle-button:after {
    content: "]";
    color: #54595d;
  }

  .client-js .vector-pinnable-header-unpinned .vector-pinnable-header-pin-button, .client-js .vector-pinnable-header-pinned .vector-pinnable-header-unpin-button {
    display: inline-block;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element > :not(:last-child) {
    border-bottom: 1px solid #eaecf0;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading {
    color: #54595d;
    cursor: default;
    border-width: 1px;
    border-color: #c8ccd1;
    align-self: center;
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    font-weight: normal;
    display: flex;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading:visited {
    color: #36c;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading.selected a, .vector-feature-page-tools-enabled .vector-pinnable-element .vector-menu-heading.selected a:visited {
    color: #202122;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a {
    color: #36c;
    align-items: center;
    padding: 6px 14px;
    font-size: .875rem;
    display: flex;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a:visited {
    color: #36c;
  }

  .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a.selected a, .vector-feature-page-tools-enabled .vector-pinnable-element .mw-list-item a.selected a:visited {
    color: #202122;
  }

  .vector-unpinned-container .vector-pinnable-element {
    padding: 4px 0;
  }

  .vector-pinned-container .vector-pinnable-element .vector-pinnable-header, .vector-pinned-container .vector-pinnable-element .vector-menu-heading, .vector-pinned-container .vector-pinnable-element .mw-list-item a {
    padding-left: 0;
    padding-right: 0;
  }

  .vector-sticky-header {
    width: 100%;
    max-width: 99.75em;
    height: 3.125rem;
    z-index: 4;
    box-sizing: border-box;
    opacity: 0;
    background: #fffffff7;
    border-bottom: 1px solid #eaecf0;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 6px 2.75em;
    transition: transform .25s linear;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .vector-feature-limited-width-disabled .vector-sticky-header {
    max-width: none;
  }

  .vector-sticky-header-start, .vector-sticky-header-end, .vector-sticky-header-icons, .vector-sticky-header-context-bar {
    align-items: center;
    display: flex;
  }

  .vector-sticky-header-start {
    min-width: 0;
    flex-grow: 1;
  }

  .vector-sticky-header-context-bar-primary, .vector-sticky-header-end {
    white-space: nowrap;
  }

  .vector-sticky-header-context-bar {
    min-width: 0;
    border-left: 1px solid #c8c8c8;
    margin-left: 30px;
    padding-left: 30px;
  }

  .vector-sticky-header-context-bar-primary {
    text-overflow: ellipsis;
    font-family: Linux Libertine, Georgia, Times, serif;
    font-size: 1.5em;
    overflow: hidden;
  }

  .vector-sticky-header-context-bar-primary wbr {
    display: none;
  }

  .vector-sticky-header-icons {
    column-gap: 8px;
    font-size: .875em;
  }

  .vector-sticky-header .vector-search-box, .vector-sticky-header.vector-header-search-toggled .vector-sticky-header-search-toggle, .vector-sticky-header.vector-header-search-toggled .vector-sticky-header-context-bar {
    display: none;
  }

  .vector-sticky-header.vector-header-search-toggled .vector-search-box {
    margin-left: 4px;
    display: block;
  }

  .vector-sticky-header.vector-header-search-toggled .vector-search-box-show-thumbnail {
    margin-left: -9px;
  }

  .vector-sticky-header.vector-header-search-toggled .vector-search-box-show-thumbnail .cdx-text-input__start-icon {
    color: #202122;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .mw-watchlink a:before {
    transition: transform .5s;
  }

  .mw-watchlink .loading:before {
    cursor: default;
    pointer-events: none;
    transform-origin: 50%;
    outline: 0;
  }

  .mw-ui-icon-wikimedia-unStar:before, .mw-ui-icon-wikimedia-star.loading:before {
    transform: rotate(72deg);
  }

  .vector-toc {
    max-height: 75vh;
    box-sizing: border-box;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .vector-feature-page-tools-disabled .vector-toc {
    padding: 20px 12px 20px 27px;
  }

  .vector-feature-page-tools-disabled .vector-toc .vector-toc-pinnable-header {
    line-height: initial;
    border: 0;
    padding: 0 0 12px;
  }

  .vector-toc .vector-pinnable-header-label {
    overflow: unset;
  }

  .vector-toc .vector-toc-numb {
    display: none;
  }

  .vector-toc .vector-toc-toggle {
    width: 1.834em;
    height: 1.834em;
    color: #0000;
    cursor: pointer;
    font-size: .75em;
    transition: all .1s;
    display: none;
    position: absolute;
    top: 1px;
    left: calc(-1.834em - 1px);
  }

  .vector-feature-page-tools-enabled .vector-toc .vector-toc-toggle {
    margin-top: 2px;
  }

  .vector-toc .vector-toc-link {
    word-break: break-word;
    color: #36c;
    display: block;
  }

  .vector-toc .vector-toc-list-item-active > .vector-toc-link, .vector-toc .vector-toc-level-1-active:not(.vector-toc-list-item-expanded) > .vector-toc-link, .vector-toc .vector-toc-list-item-active.vector-toc-level-1-active > .vector-toc-link {
    color: #202122;
    font-weight: bold;
  }

  .vector-toc .vector-toc-list-item-active > .vector-toc-link .vector-toc-text, .vector-toc .vector-toc-level-1-active:not(.vector-toc-list-item-expanded) > .vector-toc-link .vector-toc-text, .vector-toc .vector-toc-list-item-active.vector-toc-level-1-active > .vector-toc-link .vector-toc-text {
    width: calc(100% + 14px);
  }

  .vector-toc .vector-toc-level-1-active:not(.vector-toc-list-item-active) > .vector-toc-link {
    color: #202122;
  }

  .vector-feature-page-tools-disabled .vector-toc .vector-toc-text {
    padding: 4px 0;
  }

  .vector-feature-page-tools-enabled .vector-toc .vector-toc-text {
    padding: 6px 0;
  }

  .vector-toc .vector-toc-contents, .vector-toc .vector-toc-list {
    margin: 0;
    list-style: none;
  }

  .vector-feature-page-tools-disabled .vector-toc .vector-toc-contents, .vector-feature-page-tools-disabled .vector-toc .vector-toc-list {
    line-height: 18px;
  }

  .vector-toc .vector-toc-list-item {
    padding-left: 8px;
    list-style-type: none;
    display: block;
    position: relative;
  }

  .vector-feature-page-tools-enabled .vector-toc .vector-toc-list-item {
    margin: 0;
  }

  .vector-toc .vector-toc-list-item.vector-toc-level-1 {
    padding-left: 0;
  }

  .vector-toc .vector-toc-list-item a {
    font-size: .875em;
  }

  .client-js .vector-toc .vector-toc-level-1 .vector-toc-list-item {
    display: none;
  }

  .client-js .vector-toc .vector-toc-level-1.vector-toc-list-item-expanded .vector-toc-list-item, .client-js .vector-toc .vector-toc-toggle {
    display: block;
  }

  .client-js .vector-toc .vector-toc-level-1.vector-toc-list-item-expanded .vector-toc-toggle {
    transform: rotate(0);
  }

  .client-js body.ltr .vector-toc .vector-toc-toggle {
    transform: rotate(-90deg);
  }

  .client-js body.rtl .vector-toc .vector-toc-toggle {
    transform: rotate(90deg);
  }

  .mw-table-of-contents-container {
    height: 100%;
    align-self: start;
  }

  .vector-feature-page-tools-enabled .vector-unpinned-container .vector-toc .vector-pinnable-header {
    padding-left: 36px;
  }

  .vector-feature-page-tools-enabled .vector-unpinned-container .vector-toc .vector-toc-contents {
    padding-left: 36px;
    padding-right: 14px;
  }

  #vector-toc-collapsed-button, .vector-sticky-header-toc, .vector-page-titlebar-toc {
    margin-right: 8px;
    display: none;
  }

  .vector-feature-page-tools-disabled .vector-unpinned-container .vector-toc {
    box-sizing: content-box;
  }

  .mw-ui-icon:before {
    opacity: .87;
  }
}

@media screen and (min-width: 1000px) {
  .mw-page-container {
    padding-left: 2.75em;
    padding-right: 2.75em;
  }
}

@media screen and (min-width: 1200px) {
  .mw-page-container {
    padding-left: 3.25em;
    padding-right: 3.25em;
  }

  .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc:after, .vector-feature-page-tools-disabled #vector-toc-pinned-container .vector-toc, .vector-feature-page-tools-disabled .vector-main-menu {
    width: 244px;
  }
}

@media screen and (min-width: 1000px) {
  .mw-page-container-inner {
    grid-template: "header header" min-content
                   "siteNotice siteNotice" min-content
                   "mainMenu pageContent" min-content
                   "toc pageContent" 1fr
                   "footer footer" min-content
                   / 12.25em minmax(0, 1fr);
    column-gap: 36px;
    display: grid;
  }

  .vector-feature-page-tools-disabled .mw-page-container-inner {
    column-gap: 44px;
  }

  .vector-sitenotice-container {
    grid-area: siteNotice;
  }

  .mw-table-of-contents-container {
    grid-area: toc;
  }

  .mw-header {
    grid-area: header;
  }

  .vector-main-menu-container {
    grid-area: mainMenu;
  }

  .mw-content-container {
    grid-area: pageContent;
  }

  .mw-footer-container {
    grid-area: footer;
  }
}

@media screen and (min-width: 1200px) {
  .mw-page-container-inner {
    grid-template-columns: 15.5em minmax(0, 1fr);
  }

  .vector-feature-page-tools-enabled .mw-header {
    grid-template: "headerStart headerEnd"
    / 15.5em minmax(0, 1fr);
    column-gap: 36px;
    display: grid;
  }

  .vector-feature-page-tools-enabled .mw-header .vector-header-start {
    grid-area: headerStart;
  }

  .vector-feature-page-tools-enabled .mw-header .vector-header-end {
    grid-area: headerEnd;
  }
}

@media screen and (min-width: 1000px) {
  .vector-feature-page-tools-enabled .mw-body {
    grid-template: "titlebar-cx ." min-content
                   "titlebar ." min-content
                   "toolbar columnEnd" min-content
                   "content columnEnd" 1fr
                   / minmax(0, 60em) min-content;
    display: grid;
  }

  .vector-feature-page-tools-pinned-enabled.vector-feature-page-tools-enabled .mw-body {
    column-gap: 36px;
  }

  .vector-feature-limited-width-disabled.vector-feature-page-tools-enabled .mw-body, .vector-feature-limited-width-content-disabled.vector-feature-page-tools-enabled .mw-body {
    grid-template-columns: minmax(0, 1fr) min-content;
  }

  .vector-feature-page-tools-enabled .mw-body .vector-page-titlebar {
    grid-area: titlebar;
  }

  .vector-feature-page-tools-enabled .mw-body .vector-page-toolbar {
    grid-area: toolbar;
  }

  .vector-feature-page-tools-enabled .mw-body #bodyContent {
    grid-area: content;
  }

  .vector-feature-page-tools-enabled .mw-body .vector-column-end {
    grid-area: columnEnd;
  }
}

@media screen and (min-width: 720px) {
  .vector-user-menu .user-links-collapsible-item {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .vector-user-links .mw-portlet-vector-user-menu-overflow {
    margin-right: 8px;
  }

  .vector-user-links .mw-portlet-vector-user-menu-overflow .vector-menu-content-list {
    column-gap: 8px;
  }
}

@media screen and (max-width: 719px) {
  .vector-user-links .mw-portlet-vector-user-menu-overflow .user-links-collapsible-item {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  #pt-userpage-2 {
    max-width: 14.2857em;
  }

  .vector-feature-page-tools-disabled .mw-header .vector-search-box:not(.vector-search-box-auto-expand-width), .vector-feature-page-tools-disabled:not(.client-js) .mw-header .vector-search-box, html:not(.client-js) .vector-feature-page-tools-disabled .mw-header .vector-search-box {
    padding-left: 24px;
  }

  .client-js.vector-feature-page-tools-enabled .mw-header .vector-search-box.vector-search-box-auto-expand-width, .client-js .vector-feature-page-tools-enabled .mw-header .vector-search-box.vector-search-box-auto-expand-width {
    margin-left: -24px;
  }
}

@media screen and (min-width: 1000px) {
  .mw-header .vector-search-box {
    margin-right: 12px;
  }
}

@media screen and (max-width: 999px) {
  .mw-header.vector-header-search-toggled .vector-header-start, .mw-header.vector-header-search-toggled .search-toggle {
    display: none;
  }

  .mw-header.vector-header-search-toggled .vector-search-box-collapses > div {
    display: block;
  }

  .mw-header.vector-header-search-toggled .vector-search-box {
    margin-right: 12px;
    position: relative;
  }

  .mw-header.vector-header-search-toggled .vector-search-box > div {
    max-width: none;
  }

  .vector-feature-page-tools-disabled .mw-header.vector-header-search-toggled .vector-search-box {
    margin-left: 8px;
  }

  .mw-header.vector-header-search-toggled .cdx-search-input__input-wrapper {
    position: static;
  }

  .mw-header.vector-header-search-toggled .cdx-typeahead-search--expanded .cdx-search-input__end-button {
    border-bottom-right-radius: 0;
  }

  .vector-feature-page-tools-disabled .vector-main-menu {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .vector-feature-page-tools-disabled .vector-main-menu {
    margin-left: -28px;
  }
}

@media screen and (max-width: 999px) {
  .vector-page-titlebar .vector-page-titlebar-toc .vector-menu-heading {
    padding: 7px 12px;
  }
}

@media screen and (min-width: 720px) {
  .vector-page-toolbar-container .mw-portlet-views:not(.emptyPortlet) {
    display: block;
  }

  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-more-collapsible-item, .vector-page-tools .vector-more-collapsible-item {
    display: none;
  }
}

@media screen and (max-width: 719px) {
  .vector-feature-page-tools-disabled .vector-page-tools-landmark .vector-has-collapsible-items, .vector-page-tools .vector-has-collapsible-items {
    display: block;
  }
}

@media screen and (max-width: 999px) {
  .vector-pinned-container {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .vector-sticky-header {
    padding: 6px 3.25em;
  }
}

@media screen and (min-width: 1000px) {
  .client-js.vector-sticky-header-enabled {
    scroll-padding-top: 3.125rem;
  }

  .client-js.vector-sticky-header-enabled .vector-sticky-header {
    display: flex;
  }

  .client-js.vector-sticky-header-enabled .vector-sticky-header-visible .vector-sticky-header {
    opacity: 1;
    transform: translateY(0);
  }

  .client-js.vector-sticky-header-enabled .vector-toc-pinned #vector-toc-pinned-container, .client-js.vector-sticky-header-enabled .mw-sticky-header-element, .client-js.vector-sticky-header-enabled .charts-stickyhead th {
    top: 3.125rem !important;
  }

  .vector-toc-pinned #mw-panel-toc {
    contain: paint;
  }

  .vector-feature-page-tools-disabled .vector-toc-pinned #mw-panel-toc, .vector-feature-page-tools-disabled.vector-toc-pinned #mw-panel-toc {
    margin-left: -27px;
  }

  .vector-feature-page-tools-enabled .vector-toc-pinned #mw-panel-toc, .vector-feature-page-tools-enabled.vector-toc-pinned #mw-panel-toc {
    margin-left: -22px;
  }

  #vector-toc-pinned-container {
    position: sticky;
    top: 0;
  }

  .vector-toc-pinned #vector-toc-pinned-container {
    padding-top: 1.5em;
  }

  .vector-feature-page-tools-disabled .vector-toc-pinned #mw-sidebar-checkbox:not(:checked) ~ .mw-table-of-contents-container #vector-toc-pinned-container, .vector-feature-page-tools-disabled.vector-toc-pinned #mw-sidebar-checkbox:not(:checked) ~ .mw-table-of-contents-container #vector-toc-pinned-container {
    margin-top: 1.5em;
  }

  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .vector-toc-pinned #vector-toc-pinned-container, .vector-feature-page-tools-enabled.vector-toc-pinned.vector-feature-main-menu-pinned-disabled #vector-toc-pinned-container {
    margin-top: 1.34em;
  }

  .vector-feature-page-tools-enabled #vector-toc-pinned-container .vector-toc {
    padding-bottom: 20px;
    padding-left: 22px;
    padding-right: 14px;
  }

  .vector-toc-pinned #vector-toc-pinned-container .vector-toc:after {
    content: "";
    height: 30px;
    pointer-events: none;
    background: linear-gradient(#fff0, #fff) -12px no-repeat;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 999px) {
  .client-js #vector-toc-pinned-container .vector-toc, .client-js .vector-toc .vector-pinnable-header-toggle-button {
    display: none;
  }

  .client-js .vector-page-titlebar-toc {
    display: block;
  }

  .client-js .vector-page-titlebar-toc > .vector-menu-content {
    max-width: none;
  }

  .client-js .vector-page-titlebar-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min(51em, 75vw);
  }

  .client-js .vector-below-page-title .vector-page-titlebar-toc {
    z-index: 3;
    background-color: #fff;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1000px) {
  .client-js .vector-toc-unpinned:not(.vector-sticky-header-visible) .vector-page-titlebar-toc {
    display: block;
  }

  .client-js .vector-toc-unpinned:not(.vector-sticky-header-visible) .vector-page-titlebar-toc > .vector-menu-content {
    max-width: none;
  }

  .client-js .vector-toc-unpinned:not(.vector-sticky-header-visible) .vector-page-titlebar-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min(51em, 75vw);
  }

  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc {
    display: block;
  }

  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc > .vector-menu-content {
    max-width: none;
  }

  .client-js .vector-toc-unpinned.vector-sticky-header-visible .vector-sticky-header-toc .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min(51em, 75vw);
  }
}

@media screen and (max-width: 999px) {
  .client-nojs #vector-toc-collapsed-button {
    padding: 7px 12px;
    display: block;
  }

  .client-nojs #vector-toc-collapsed-button:hover, .client-nojs #vector-toc-collapsed-button:active {
    background-color: #f8f9fa;
  }

  .client-nojs #vector-toc-pinned-container {
    display: block;
    position: relative;
  }

  .client-nojs #vector-toc-pinned-container > .vector-menu-content {
    max-width: none;
  }

  .client-nojs #vector-toc-pinned-container .vector-toc {
    width: max-content;
    min-width: 200px;
    max-width: min(51em, 75vw);
  }

  .client-nojs .vector-toc {
    z-index: 3;
    border: 1px solid #a2a9b1;
    margin-top: .5em;
    display: none;
    position: absolute;
    top: 36px;
    left: -12px;
  }

  .client-nojs .vector-feature-page-tools-enabled .vector-toc {
    padding-left: 14px;
  }

  .client-nojs #vector-toc-collapsed-checkbox:checked ~ .mw-table-of-contents-container .vector-toc {
    display: block;
  }
}

@media all {
  .mw-logo {
    height: 100%;
    min-width: 13.875em;
    align-items: center;
    display: flex;
  }

  .vector-feature-page-tools-disabled .mw-logo {
    margin-left: 24px;
  }

  .vector-feature-page-tools-enabled.vector-feature-main-menu-pinned-disabled .mw-logo {
    margin-left: 20px;
  }

  .mw-logo-icon {
    float: left;
    width: 3.125em;
    height: 3.125em;
    margin-right: 10px;
    display: none;
  }

  .mw-logo-container {
    float: left;
    max-width: 120px;
  }

  .mw-logo-container img {
    width: 100%;
  }

  .mw-logo-wordmark {
    margin: 0 auto;
    display: block;
  }

  .mw-logo-tagline {
    margin: 5px auto 0;
    display: block;
  }
}

@media (min-width: 720px) {
  .mw-logo-icon {
    display: block;
  }

  .mw-logo-container {
    max-width: none;
  }

  .mw-logo-container img {
    width: auto;
  }
}

@media print {
  .vector-page-toolbar, #mw-sidebar-checkbox, .vector-header-start > :not(.mw-logo), .vector-header-end, #mw-panel-toc, #vector-sticky-header, #p-lang-btn, .vector-menu-checkbox, nav, #vector-page-titlebar-toc, #footer {
    display: none !important;
  }
}

.mw-table-of-contents-container {
  display: none;
}

@supports (display: grid ) {
  .mw-table-of-contents-container {
    display: block;
  }
}

@media screen {
  div.thumbinner {
    background-color: #f8f9fa;
    border: 1px solid #c8ccd1;
    font-size: 94%;
  }

  .thumbimage {
    background-color: #fff;
    border: 1px solid #c8ccd1;
  }

  .thumbcaption {
    border: 0;
    font-size: 94%;
  }

  .thumbborder {
    border: 1px solid #eaecf0;
  }

  .magnify {
    float: right;
    margin-left: 3px;
  }

  .magnify a {
    text-indent: 15px;
    white-space: nowrap;
    width: 15px;
    height: 11px;
    -webkit-user-select: none;
    user-select: none;
    background-image: url("https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e");
    display: block;
    overflow: hidden;
  }

  .mw-content-ltr .thumbcaption {
    text-align: left;
  }

  .mw-content-rtl .thumbcaption {
    text-align: right;
  }

  .mw-content-ltr .magnify {
    float: right;
    margin-left: 3px;
    margin-right: 0;
  }

  .mw-content-ltr .magnify a {
    background-image: url("https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e");
  }

  .mw-content-rtl .magnify {
    float: left;
    margin-left: 0;
    margin-right: 3px;
  }

  .mw-content-rtl .magnify a {
    background-image: url("https://en.wikipedia.org/w/resources/src/mediawiki.skinning/images/magnify-clip-rtl.svg?38fd5");
  }

  a.new {
    color: #d33;
  }

  a.mw-selflink {
    color: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
    font-weight: bold;
  }

  a.mw-selflink:hover {
    cursor: inherit;
    -webkit-text-decoration: inherit;
    text-decoration: inherit;
  }

  a.mw-selflink:active, a.mw-selflink:visited {
    color: inherit;
  }

  a.new:visited {
    color: #a55858;
  }

  .mw-parser-output a.extiw, .mw-parser-output a.external {
    color: #36c;
  }

  .mw-parser-output a.extiw:visited, .mw-parser-output a.external:visited {
    color: #795cb2;
  }

  .mw-parser-output a.extiw:active, .mw-parser-output a.external:active {
    color: #faa700;
  }

  .mw-underline-always a {
    text-decoration: underline;
  }

  .mw-underline-never a {
    text-decoration: none;
  }

  .plainlinks a.external {
    background: none !important;
    padding: 0 !important;
  }

  .mw-body-content:after {
    clear: both;
    content: "";
    display: block;
  }

  .mw-body-content a.external.free {
    word-wrap: break-word;
  }

  .mw-body-content .error {
    color: #d33;
    font-size: larger;
  }

  .rtl .mw-parser-output a.external.free, .rtl .mw-parser-output a.external.autonumber {
    direction: ltr;
    unicode-bidi: embed;
  }

  .mw-hide-empty-elt .mw-parser-output:not(.mw-show-empty-elt) .mw-empty-elt {
    display: none;
  }

  .wikitable {
    color: #202122;
    border-collapse: collapse;
    background-color: #f8f9fa;
    border: 1px solid #a2a9b1;
    margin: 1em 0;
  }

  .wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
    border: 1px solid #a2a9b1;
    padding: .2em .4em;
  }

  .wikitable > tr > th, .wikitable > * > tr > th {
    text-align: center;
    background-color: #eaecf0;
  }

  .wikitable > caption {
    font-weight: bold;
  }

  #catlinks {
    text-align: left;
  }

  .catlinks {
    clear: both;
    background-color: #f8f9fa;
    border: 1px solid #a2a9b1;
    margin-top: 1em;
    padding: 5px;
  }

  .catlinks ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
  }

  .catlinks li {
    border-left: 1px solid #a2a9b1;
    margin: .125em 0;
    padding: 0 .5em;
    line-height: 1.25em;
    display: inline-block;
  }

  .catlinks li:first-child {
    border-left: 0;
    padding-left: .25em;
  }

  .catlinks li a.mw-redirect {
    font-style: italic;
  }

  .mw-hidden-cats-hidden, .catlinks-allhidden, .emptyPortlet, .printfooter, .client-nojs #t-print {
    display: none;
  }

  .mw-editsection {
    -webkit-user-select: none;
    user-select: none;
  }

  .mw-editsection, .mw-editsection-like {
    vertical-align: baseline;
    margin-left: 1em;
    font-size: small;
    font-weight: normal;
    line-height: 0;
  }

  .mw-content-ltr .mw-editsection, .mw-content-rtl .mw-content-ltr .mw-editsection, .mw-content-ltr .mw-editsection-like, .mw-content-rtl .mw-content-ltr .mw-editsection-like {
    margin-left: 1em;
    margin-right: 0;
  }

  .mw-content-rtl .mw-editsection, .mw-content-ltr .mw-content-rtl .mw-editsection, .mw-content-rtl .mw-editsection-like, .mw-content-ltr .mw-content-rtl .mw-editsection-like {
    margin-left: 0;
    margin-right: 1em;
  }

  .usermessage {
    color: #000;
    vertical-align: middle;
    background-color: #fef6e7;
    border: 1px solid #fc3;
    margin: 2em 0 1em;
    padding: .5em 1em;
    font-weight: bold;
  }

  a {
    color: #36c;
    background: none;
    text-decoration: none;
  }

  a:not([href]) {
    cursor: pointer;
  }

  a:visited {
    color: #795cb2;
  }

  a:active {
    color: #faa700;
  }

  a:hover, a:focus {
    text-decoration: underline;
  }

  a:lang(ar), a:lang(kk-arab), a:lang(mzn), a:lang(ps), a:lang(ur) {
    text-decoration: none;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  hr {
    height: 1px;
    background-color: #a2a9b1;
    border: 0;
    margin: .2em 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #000;
    margin: 0;
    padding-top: .5em;
    padding-bottom: .17em;
    overflow: hidden;
  }

  h1, h2 {
    border-bottom: 1px solid #a2a9b1;
    margin-bottom: .6em;
  }

  h3, h4, h5 {
    margin-bottom: .3em;
  }

  h1 {
    font-size: 188%;
    font-weight: normal;
  }

  h2 {
    font-size: 150%;
    font-weight: normal;
  }

  h3 {
    font-size: 128%;
  }

  h4 {
    font-size: 116%;
  }

  h5 {
    font-size: 108%;
  }

  h6 {
    font-size: 100%;
  }

  p {
    margin: .4em 0 .5em;
  }

  p img {
    margin: 0;
  }

  ul {
    margin: .3em 0 0 1.6em;
    padding: 0;
  }

  ol {
    margin: .3em 0 0 3.2em;
    padding: 0;
    list-style-image: none;
  }

  li {
    margin-bottom: .1em;
  }

  dt {
    margin-bottom: .1em;
    font-weight: bold;
  }

  dl {
    margin-top: .2em;
    margin-bottom: .5em;
  }

  dd {
    margin-bottom: .1em;
    margin-left: 1.6em;
  }

  pre, code, tt, kbd, samp, .mw-code {
    font-family: monospace;
  }

  pre, code, .mw-code {
    color: #000;
    background-color: #f8f9fa;
    border: 1px solid #eaecf0;
  }

  code {
    border-radius: 2px;
    padding: 1px 4px;
  }

  pre, .mw-code {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 1em;
    overflow-x: hidden;
  }

  table {
    font-size: 100%;
  }

  fieldset {
    border: 1px solid #2a4b8d;
    margin: 1em 0;
    padding: 0 1em 1em;
  }

  legend {
    padding: .5em;
  }

  form {
    border: 0;
    margin: 0;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #c8ccd1;
    padding: .1em;
    display: block;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .center * {
    margin-left: auto;
    margin-right: auto;
  }

  .small {
    font-size: 94%;
  }

  table.small {
    font-size: 100%;
  }

  @counter-style meetei {
    system: numeric;
    symbols: "꯰" "꯱" "꯲" "꯳" "꯴" "꯵" "꯶" "꯷" "꯸" "꯹";
    suffix: ") ";
  }

  @counter-style santali {
    system: numeric;
    symbols: "᱐" "᱑" "᱒" "᱓" "᱔" "᱕" "᱖" "᱗" "᱘" "᱙";
  }

  @counter-style myanmar_with_period {
    system: numeric;
    symbols: "၀" "၁" "၂" "၃" "၄" "၅" "၆" "၇" "၈" "၉";
    suffix: "။ ";
  }

  ol:lang(azb) li, ol:lang(bcc) li, ol:lang(bgn) li, ol:lang(bqi) li, ol:lang(fa) li, ol:lang(glk) li, ol:lang(kk-arab) li, ol:lang(lrc) li, ol:lang(luz) li, ol:lang(mzn) li {
    list-style-type: persian;
  }

  ol:lang(ckb) li, ol:lang(sdh) li {
    list-style-type: arabic-indic;
  }

  ol:lang(hi) li, ol:lang(mai) li, ol:lang(mr) li, ol:lang(ne) li {
    list-style-type: devanagari;
  }

  ol:lang(as) li, ol:lang(bn) li {
    list-style-type: bengali;
  }

  ol:lang(mni) li {
    list-style-type: meetei;
  }

  ol:lang(or) li {
    list-style-type: oriya;
  }

  ol:lang(sat) li {
    list-style-type: santali;
  }

  ol:lang(blk) li, ol:lang(kjp) li, ol:lang(ksw) li, ol:lang(mnw) li, ol:lang(my) li, ol:lang(shn) li {
    list-style-type: myanmar_with_period;
  }

  .mw-content-ltr ul, .mw-content-rtl .mw-content-ltr ul {
    margin: .3em 0 0 1.6em;
    padding: 0;
  }

  .mw-content-rtl ul, .mw-content-ltr .mw-content-rtl ul {
    margin: .3em 1.6em 0 0;
    padding: 0;
  }

  .mw-content-ltr ol, .mw-content-rtl .mw-content-ltr ol {
    margin: .3em 0 0 3.2em;
    padding: 0;
  }

  .mw-content-rtl ol, .mw-content-ltr .mw-content-rtl ol {
    margin: .3em 3.2em 0 0;
    padding: 0;
  }

  .mw-content-ltr dd, .mw-content-rtl .mw-content-ltr dd {
    margin-left: 1.6em;
    margin-right: 0;
  }

  .mw-content-rtl dd, .mw-content-ltr .mw-content-rtl dd {
    margin-left: 0;
    margin-right: 1.6em;
  }

  h1:lang(anp), h1:lang(as), h1:lang(bh), h1:lang(bho), h1:lang(blk), h1:lang(bn), h1:lang(gu), h1:lang(hi), h1:lang(kjp), h1:lang(kn), h1:lang(ks), h1:lang(ksw), h1:lang(mag), h1:lang(ml), h1:lang(mr), h1:lang(my), h1:lang(mai), h1:lang(mnw), h1:lang(ne), h1:lang(new), h1:lang(or), h1:lang(pa), h1:lang(pi), h1:lang(rki), h1:lang(sa), h1:lang(shn), h1:lang(syl), h1:lang(ta), h1:lang(te) {
    line-height: 1.6em !important;
  }

  h2:lang(anp), h2:lang(as), h2:lang(bh), h2:lang(bho), h2:lang(blk), h2:lang(bn), h2:lang(gu), h2:lang(hi), h2:lang(kjp), h2:lang(kn), h2:lang(ks), h2:lang(ksw), h2:lang(mag), h2:lang(ml), h2:lang(mr), h2:lang(my), h2:lang(mai), h2:lang(mnw), h2:lang(ne), h2:lang(new), h2:lang(or), h2:lang(pa), h2:lang(pi), h2:lang(rki), h2:lang(sa), h2:lang(shn), h2:lang(syl), h2:lang(ta), h2:lang(te), h3:lang(anp), h3:lang(as), h3:lang(bh), h3:lang(bho), h3:lang(blk), h3:lang(bn), h3:lang(gu), h3:lang(hi), h3:lang(kjp), h3:lang(kn), h3:lang(ks), h3:lang(ksw), h3:lang(mag), h3:lang(ml), h3:lang(mr), h3:lang(my), h3:lang(mai), h3:lang(mnw), h3:lang(ne), h3:lang(new), h3:lang(or), h3:lang(pa), h3:lang(pi), h3:lang(rki), h3:lang(sa), h3:lang(shn), h3:lang(syl), h3:lang(ta), h3:lang(te), h4:lang(anp), h4:lang(as), h4:lang(bh), h4:lang(bho), h4:lang(blk), h4:lang(bn), h4:lang(gu), h4:lang(hi), h4:lang(kjp), h4:lang(kn), h4:lang(ks), h4:lang(ksw), h4:lang(mag), h4:lang(ml), h4:lang(mr), h4:lang(my), h4:lang(mai), h4:lang(mnw), h4:lang(ne), h4:lang(new), h4:lang(or), h4:lang(pa), h4:lang(pi), h4:lang(rki), h4:lang(sa), h4:lang(shn), h4:lang(syl), h4:lang(ta), h4:lang(te), h5:lang(anp), h5:lang(as), h5:lang(bh), h5:lang(bho), h5:lang(blk), h5:lang(bn), h5:lang(gu), h5:lang(hi), h5:lang(kjp), h5:lang(kn), h5:lang(ks), h5:lang(ksw), h5:lang(mag), h5:lang(ml), h5:lang(mr), h5:lang(my), h5:lang(mai), h5:lang(mnw), h5:lang(ne), h5:lang(new), h5:lang(or), h5:lang(pa), h5:lang(pi), h5:lang(rki), h5:lang(sa), h5:lang(shn), h5:lang(syl), h5:lang(ta), h5:lang(te), h6:lang(anp), h6:lang(as), h6:lang(bh), h6:lang(bho), h6:lang(blk), h6:lang(bn), h6:lang(gu), h6:lang(hi), h6:lang(kjp), h6:lang(kn), h6:lang(ks), h6:lang(ksw), h6:lang(mag), h6:lang(ml), h6:lang(mr), h6:lang(my), h6:lang(mai), h6:lang(mnw), h6:lang(ne), h6:lang(new), h6:lang(or), h6:lang(pa), h6:lang(pi), h6:lang(rki), h6:lang(sa), h6:lang(shn), h6:lang(syl), h6:lang(ta), h6:lang(te) {
    line-height: 1.4em;
  }

  h2:lang(th) {
    line-height: 1.6;
  }
}

@media print {
  table.floatright, div.floatright, table.floatleft, div.floatleft {
    border: 0;
  }

  div.thumb {
    page-break-inside: avoid;
  }

  div.thumb a {
    border-bottom: 0;
  }

  div.thumbinner {
    color: #666;
    min-width: 100px;
    background-color: #fff;
    border: 1px;
    border-radius: 2px;
    font-size: 10pt;
  }

  .thumbborder {
    border: 1pt solid #ddd;
  }

  .magnify {
    display: none;
  }

  .mw-parser-output a.external.text:after, .mw-parser-output a.external.autonumber:after {
    content: " (" attr(href) ")";
    word-break: break-all;
    word-wrap: break-word;
  }

  .mw-parser-output a.external.text[href^="//"]:after, .mw-parser-output a.external.autonumber[href^="//"]:after {
    content: " (https:" attr(href) ")";
  }

  .wikitable {
    border-collapse: collapse;
    page-break-inside: avoid;
    background: #fff;
    border: 1pt solid #aaa;
    margin: 1em 0;
    font-size: 10pt;
  }

  .wikitable > caption {
    padding: 5px;
    font-size: 10pt;
  }

  .wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
    border: 1pt solid #aaa;
    padding: .4em .6em;
    color: #000 !important;
    background: #fff !important;
  }

  .wikitable > tr > th, .wikitable > * > tr > th {
    text-align: center;
  }

  table.listing, table.listing td {
    border-collapse: collapse;
    border: 1pt solid #000;
  }

  .catlinks ul {
    padding: 0;
    list-style: none;
    display: inline;
  }

  .catlinks li {
    border-left: 1pt solid #aaa;
    margin: .1em 0;
    padding: 0 .4em;
    line-height: 1.15;
    display: inline-block;
  }

  .catlinks li:first-child {
    border-left: 0;
    padding-left: .2em;
  }

  .mw-hidden-catlinks, .catlinks, .mw-editsection, .mw-editsection-like, .mw-indicators, #siteNotice, .usermessage {
    display: none;
  }

  .printfooter {
    padding: 1em 0;
  }

  .center {
    text-align: center;
  }

  a {
    background: none !important;
    padding: 0 !important;
  }

  a, a.external, a.new, a.stub {
    color: inherit !important;
    -webkit-text-decoration: inherit !important;
    text-decoration: inherit !important;
  }

  dt {
    font-weight: bold;
  }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-before: avoid;
    font-weight: bold;
  }

  p {
    orphans: 3;
    widows: 3;
    margin: 1em 0;
    line-height: 1.2;
  }

  img, figure {
    page-break-inside: avoid;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  pre, .mw-code {
    color: #000;
    white-space: pre-wrap;
    word-wrap: break-word;
    background: #fff;
    border: 1pt dashed #000;
    padding: 1em;
    font-size: 8pt;
    overflow-x: hidden;
  }

  ul {
    list-style-type: square;
  }
}

.mw-ui-icon-wikimedia-labFlask:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-labFlask-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=labFlask&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-language:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-language-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=language&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-sandbox:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-sandbox-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=sandbox&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-listBullet:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-listBullet-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=listBullet&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-ellipsis:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-ellipsis-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=ellipsis&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-heart:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-heart-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=heart&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-logIn:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-logIn-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logIn&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-logOut:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-logOut-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=logOut&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-search:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-search-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=search&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-settings:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-settings-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=settings&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-menu:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-menu-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=menu&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-imageGallery:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-imageGallery-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=imageGallery&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-unStar:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-unStar-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=unStar&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-expand:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-expand-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=expand&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAdd:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAdd-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAdd&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAnonymous:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAnonymous-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAnonymous&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAvatar:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userAvatar-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userAvatar&variant=progressive&format=original&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userContributions:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userContributions-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userContributions&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userGroup:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userGroup-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userGroup&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userTalk:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-userTalk-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=userTalk&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-watchlist:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon-wikimedia-watchlist-progressive:before {
  background-image: linear-gradient(#0000, #0000), url("https://en.wikipedia.org/w/load.php?modules=skins.vector.icons&image=watchlist&variant=progressive&format=original&lang=en&skin=vector-2022&version=ztntf");
}

.mw-ui-icon {
  font-size: initial;
  width: 1.25em;
  height: 1.25em;
  min-width: 1.25em;
  min-height: 1.25em;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #0000;
  flex-basis: 1.25em;
  margin: 0;
  padding: 0;
  line-height: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-sizing: content-box !important;
}

.mw-ui-icon:not(.mw-ui-button) {
  border: 0;
}

.mw-ui-icon:before {
  content: "";
  width: 100%;
  height: 100%;
  min-width: 1.25em;
  min-height: 1.25em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25em 1.25em;
  display: block;
}

.mw-ui-icon + span:not(:empty) {
  margin-left: 6px;
}

.mw-ui-icon-flush-top {
  margin-top: -.75em;
}

.mw-ui-icon-flush-left {
  margin-left: -.75em;
}

@media (min-width: 1000px) {
  .mw-ui-icon-flush-left {
    margin-left: -.375em;
  }
}

.mw-ui-icon-flush-right {
  margin-right: -.75em;
}

@media (min-width: 1000px) {
  .mw-ui-icon-flush-right {
    margin-right: -.375em;
  }
}

.mw-ui-icon-element {
  color: #0000;
  border-radius: 2px;
  padding: .75em;
  transition: background-color .1s;
}

.mw-ui-icon-element.mw-ui-button {
  padding: .6875em;
}

.mw-ui-icon-element:focus, .mw-ui-icon-element:active, .mw-ui-icon-element:visited {
  color: #0000;
}

.mw-ui-icon-element:active {
  background-color: #00184907;
}

@media (min-width: 1000px) {
  .mw-ui-icon-element.mw-ui-button {
    padding: .3125em;
  }
}

@media (hover: hover) {
  .mw-ui-icon-element:not(.disabled):hover {
    background-color: #00184907;
  }
}

.mw-ui-icon-small {
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  flex-basis: 1em;
  line-height: 1em;
}

.mw-ui-icon-small:before {
  content: "";
  width: 100%;
  height: 100%;
  min-width: 1em;
  min-height: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  display: block;
}

.mw-ui-icon-small.mw-ui-icon-element {
  padding: .4375em;
}

.mw-ui-icon-small.mw-ui-icon-flush-left {
  margin-left: -.4375em;
}

.mw-ui-icon-small.mw-ui-icon-flush-right {
  margin-right: -.4375em;
}

.mw-ui-icon-small.mw-ui-icon-before:before {
  min-width: 1em;
  min-height: 1em;
  margin-right: .4375em;
}

.mw-ui-icon-before {
  width: auto;
  max-width: 100%;
}

.mw-ui-icon-before:before {
  font-size: initial;
  width: auto;
  min-width: 1.25em;
  min-height: 1.25em;
  vertical-align: middle;
  margin-right: 6px;
  display: inline-block;
}

.mw-ui-icon-before span {
  vertical-align: middle;
}

@media (min-width: 1000px) {
  .mw-ui-icon-with-label-desktop {
    width: auto;
    line-height: inherit;
    flex-basis: auto;
    color: #54595d !important;
    font-weight: 500 !important;
  }

  .mw-ui-icon-with-label-desktop:hover, .mw-ui-icon-with-label-desktop:focus, .mw-ui-icon-with-label-desktop:active, .mw-ui-icon-with-label-desktop:visited {
    color: #54595d;
    text-decoration: none;
  }

  .mw-ui-icon-with-label-desktop:before {
    width: auto;
    vertical-align: text-bottom;
    margin-right: 6px;
    display: inline-block;
  }
}

/*# sourceMappingURL=index.7901e28e.css.map */
